import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";

export default async function removeItem({ productId, variantId }) {
    try {
        const cartState = CartState.get();
        const authState = AuthState.get();
        const loginData = authState.loginData;
        var serviceType = undefined;
        // console.log("Auth State is ", authState);

        //auth setter part with optional test data for guest
        if (authState.guest_user === true && authState.uuId !== "") {
            serviceType = serviceTypes.deleteCart(125 || loginData.user?.id, cartState.cart?.id)
            serviceType.params.uuid = "cba0660e-1d01-11eb-adc1-0242ac120002" || authState.uuId;
        } else if (authState.guest_user === false && (typeof loginData?.user) === "object") {
            serviceType = serviceTypes.deleteCart(loginData.user?.id, cartState.cart?.id)
            serviceType.bearerToken = loginData.token?.access_token;
        } else {
            throw new Error("Login data error, try logout and login again.");
        }

        // console.log("Cart Service Data is ", serviceType);
        const { response, error } = await service(serviceType);
        if (error) {
            //console.log("Received data is ", error.message);
            throw new Error(error.message || "Error in deleting cart");
        } else if (response) {
            //console.log("Received data is ", response);
            if (response.data?.success === true) {
                window.notify([{ message: error.message || "Cart deleted successfully", type: "success" }]);
            } else {
                throw new Error(error.message || "Error in deleting cart");
            }
        }

    } catch (error) {
        window.notify([{ message: error.message || "Error in deleting cart", type: "danger" }]);
    }
}


