import React, {  useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Footer, FeatureBar, Header } from "../../components";
import { Container } from "reactstrap";
import { BannerHomeTwo } from "../home";
import ScrollToTop from "react-scroll-to-top";
import service, { serviceTypes } from "../../services";
import { FaQuoteLeft } from "react-icons/fa";
import { Card, CardText, CardBody, CardTitle } from "reactstrap";

function Reviews(props) {
  const [customerFeedback, setFeedback] = useState([]);
  useEffect(() => {
    if (customerFeedback.length === 0) {
      const serviceType = serviceTypes.customerFeedback();
      service(serviceType).then(({ response, error }) => {
        if (error) {
          console.log(error, "ERROR /***");
        } else if (response?.data?.data) {
          setFeedback(response?.data?.data?.feedback);
        }
      });
    }
  }, [props]);
  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      <section className="about-first">
        <Container>
          <h1 className="title text-center" style={{ fontSize: "45px" }}>
            Reviews
          </h1>
          <h4 className="title text-center" style={{ fontSize: "18px" }}>
            Impressions By Our Customers{" "}
          </h4>

          <div>
            {customerFeedback?.map((feedback, index) => (
              <>
                {feedback.is_active ? (
                  <Card className="border-0" key={index}>
                    <CardBody className="yt-card-box-shadow m-3">
                      <FaQuoteLeft className="yt-quote-icon" />
                      <CardTitle tag="h5">{feedback.title}</CardTitle>

                      <CardText className="yt-card-text">
                        {feedback.description}
                      </CardText>

                      <div>
                        <h6>
                          <strong>-{feedback.customer_name}</strong>{" "}
                        </h6>
                        <p>{feedback.customer_location}</p>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <></>
                )}
              </>
            ))}
          </div>
          <br />
        </Container>
      </section>
      <BannerHomeTwo />
      <FeatureBar />

      <Footer />
    </div>
  );
}

export default Reviews;
