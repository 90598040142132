// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  apiKey: "AIzaSyB5MGXOvWWvXkLE7xTQGqRTMLjqqSH_vT0",
  authDomain: "groceden-6a330.firebaseapp.com",
  databaseURL: "https://groceden-6a330.firebaseio.com",
  projectId: "groceden-6a330",
  storageBucket: "groceden-6a330.appspot.com",
  messagingSenderId: "184770984436",
  appId: "1:184770984436:web:65509c32a49ebcd88561a1",
  measurementId: "G-GYGV8W6W0Q",
};
