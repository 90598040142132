import React, { useEffect, Fragment, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import screens from "./screens";
import {
  NotificationToaster,
  PageLoadingBlock,
  ScrollToTop,
} from "./components";
import { fetchUUID, setGuestStatus } from "./redux/actions/loginActions";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { post } from "./Barriers/apiHelper";
import "./app.css";
import CartState from "./redux/states/cart";
import CacheState from "./redux/states/cache";
import AuthState from "./redux/states/auth";
import commands from "./commands";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Routes = () => (
  <Fragment>
    <Router>
      <ScrollToTop />
      <Switch>
        {/* <PrivateRoute
        path="/transactionfailed"
        component={screens.TransactionFailedPage}
      /> */}
        <Route
          path="/transactionfailed"
          component={screens.TransactionFailedPage}
        />

        <Route path="/payment" component={screens.Payment} />
        <Route exact path="/signupconfirm" component={screens.SignUpConfirm} />
        <Route exact path="/loginconfirm" component={screens.SignUpConfirm} />
        <Route path="/orderplaced" component={screens.OrderPlacedPage} />
        <Route path="/enquiryproceeded" component={screens.OrderPlacedPage} />
        <Route path="/cart" component={screens.CartPage} />
        <Route
          path="/profile/changepassword"
          component={screens.ChangePasswordPage}
        />
        <Route
          path="/profile/:slug/:slug/:slug/orderdetails"
          component={screens.OrderDetailPage}
        />
        <Route path="/profile/:slug" component={screens.ProfilePage} />
        <Route path="/profile" component={screens.ProfilePage} />
        <Route path="/createpassword" component={screens.CreatePassword} />
        <Route path="/forgotpassword" component={screens.ForgotPassword} />
        <Route exact path="/login" component={screens.LoginPage} />
        <Route path="/help-center/:slug" component={screens.HelpCenter} />
        <Route path="/help-center" component={screens.HelpCenter} />
        <Route path="/faq" component={screens.FAQ} />
        <Route
          path="/terms-and-conditions"
          component={screens.TermsAndCondition}
        />
        <Route path="/privacy-policy" component={screens.PrivacyPolicy} />
        <Route path="/reviews" component={screens.Reviews} />

        <Route path="/shop/:productId" component={screens.ProductDetails} />
        <Route path="/shop" component={screens.Products} />
        <Route path="/contactus" component={screens.ContactUs} />
        <Route path="/ourservices" component={screens.AboutUs} />
        <Route path="/aboutus" component={screens.AboutUs} />
        <Route path="/size-guide" component={screens.SizeGuide} />
        {/* <Route path="/" component={screens.Home} /> */}
        <Route path="/" component={screens.Home2} />
      </Switch>
    </Router>
  </Fragment>
);

function App(props) {
  //dont remove it
  const store = useSelector((store) => store);

  const cartState = CartState.get();
  const cacheState = CacheState.get();
  const authState = AuthState.get();
  const state = useSelector((state) => state.logInReducer);
  const dispatch = useDispatch();
  const [cartCreated, setCartCreated] = useState(undefined);
  const [homeProductsAvailable, setHomeProductsAvailable] = useState(undefined);
  const [bannersAvailable, setBannersAvailable] = useState(undefined);
  const [webBanners, setWebBanners] = useState(undefined);
  AOS.init({
    once: true,
  });
  useEffect(() => {
    if (cartCreated === undefined || typeof cartState.cart !== "object") {
      if (
        typeof authState.user === "object" &&
        authState.user?.id !== undefined
      ) {
        commands.cart.createCart();
        setCartCreated(true);
      }
    }
  }, [authState.user, cartState.cart, cartCreated]);

  useEffect(() => {
    if (
      homeProductsAvailable === undefined ||
      typeof cacheState.homepage !== "object"
    ) {
      if (
        typeof authState.user === "object" &&
        authState.user?.id !== undefined
      ) {
        commands.cache.getHomeProducts();
        setHomeProductsAvailable(true);
      }
    }
  }, [authState.user, cacheState.homepage, homeProductsAvailable]);

  useEffect(() => {
    if (
      bannersAvailable === undefined ||
      typeof cacheState.banners !== "object"
    ) {
      if (
        typeof authState.user === "object" &&
        authState.user?.id !== undefined
      ) {
        commands.cache.getBanners();
        setBannersAvailable(true);
      }
    }
  }, [authState.user, cacheState.banners, bannersAvailable]);

  useEffect(() => {
    if (webBanners === undefined || typeof cacheState.webBanners !== "object") {
      if (
        typeof authState.user === "object" &&
        authState.user?.id !== undefined
      ) {
        commands.cache.getWebBanners();
        setWebBanners(true);
      }
    }
  });

  useEffect(async () => {
    if (!localStorage.getItem("UUID")) {
      let uuId = uuidv4();
      dispatch(fetchUUID(uuId));
      localStorage.setItem("UUID", uuId);
    }
    if (state.uuId.length > 0) {
      if (state.guest_user) await createGuestUser(state.uuId);
    }
  }, [state.uuId]);

  const createGuestUser = (uuid) => {
    let data = {
      uuid: uuid,
    };
    post(`/users/create_guest_user`, data)
      .then((res) => {
        dispatch(setGuestStatus(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Fragment>
      <NotificationToaster timer={3} />
      {window.notify /*&& authState.user && cartState.cart*/ ? (
        <Routes />
      ) : (
        <PageLoadingBlock message=" " title=" Please Wait..." />
      )}
    </Fragment>
  );
}

export default App;
