import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Formik, Field, Form } from "formik";
import * as validationSchemas from "../../../validationSchemas";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from "react-redux";
import { postUpdate } from "../../../Barriers/apiHelper";
import { useHistory } from "react-router";
import commands from "../../../commands";

function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}

function AddaddressModal(props) {
  const history = useHistory();
  const [modal, setModal] = useState(true);
  const [name, setName] = useState("");
  const [flatNo, setFlatNo] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [phone, setPhone] = useState("");
  const [required, setRequired] = useState("");
  const [checkValidation, setcheckValidation] = useState(true);
  const toggle = () => setModal(!modal);
  const userData = useSelector((state) => state.logInReducer);
  const initialValues = {
    bname: "",
    bhouseNumber: "",
    baddressLine1: "",
    baddressLine2: "",
    bcity: "",
    bstate: "",
    bcountry: "",
    bphone: "",
    bpincode: "",
  };

  const addAddress = async (values) => {
    let data = {
      uuid: userData.uuid,
      address: {
        name: values.bname,
        flat_no: values.bhouseNumber,
        address: values.baddressLine1,
        address_line_2: values.baddressLine2,
        zip_code: values.bpincode,
        phone_number: values.bphone,
        city: values.bcity,
        state: values.bstate,
        country: values.bcountry,
        is_default: false,
      },
    };
    commands.user.addAddress(data, onAddAddressSuccess, onAddAddressFailure);
  };

  const onAddAddressSuccess = () => {
    commands.user.getAddressList(
      () => {
        props.setAddress();
        history.push(`/profile/saveaddresses`);
        toggle();
      },
      () => {}
    );
  };

  const onAddAddressFailure = () => {};

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="cm-small-modal-6">
        <ModalHeader toggle={toggle} className="add-addr-title-bar p-4">
          <span>Add New Address</span>
        </ModalHeader>
        <ModalBody className="py-4 px-5 yt-add-modal-body">
          <div className="ad-addr-body-wrap">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => addAddress(values)}
              validationSchema={validationSchemas.addressForm}
            >
              {({ values, errors, setFieldTouched, touched, handleChange }) => (
                <Form className="yt-fp-form">
                  <Row form className="justify-content-between">
                    <Col md={5} className="cm-edit-add-col">
                      <FormGroup>
                        <div>
                          <span className="profile-form-tag">Name</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bname"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("bname")}
                          />
                          <FieldError
                            error={errors.bname}
                            touched={touched.bname}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6} className="cm-edit-add-col">
                      <FormGroup>
                        <div>
                          <span className="profile-form-tag">
                            Flat / House / Apartment No.
                          </span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bhouseNumber"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("bhouseNumber")}
                          />
                          <FieldError
                            error={errors.bhouseNumber}
                            touched={touched.bhouseNumber}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">Address Line 1</span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="baddressLine1"
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("baddressLine1")}
                      />
                      <FieldError
                        error={errors.baddressLine1}
                        touched={touched.baddressLine1}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">Address Line 2</span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="baddressLine2"
                        id="baddressLine2"
                        onChange={handleChange}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">City</span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="bcity"
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("bcity")}
                      />
                      <FieldError
                        error={errors.bcity}
                        touched={touched.bcity}
                      />
                    </div>
                  </FormGroup>
                  <Row form className="justify-content-between">
                    <Col md={5} className="cm-edit-add-col">
                      <FormGroup>
                        <div>
                          <span className="profile-form-tag">State</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bstate"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("bstate")}
                          />
                          <FieldError
                            error={errors.bstate}
                            touched={touched.bstate}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6} className="cm-edit-add-col">
                      <FormGroup>
                        <div>
                          <span className="profile-form-tag">Country</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bcountry"
                            onBlur={() => setFieldTouched("bcountry")}
                            onChange={handleChange}
                          />
                          <FieldError
                            error={errors.bcountry}
                            touched={touched.bcountry}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={5} className="cm-edit-add-col">
                      <FormGroup className="yt-number-filed">
                        <div>
                          <span className="profile-form-tag">Postal Code</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bpincode"
                            onBlur={() => setFieldTouched("bpincode")}
                            onChange={handleChange}
                          />
                          <FieldError
                            error={errors.bpincode}
                            touched={touched.bpincode}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6} className="cm-edit-add-col">
                      <FormGroup className="yt-number-filed">
                        <div>
                          <span className="profile-form-tag">Phone No</span>
                          <Field
                            className="py-2 form-control"
                            type="number"
                            name="bphone"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("bphone")}
                          />
                        </div>
                        <FieldError
                          error={errors.bphone}
                          touched={touched.bphone}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {required.length != 0 ? (
                    <span className="invalid_error">
                      Please Enter {required} Fields
                    </span>
                  ) : (
                    <></>
                  )}
                  <Button
                    color="secondary add-addr-btn py-3"
                    onClick={() => addAddress(values)}
                    block
                    type="submit"
                  >
                    Save Address
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default AddaddressModal;
