import Home from "./home";
import Home2 from './home2';
import ContactUs from "./contactus";
import AboutUs from "./aboutus";
import SizeGuide from './sizeguide';
import Products from "./products";
import ProductDetails from "./productdetails";
import HelpCenter from "./helpcenter";
import FAQ from './faq';
import TermsAndCondition from './termsAndConditions';
import PrivacyPolicy from './privacyPolicy';
import LoginPage from "./login";
import ForgotPassword from "./forgotpassword";
import CreatePassword from "./createpassword";
import ProfilePage from "./profile";
import CartPage from "./cart";
import OrderPlacedPage from "./orderplaced";
import TransactionFailedPage from "./transactionfailed";
import ChangePasswordPage from "./changepassword";
import OrderDetailPage from "./orderdetails";
import SignUpConfirm from "./signUpConfirmation";
import Payment from "./payment";
import Reviews from "./reviews";

export default {
  Home,
  Home2,
  Products,
  ProductDetails,
  ContactUs,
  AboutUs,
  SizeGuide,
  HelpCenter,
  FAQ,
  TermsAndCondition,
  PrivacyPolicy,
  LoginPage,
  ForgotPassword,
  CreatePassword,
  ProfilePage,
  CartPage,
  OrderPlacedPage,
  TransactionFailedPage,
  ChangePasswordPage,
  OrderDetailPage,
  SignUpConfirm,
  Payment,
  Reviews
};
