import React, { useState } from "react";
import "./css/index.scoped.css";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { FaTrashAlt, FaPlus, FaMinus } from "react-icons/fa";
import Ripple from "react-ripples";
function OrderPlaced(props) {
  const history = useHistory();
  const routeToshop = () => {
    let path = "/products";
    history.push(path);
  };
  const query = new URLSearchParams(window.location.search);
  const cartId = query.get("cartId");
  return (
    <>
      <section>
        <Container>
          <div className="orderplc-pg-inner-wrap p-4 bg-white radius-10 orderplc-pg-mb-80 ">
            <div className="orderplc-pg-success-main-wrap order-placed text-center py-5">
              <img
                src={require("./images/ic_CheckMark@3x.png")}
                className="img-fluid yt-order-placed-icn"
                width="170"
                height="212"
              />
              <div className="orderplc-wrap my-5">
                <h2 className="orderplc-ttl my-3">
                  {history.location.pathname === "/enquiryproceeded"
                    ? "Your Enquiry has been placed Successfully"
                    : "Order Placed Successfully!"}
                </h2>
                <p className="orderplc-text mb-0">
                  {history.location.pathname === "/enquiryproceeded"
                    ? "Thank you for you interest. Our team will contact you shortly"
                    : "Thank you so much for your order."}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {history.location.pathname === "/enquiryproceeded" ? (
                  <Button
                    color=" orderplc-btn py-3 px-2 mt-2 mx-3"
                    onClick={() => history.push(`/shop`)}
                  >
                    Browse Products
                  </Button>
                ) : (
                  <Button
                    color=" orderplc-btn py-3 px-2 mt-2 mx-3"
                    onClick={() => history.push(`/profile/myorder`)}
                  >
                    Track Order
                  </Button>
                )}
                <Button
                  color=" orderplc-btn py-3 px-2 mt-2 mx-3"
                  onClick={() => history.replace("/")}
                >
                  Go To Home
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
export default OrderPlaced;
