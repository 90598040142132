import React, { useState, Fragment, useEffect } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button, Form } from "reactstrap";
import * as validationSchemas from "../../validationSchemas";
import { Formik, Field } from "formik";
import commands from "../../commands";
import { useSelector } from "react-redux";
import { FiChevronDown } from "react-icons/fi";
import AllModal from "../modal";

function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}

const ContactForm = (props) => {
  const [values, setValues] = useState(undefined);
  const [messageSent, setMessageSent] = useState(false);
  const state = useSelector((state) => state.logInReducer);
  const [ShowSMModal, setShowSMModal] = useState(false);
  const openSentMessageDialog = () => {
    setShowSMModal(!ShowSMModal);
  };

  const sendMessage = (values) => {
    const sent = commands.user.sendContactMessage(values);
    setMessageSent(sent);
    openSentMessageDialog();
  };

  useEffect(() => {
    if (values !== undefined) {
      sendMessage(values);
    }
  }, [values]);
  const initialValues = {
    name: state?.loginData?.user?.name || "",
    email: state?.loginData?.user?.email || "",
    phone: state?.loginData?.user?.phone_number || "",
    purpose: "",
    message: "",
  };
  return (
    <section className="contactform yt-main-contact-us-pg">
      <Container>
        {ShowSMModal ? <AllModal modalName="messagesent" /> : ""}
        <div className="yt-main-wrapper">
          <div className="title">Contact us</div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              setValues(values), resetForm();
            }}
            validationSchema={validationSchemas.contactForm}
          >
            {({
              values,
              handleChange,
              errors,
              setFieldTouched,
              touched,
              isValid,
              handleSubmit,
            }) => (
              <Fragment>
                <Form>
                  <div className="form group">
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="group">
                          <input
                            type="text"
                            required
                            name={"name"}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("name")}
                            value={values.name}
                          />
                          <label>Name</label>
                          <FieldError
                            error={errors.name}
                            touched={touched.name}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="group">
                          <input
                            type="text"
                            required
                            name={"email"}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("email")}
                            value={values.email}
                          />
                          <label>Email</label>
                          <FieldError
                            error={errors.email}
                            touched={touched.email}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="group">
                          <input
                            type="number"
                            required
                            name={"phone"}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("phone")}
                            value={values.phone}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Phone Number</label>
                          <FieldError
                            error={errors.phone}
                            touched={touched.phone}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="group">
                          <input
                            type="text"
                            required
                            name={"purpose"}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("purpose")}
                            value={values.purpose}
                          />
                          <label>Purpose of Contact</label>
                          <FieldError
                            error={errors.purpose}
                            touched={touched.purpose}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <textarea
                        placeholder="Write your message here..."
                        name={"message"}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("message")}
                        value={values.message}
                      />
                      <div style={{ padding: "0px 20px" }}>
                        <FieldError
                          error={errors.message}
                          touched={touched.message}
                        />
                      </div>
                    </Row>
                    {/* {messageSent && (
                    <div className="w3-text-green w3-margin-top">
                      Message sent successfully.
                    </div>
                  )} */}
                  </div>
                  <Button
                    type="submit"
                    color=" yt-contact-send-btn"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Form>
              </Fragment>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
};

export default ContactForm;
