import React, { useState } from "react";
import "./css/index.scoped.css";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { FiX } from "react-icons/fi";
import * as Yup from "yup";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";

const changePasswordSchema = Yup.object({
  currentPassword: Yup.string().required("Field is required"),
  newPassword: Yup.string().required("Field is required"),
  reEnterPassword: Yup.string()
    .required("Field is required")
    .oneOf([Yup.ref("newPassword"), null], "Password Missmatch !"),
});

const ChangePassword = ({ toggleFn }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [changePasswordState] = useState({
    currentPassword: "",
    newPassword: "",
    reEnterPassword: "",
  });

  const changeUserPassword = (values) => {
    console.log(values);
    // TODO : api call to save password

    setShowSuccessModal(true);
  };

  const closeModals = () => {
    // Note: This callback Fn closes both modals after successful password reset
    setShowSuccessModal(false);
    toggleFn();
  };

  return showSuccessModal ? (
    <ChangePasswordSuccess toggleFn={closeModals} />
  ) : (
    <Modal keyboard centered show onHide={toggleFn} className="custom-modal">
      <Modal.Header className="p-2">
        <Modal.Title className="mx-auto my-2">Change Password</Modal.Title>
        <FiX size="18" className="close-btn" onClick={toggleFn} />
      </Modal.Header>
      <Modal.Body>
        <div className="custom-body">
          <p className="password-hint">
            Enter a password with alphabets A-z and a symbol
          </p>
          <Formik
            enableReinitialize
            validateOnChange
            validateOnBlur
            validationSchema={changePasswordSchema}
            initialValues={changePasswordState}
            onSubmit={changeUserPassword}
          >
            {({ handleSubmit, errors, touched }) => (
              <FormikForm
                className="main-form"
                noValidate
                onSubmit={handleSubmit}
              >
                <FormGroup>
                  <FormLabel className="custom-label">
                    Enter current password
                  </FormLabel>
                  <Field
                    name="currentPassword"
                    type="password"
                    className={`form-control custom-input ${touched.currentPassword &&
                      errors.currentPassword &&
                      "invalid"}`}
                  />
                  <p className="error">
                    &nbsp;
                    <ErrorMessage name="currentPassword" />
                  </p>
                </FormGroup>

                <FormGroup>
                  <FormLabel className="custom-label">
                    Enter new password
                  </FormLabel>
                  <Field
                    name="newPassword"
                    type="password"
                    className={`form-control custom-input ${touched.newPassword &&
                      errors.newPassword &&
                      "invalid"}`}
                  />
                  <p className="error">
                    &nbsp;
                    <ErrorMessage name="newPassword" />
                  </p>
                </FormGroup>

                <FormGroup>
                  <FormLabel className="custom-label">
                    Re-enter new password
                  </FormLabel>
                  <Field
                    name="reEnterPassword"
                    type="password"
                    className={`form-control custom-input ${touched.reEnterPassword &&
                      errors.reEnterPassword &&
                      "invalid"}`}
                  />
                  <p className="error">
                    &nbsp;
                    <ErrorMessage name="reEnterPassword" />
                  </p>
                </FormGroup>

                <Button variant="flat" type="submit" className="dark-btn" block>
                  Change Password
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ChangePasswordSuccess = ({ toggleFn }) => {
  return (
    <Modal
      keyboard
      centered
      show
      onHide={toggleFn}
      dialogClassName="custom-modal"
    >
      <ModalBody className="custom-body">
        <img src={require("./images/success.png")} alt="success" />
        <h1>Password changed successfully !</h1>
        <p>
          Yo can go back and continue browsing products <br />
          Enjoy Shopping !
        </p>
        <Button onClick={toggleFn} variant="flat" className="dark-btn">
          Go to Profile
        </Button>
      </ModalBody>
    </Modal>
  );
};

function ChangePasswordComponent() {
  return (
    <>{ShowChangePassword ? <ChangePassword /> : <ChangePasswordSuccess />}</>
  );
}
export default ChangePasswordComponent;
