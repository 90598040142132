import React from "react";
import "./css/index.scoped.css";
import { Footer, LoginComponent, Header } from "../../components";
import ProductReview from "../../components/sitereview";
import { BannerHomeTwo } from "../home2";

function LoginPage(props) {
  return (
    <>
      <Header onProps={props} />
      <h1
        style={{
          fontSize: "50px",
          textAlign: "center",
          marginTop: "0",
          paddingTop: "25px",
        }}
      >
        Accounts
      </h1>
      <LoginComponent className="main-login-page" />
      <BannerHomeTwo />
      <ProductReview />
      <Footer />
    </>
  );
}
export default LoginPage;
