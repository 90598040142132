import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Footer, Header } from "../../components";
import { Container, Table } from "reactstrap";
import { BannerHomeTwo } from "../home2";
import ScrollToTop from "react-scroll-to-top";
import service, { serviceTypes } from "../../services";
import _ from "lodash";

function SizeGuide(props) {
  const [sizeGuide, setSizeGuide] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getAboutus();
  }, [props]);

  function getAboutus() {
    const serviceType = serviceTypes.getSizeGuide();
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log(error, "Error*");
      } else {
        setSizeGuide(response?.data?.data?.size_guides[0]?.images);
      }
    });
  }
  if (sizeGuide.length > 0) {
    var sortedImages = _.sortBy(sizeGuide, ["position"]);
  }

  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      <section className="about-first">
        <Container style={{ paddingTop: "30px", width: "50%" }}>
          <div className="size_chart">
            <div>
              <h2 className="page_title">SIZE GUIDE</h2>
            </div>
            <div
              className="section_1"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h4 className="title">RINGS</h4>
              <img
                src={
                  sortedImages === undefined
                    ? require("./images/rings.jpg")
                    : sortedImages[0]?.image
                }
                className="img-fluid mt-4 mb-4"
                alt="pooja-duggal-fine-jewels"
              />
              <p className="subtitle-content">
                Pooja Duggal Fine Jewels uses standard US ring sizes. All of our
                handcrafted pieces can be made to custom sizes.
              </p>
              <div className="sizeTable">
                <Table
                  className="mb-0 cart-prodict-amount borderless"
                  striped
                  align="center"
                >
                  <thead>
                    <tr>
                      <th>Diameter (mm)</th>
                      <th>United States & Canada</th>
                      <th>Europe</th>
                      <th>UK & Australia</th>
                      <th>Singapore & Japan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>14.1</td>
                      <td>3</td>
                      <td>44</td>
                      <td>F 1/2</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>14.3</td>
                      <td />
                      <td>45</td>
                      <td>G</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>14.5</td>
                      <td>3.5</td>
                      <td />
                      <td>G 1/2</td>
                      <td />
                    </tr>
                    <tr>
                      <td>14.7</td>
                      <td />
                      <td>46</td>
                      <td>H</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td>14.9</td>
                      <td>4</td>
                      <td>47</td>
                      <td>H 1/2</td>
                      <td>7</td>
                    </tr>
                    <tr>
                      <td>15.1</td>
                      <td />
                      <td />
                      <td>I</td>
                      <td />
                    </tr>
                    <tr>
                      <td>15.3</td>
                      <td>4.5</td>
                      <td>48</td>
                      <td>I 1/2</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <td>15.5</td>
                      <td />
                      <td />
                      <td>J</td>
                      <td />
                    </tr>
                    <tr>
                      <td>15.7</td>
                      <td>5</td>
                      <td>49</td>
                      <td>J 1/2</td>
                      <td>9</td>
                    </tr>
                    <tr>
                      <td>15.9</td>
                      <td />
                      <td>50</td>
                      <td>K</td>
                      <td />
                    </tr>
                    <tr>
                      <td>16.1</td>
                      <td>5.5</td>
                      <td />
                      <td>K 1/2</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>16.3</td>
                      <td />
                      <td>51</td>
                      <td>L</td>
                      <td />
                    </tr>
                    <tr>
                      <td>16.5</td>
                      <td>6</td>
                      <td>52</td>
                      <td>L 1/2</td>
                      <td>11</td>
                    </tr>
                    <tr>
                      <td>16.7</td>
                      <td />
                      <td />
                      <td>M</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td>16.9</td>
                      <td>6.5</td>
                      <td>53</td>
                      <td>M 1/2</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>17.1</td>
                      <td />
                      <td>N</td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>17.3</td>
                      <td>7</td>
                      <td>54</td>
                      <td>N 1/2</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>17.5</td>
                      <td />
                      <td>55</td>
                      <td>O</td>
                      <td />
                    </tr>
                    <tr>
                      <td>17.7</td>
                      <td>7.5</td>
                      <td />
                      <td>O 1/2</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>17.9</td>
                      <td />
                      <td>56</td>
                      <td>P</td>
                      <td />
                    </tr>
                    <tr>
                      <td>18.1</td>
                      <td>8</td>
                      <td>57</td>
                      <td>P 1/2</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>18.2</td>
                      <td />
                      <td>18</td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>18.3</td>
                      <td />
                      <td>Q</td>
                      <td />
                      <td />
                    </tr>
                    <tr>
                      <td>18.5</td>
                      <td>8.5</td>
                      <td>58</td>
                      <td>Q 1/2</td>
                      <td>17</td>
                    </tr>
                    <tr>
                      <td>18.8</td>
                      <td />
                      <td>59</td>
                      <td>R</td>
                      <td />
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>9</td>
                      <td />
                      <td>R 1/2</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>19.2</td>
                      <td />
                      <td>60</td>
                      <td>S</td>
                      <td />
                    </tr>
                    <tr>
                      <td>19.4</td>
                      <td>9.5</td>
                      <td>61</td>
                      <td>S 1/2</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>19.6</td>
                      <td />
                      <td />
                      <td>T</td>
                      <td />
                    </tr>
                    <tr>
                      <td>19.8</td>
                      <td>10</td>
                      <td>62</td>
                      <td>T 1/2</td>
                      <td>20</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              className="section_common"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h4 className="title">BRACELETS & BANGLES</h4>
              <img
                src={
                  sortedImages === undefined
                    ? require("./images/bracelets.jpg")
                    : sortedImages[1]?.image
                }
                className="img-fluid mt-4 mb-4"
                alt="pooja-duggal-fine-jewels"
              />
              <p className="subtitle-content">
                Our flexible Bracelets and Bangles are measured by the
                circumference your wrist. To find your size, use a pieces of
                string, wrap it around your wrist and measure the length to a
                ruler. All of our designs can be made to custom sizes.
              </p>
              <div className="sizeTable">
                <Table
                  className="mb-0 cart-prodict-amount borderless"
                  striped
                  align="center"
                >
                  <thead>
                    <tr>
                      <th>Bracelets</th>
                      <th>Wrist Measurements </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Extra Small</td>
                      <td>4.75 - 5.25 in.</td>
                      <td>12.1 - 13.3 cm</td>
                    </tr>
                    <tr>
                      <td>Small</td>
                      <td>5.26 - 5.75 in.</td>
                      <td>13.4 - 14.6 cm</td>
                    </tr>
                    <tr>
                      <td>Medium</td>
                      <td>5.76 - 6.25 in.</td>
                      <td>14.7 - 15.9 cm</td>
                    </tr>
                    <tr>
                      <td>Large</td>
                      <td>6.26 - 6.75 in. </td>
                      <td>15.9 - 17.1 cm</td>
                    </tr>
                    <tr>
                      <td>Extra Large</td>
                      <td>6.76 - 7.25 in. </td>
                      <td>17.2 - 18.4 cm</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              className="section_common"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h4 className="title">NECKLACES & PENDANTS</h4>
              <img
                src={
                  sortedImages === undefined
                    ? require("./images/necklaces.jpg")
                    : sortedImages[2]?.image
                }
                className="img-fluid mt-4 mb-4"
                alt="pooja-duggal-fine-jewels"
              />
              <p className="subtitle-content">
                Our necklaces can be made to order according to your size and
                our pendants do not come with chains. We sell adjustable chains
                (16" and 18") separately. If you would like a preferred length,
                we are able to customize it for you.
              </p>
              <div className="sizeTable">
                <Table
                  className="mb-0 cart-prodict-amount borderless"
                  striped
                  align="center"
                >
                  <thead>
                    <tr>
                      <th>Length (in) </th>
                      <th>Length (cm)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>16</td>
                      <td>40.50</td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>45.75</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>50.75</td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <td>61.00</td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td>76.25</td>
                    </tr>
                    <tr>
                      <td>36</td>
                      <td>91.50</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div
              className="section_common"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h4 className="title">FEEL COMFORTABLE WITH YOUR FIT</h4>

              <p className="subtitle-content">
                If you have any questions or concerns, please contact us on
                info@poojaduggal.com
              </p>
            </div>
          </div>
        </Container>
      </section>

      <BannerHomeTwo />
      <Footer />
    </div>
  );
}

export default SizeGuide;
