import React, { useState } from "react";
import "./css/index.scoped.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import service, { serviceTypes } from "../../../services";

function DisconnectAccount(props) {
  console.log(props,"SOCIAL")
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const ConfirmLogout = () => {
    // const serviceType = serviceTypes.delSocialLogin(props?.state?.id,props?.social_auths[0]?.id);
    const serviceType = serviceTypes.delSocialLogin(props.state?.id,props.state?.social_auths[0]?.id);
    service(serviceType).then(({response,error})=>{
      setModal(!modal);
      if(error){
        window.notify([{ message: "Unable to Disconnect social account! Please try again later", type: "danger" }])
        console.log(error);
      }else{
        window.notify([{ message: "Social Account has been Disconnected.", type: "success" }])
      }
    })
   
  };
  return (
    <div className="cm-main-modal-wrapper">
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="cm-small-modal-4"
        centered={true}
        modalClassName="popopop"
      >
        <ModalHeader toggle={toggle} className="dis-acc-title-bar  border-0">
          {props.header === "FB" ? (
            <span>Disconnect Facebook</span>
          ) : (
            <span>Disconnect Google</span>
          )}
        </ModalHeader>
        <ModalBody className="py-4">
          {props.header === "FB" ? (
            <div className="text-center dis-acc-body-text px-4 pt-4">
              Are you sure you want to disconnect your facebook account from
              Escape Prive?
            </div>
          ) : (
            <div className="text-center dis-acc-body-text px-4 pt-4">
              Are you sure you want to disconnect your google account from
              Escape Prive?
            </div>
          )}
        </ModalBody>
        <ModalFooter className="dis-acc-bottom-bar p-1 border-0 d-flex justify-content-between">
          <Button
            color="secondary pp-dis-acc-btn-modal p-3 pp-dis-acc-btn-light-grey"
            onClick={toggle}
            block
          >
            Cancel
          </Button>
          <span className="yt-form-spacer" />
          <Button
            color="secondary pp-dis-acc-btn-modal p-3 pp-dis-acc-btn-dark-grey"
            onClick={ConfirmLogout}
            block
          >
            Disconnect
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default DisconnectAccount;
