import CacheState from "../../redux/states/cache";

export default function getPositionedWebBanners({ position }) {
  try {
    const cacheState = CacheState.get();

    if (position && Array.isArray(cacheState.webBanners)) {
      var banners = cacheState.webBanners;
      var filtered = banners.filter((item, idx) => item.position == position);
      return filtered;
    } else {
      return [];
    }
  } catch (error) {
    window.notify([
      {
        message:
          error.message || "Error occured in getting positioned Web banners",
        type: "danger",
      },
    ]);
  }
}
