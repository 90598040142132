import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "./css/index.scoped.css";
import { BsChevronCompactRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import commands from "../../commands";
import _ from "lodash";

function DisplayBanner1() {
  const history = useHistory();
  const myBanner = commands.cache.getPositionedWebBanners({ position: 3 });
  const sortedBanner = _.sortBy(myBanner[0]?.images, ["position"]);
  const myImage = sortedBanner[1]?.image.split(".")[4];
  const myType = myImage?.substring(0,myImage.indexOf("?"));
  return (
    <section className="home-banner1">
      <Container>
        <h2
          className="banner-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/* TILL ETERNITY . . . */}
          {myBanner[0]?.title}
        </h2>
        <p
          className="banner-subtitle"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          {/* Invest in  Beautiful Classic Eternity Necklace, Bracelet or Band */}
          {myBanner[0]?.subtitle}
        </p>
        <Row>
          <Col xs={12} sm={4} lg={4}>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="display-product-container "
              onClick={() => (window.location.href = sortedBanner[0]?.url)}
              style={{ cursor: "pointer" }}
              style={{
                // backgroundImage: `url(${require("./images/Pooja-9.jpg")}`,
                backgroundImage: `url(${sortedBanner[0]?.image})`,
                height: "720px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              {/* <img
                className="img-fluid myBannerimg"
                src={require("./images/Pooja-9.jpg")}
              /> */}
              <div>
                <span
                  className="myBannercontent"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {/* 2021 Collection Essential Shapes */}
                  {sortedBanner[0]?.title}
                </span>
                <Button
                  variant="flat"
                  className="my-btn"
                  onClick={() => (window.location.href = sortedBanner[0]?.url)}
                  data-aos="fade-up"
                >
                  DISCOVER &nbsp; <BsChevronCompactRight />
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={4} lg={4}>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              onClick={() => (window.location.href = sortedBanner[1]?.url)}
              style={{ cursor: "pointer" }}
              className="mobilePadding"
            >
              <video
                className="video-container"
                playsInline
                autoPlay="autoplay"
                loop
                muted
                data-reactid=".0.1.0.0"
              >
                <source
                  type="video/mp4"
                  // src={require("./Video/fineJewelvideo.mp4")}
                  src={myType === "mp4" ? sortedBanner[1]?.image : require("./Video/fineJewelvideo.mp4")}
                />
              </video>
            </div>
          </Col>
          <Col xs={12} sm={4} lg={4}>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="display-product-container mobilePadding"
              onClick={() => (window.location.href = sortedBanner[2]?.url)}
              style={{ cursor: "pointer" }}
              style={{
                // backgroundImage: `url(${require("./images/Pooja-18.jpg")}`,
                backgroundImage: `url(${sortedBanner[2]?.image}`,
                height: "720px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              {/* <img
                className="img-fluid myBannerimg"
                src={require("./images/Pooja-18.jpg")}
              /> */}
              <div>
                <span
                  className="myBannercontent"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {/* Inlay Collection */}
                  {sortedBanner[2]?.title}
                </span>
                <Button
                  variant="flat"
                  className="my-btn"
                  data-aos="fade-up"
                  onClick={() => (window.location.href = sortedBanner[2]?.url)}
                >
                  DISCOVER &nbsp; <BsChevronCompactRight />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DisplayBanner1;
