import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";
import cartCommands from "./index";

export default async function createOrder() {
  try {
    const cartState = CartState.get();
    const authState = AuthState.get();
    const user = authState.user;
    var serviceType = undefined;

    //auth setter part with optional test data for guest
    if (typeof user === "object") {
      serviceType = serviceTypes.createOrderService();
    } else {
      throw new Error("Login data error, try logout and login again.");
    }

    if (serviceType) {
      serviceType.params.order_id = cartState.cart?.id;
    }

    const { response, error } = await service(serviceType);
    if (error) {
      throw new Error(error.message || "Error occured in creating order.");
    } else if (response) {
      // console.log(response);
      if (response.data?.success === true) {
        window.notify([
          {
            message: response.data?.message || "Order placed successfully",
            type: "success",
          },
        ]);
        return response.data?.data?.order;
      } else {
        throw new Error(
          response.data?.message || "Error occured in removing from cart"
        );
      }
    }
  } catch (error) {
    window.notify([
      {
        message: error.message || "Error occured in creating order",
        type: "danger",
      },
    ]);
  }
}
