import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import {
  Footer,
  Header,
  DisplayBannerNew,
  DisplayBanner1,
  DisplayBanner4,
  DisplayBanner5,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchUUID, setGuestStatus } from "../../redux/actions/loginActions";
import { get, getProducts, post } from "../../Barriers/apiHelper";
import { v4 as uuidv4 } from "uuid";
import { Button, Container } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
import { useHistory } from "react-router";
import commands from "../../commands";

function Home2(props) {
  const dispatch = useDispatch();
  const [products, setHomeProduts] = useState([]);
  const state = useSelector((state) => state.logInReducer);

  useEffect(async () => {
    // console.log(state.uuId);
    if (!localStorage.getItem("UUID")) {
      let uuId = uuidv4();
      dispatch(fetchUUID(uuId));
      localStorage.setItem("UUID", uuId);
    }

    // if (Object.keys(state.loginData).length > 0 && state.uuId.length > 0) {
    //   callProducts(state.loginData.token, state.uuId);
    // }
    if (state.uuId.length > 0) {
      // console.log("UUID Length");
      if (state.guest_user) await createGuestUser(state.uuId);
    }
  }, []);

  function callProducts(token, uuid) {
    // console.log(uuid);
    let headers = {
      headers: {
        Authorization: token.token_type + " " + token.access_token,
      },
    };
    getProducts(
      `/products/get_all_products?page=1&per_page=9&uuid=${uuid}`,
      headers
    )
      .then((res) => {
        setHomeProduts(res.data.data);
      })
      .catch((Err) => {
        console.log("err", Err);
      });
  }
  const createGuestUser = (uuid) => {
    let data = {
      uuid: uuid,
    };
    post(`/users/create_guest_user`, data)
      .then((res) => {
        // console.log(res);
        // callProductsUUID(res.data.data.uuid);
        dispatch(setGuestStatus(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callProductsUUID = (uuid) => {
    let headers;
    if (localStorage.getItem("token")) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
    }
    get(`/products/get_all_products?page=1&per_page=9&uuid=${uuid}`, {
      headers,
    })
      .then((res) => {
        // console.log(res);
        setHomeProduts(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      <BannerHomeOne />
      <DisplayBannerNew />
      <DisplayBanner1 />
      <DisplayBanner5 />
      {/* <DisplayBanner2 /> */}
      <DisplayBanner4 />
      <BannerHomeTwo />

      <Footer />
    </div>
  );
}

export const BannerHomeOne = () => {
  const [banner, setBanner] = useState(null);
  const heroBanner = commands.cache.getPositionedBanners({ position: 1 });
  const myImage = heroBanner[0]?.images[0]?.image.split(".")[4];
  const myType = myImage?.substring(0, myImage.indexOf("?"));

  return (
    <section className="home-banner">
      {/* <img
        className="img-fluid"
        src={require("./images/home_bg.jpg")}
        onError={ShowStaticBanner}
      /> */}

      <video
        className="video-container myVideo"
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          type="video/mp4"
          // src={require("./Video/FirstBannerVideo.mp4")}
          src={
            myType === "mp4"
              ? heroBanner[0]?.images[0]?.image
              : require("./Video/FirstBannerVideo.mp4")
          }
        />
      </video>
    </section>
  );
};

export const BannerHomeTwo = () => {
  const [banner, setBanner] = useState(null);
  const history = useHistory();
  const lastBanner = commands.cache.getPositionedWebBanners({ position: 6 });

  return (
    <div className="banner-container last_banner">
      <img
        className="img-fluid"
        // src={require("./images/home_bg2.jpg")}
        src={lastBanner[0]?.images[0]?.image}
      />
      <Container>
        <div className="content">
          <h1
            className="mytextbanner2"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            {/* "Our promise is to help you create the most exquisite hand-crafted
            Investment Grade Jewelry" */}
            {lastBanner[0]?.images[0]?.title}
          </h1>
        </div>
        <Button
          variant="flat"
          className="my-btn"
          onClick={() => history.push("/aboutus")}
          onClick={() => (window.location.href = lastBanner[0]?.images[0]?.url)}
          data-aos="fade-up"
          data-aos-duration="2000"
          style={{}}
        >
          ABOUT US
        </Button>
      </Container>
    </div>
  );
};

export default Home2;
