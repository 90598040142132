import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import "../products/css/index.scoped.css";
import { Footer, FeatureBar, Header } from "../../components";
import { Container } from "reactstrap";
import ProductReview from "../../components/sitereview";
import { BannerHomeTwo } from "../home";
import ScrollToTop from "react-scroll-to-top";
import service, { serviceTypes } from "../../services";

function AboutUs(props) {
  const [aboutUs, setAboutus] = useState(undefined);
  const [typeFor, setTypeFor] = useState({ title: "", subTitle: "" });
  const qparams = new URLSearchParams(window.location.search);
  const myUrl = window.location.pathname;

  useEffect(() => {
    getAboutus();
  }, []);

  useEffect(() => {
    const typeQuery = qparams.get("type");
    if (typeQuery === "1") {
      setTypeFor({
        title: "I'm getting Engaged",
        subTitle:
          "We are very excited for you and would love to be a part of your engagement ring journey. We are here to help with understanding the 4C's and guide you all the way through to create a bespoke custom ring. Book a complimentary consultation with Pooja Duggal, simply use the contact us form to get in touch. ",
      });
    } else if (typeQuery === "2") {
      setTypeFor({
        title: "Celebrating a Milestone",
        subTitle:
          "What better way than to create a special Jeweled custom memento to hold on to this memory forever! We can bring anything to life so you and your loved ones can cherish this milestone forever!",
      });
    } else if (typeQuery === "3") {
      setTypeFor({
        title: "Children's Customized Gifting",
        subTitle:
          "We cater to all custom requests and special orders for children's gifting. We offer children's evil eye collection, baby  and toddler gold and diamond earrings, toddler bracelets, bangles and pendants.",
      });
    } else if (typeQuery === "4") {
      setTypeFor({
        title: "Wedding Jewelry",
        subTitle:
          "Our team can design and create custom jewels from head to toe to match your wedding look and budget.",
      });
    } else {
      setTypeFor({
        title: "Our Services",
        subTitle:
          "POOJA DUGGAL FINE JEWEL's uses standard US ring sizes. If you are not satisfied with your chosen size, send it back to us and we will size it for you free of charge (you only pay for shipping). *All eternity bands are final sale and cannot be resized.",
      });
    }
  }, [qparams.get("type")]);

  function getAboutus() {
    const serviceType = serviceTypes.getHelpCenterData();
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log(error, "Error*");
      } else {
        setAboutus(response?.data?.data?.help_centers);
      }
    });
  }

  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      {myUrl == "/aboutus" ? (
        <>
          <section className="about-first">
            <Container style={{ paddingTop: "30px" }}>
              <h2 className="title">About Us</h2>
              {aboutUs && (
                <>
                  {aboutUs.map((data, index) => (
                    <p className="para" key={index}>
                      {data.title === "About Us" ? (
                        <>
                          <p
                            className="para"
                            dangerouslySetInnerHTML={{
                              __html: data.content.replace(/\n/g, "<br />"),
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                  ))}
                </>
              )}
              <br />
            </Container>
          </section>
          <BannerHomeTwo />
        </>
      ) : 
      <section >
        <div className="image-container">
          <img
            className="img-fluid myImage"
            src={require("./images/PoojaBanner.jpg")}
          />
          <div className="after banner-content">
            <h3
              className="banner-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >{typeFor?.title}
            </h3>
            <p
              className="banner-subtitle "
              data-aos="fade-up"
              data-aos-duration="3000"
            >{typeFor?.subTitle}
            </p>
          </div>
        </div>
      </section>
      }
      <ProductReview />
      <FeatureBar />
      <Footer />
    </div>
  );
}

export default AboutUs;
