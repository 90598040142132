import React, { useState, useEffect, Fragment } from "react";
import "./css/index.scoped.css";
import {
  Footer,
  Header,
  ThreePromo,
  ProfileComponent,
  ErrorOccuredBlock,
  PageLoadingBlock,
  ProductReview,
  FeatureBar,
} from "../../components";
import "./css/index.scoped.css";
import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import CacheState from "../../redux/states/cache";
import { BannerHomeTwo } from "../home";
import ScrollToTop from "react-scroll-to-top";
import { getEnquiries } from "../../services/serviceTypes";

function ProfilePage(props) {
  const authState = AuthState.get();
  const cacheState = CacheState.get();
  const [wishlist, setWishlist] = useState([]);
  const [getEnquiries, setEnquiries] = useState([]);
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [address, setAddress] = useState([]);

  useEffect(() => {
    getWishlistProducts();
    getMyOrders();
    getMyAddress();
    getMyEnquiries()
  }, []);

  function getWishlistProducts() {
    const serviceType = serviceTypes.getWishlist(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/"),
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setWishlist(undefined);
                getWishlistProducts();
              },
            });
          }
        } else if (response) {
          if (typeof response.data === "object") {
            setWishlist(response.data.data);
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setWishlist(undefined);
                getWishlistProducts();
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  function getMyOrders() {
    // const serviceType = serviceTypes.getMyOrdersList(authState.user.id);
    // service(serviceType)
    //   .then(({ response, error }) => {
    //     if (error) {
    //       window.notify([{ message: error.message }]);
    //       if (error.status === 404) {
    //         setErrorData({
    //           title: "Oh Noes!, 404 Not Found",
    //           message: "The product you are looking for is not available!",
    //           buttonText: "Go to Home",
    //           onButtonPress: () => props.history.push("/"),
    //         });
    //       } else {
    //         setErrorData({
    //           title: "Oh Noes!, Error Occured",
    //           message: error.message,
    //           buttonText: "Retry",
    //           onButtonPress: () => {
    //             setLoading(true);
    //             setErrorData(null);
    //             setOrders(undefined);
    //             getMyOrders();
    //           },
    //         });
    //       }
    //     } else if (response) {
    //       if (typeof response.data.data === "object") {
    //        setOrders(response?.data?.data?.order);
    //         setErrorData(null);
    //       } else {
    //         setErrorData({
    //           title: "Oh Noes!, Error Occured",
    //           message: "Unknown response received from server.",
    //           buttonText: "Retry",
    //           onButtonPress: () => {
    //             setLoading(true);
    //             setErrorData(null);
    //             setOrders(undefined);
    //             getMyOrders();
    //           },
    //         });
    //       }
    //     }
    //   })
    //   .finally(() => setTimeout(() => setLoading(false), 1000));
  }
  function getMyAddress() {
    const serviceType = serviceTypes.getAddressList(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/"),
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setAddress(undefined);
              },
            });
          }
        } else if (response) {
          if (response.data.success === true) {
            setAddress(response?.data?.data);
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setAddress(undefined);
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }
  function getMyEnquiries() {
    const serviceType = serviceTypes.getEnquiries(authState.user.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/"),
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setEnquiries(undefined);
              },
            });
          }
        } else if (response) {
          if (response.data.success === true) {
            setEnquiries(response?.data?.data?.customer_enquiry);
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setEnquiries(undefined);
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }
  return (
    <div>
      <Header onProps={props} />
      {/*<HeroBanner />*/}
      <ScrollToTop smooth color="#000" />
      {wishlist ? (
        <ProfileComponent
          onProps={props}
          wishlist={wishlist}
          order={orders}
          address={address}
          enquiries={getEnquiries}
          getEnquiries={getMyEnquiries}
          getWishlist={getWishlistProducts}
          getOrders={getMyOrders}
          getAddress={getMyAddress}
        />
      ) : (
        <Fragment>
          {!loading && (
            <ErrorOccuredBlock
              title={errorData?.title || "Oh Noes!, Error Occured"}
              message={errorData?.message || "Unknown Error Occured."}
              buttonText={errorData?.buttonText || "Go to Home"}
              onButtonPress={
                errorData?.onButtonPress || (() => props.history.push("/"))
              }
            />
          )}
          {loading && (
            <PageLoadingBlock
              title={"Please wait..."}
              message={"The product you are looking for is loading!"}
              buttonText={"Go to Home"}
              onButtonPress={() => props.history.push("/")}
            />
          )}
        </Fragment>
      )}
      <ThreePromo />
      <BannerHomeTwo />
      <section className="my-4">
        <ProductReview />
      </section>
      <FeatureBar />
      <Footer />
    </div>
  );
}
export default ProfilePage;
