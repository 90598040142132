import React, { useState ,useEffect} from "react";
import "./css/index.scoped.css";

import ExistAddress from './ExistAddress';
import NoAddress from "./NoAddress";


function SavedAddress(props) {
  
 
  return <><ExistAddress onProps={props} /> </>;
  
}
export default SavedAddress;
