import * as yup from "yup";

export default {
  name: yup
    .string()
    .min(6)
    .matches(/^[a-zA-Z ]+$/,"Only letters are allowed.")
    .required("Full Name is required"),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
  .string()
  .min(8, "Minimum Password length is 8.")
  .max(16, "Maximum Password length is 16")
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.")
  .required("Password is required"),
  confirmpassword: yup
    .string()
    .min(8)
    .oneOf(
      [yup.ref("password"), null],
      "The Password you entered did not match. Please try again."
    )
    .required("Confirm Password is required"),
  password1: yup
  .string()
  .min(8, "Minimum Password length is 8.")
  .max(16, "Maximum Password length is 16")
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.")
  .required("Password is required"),
    otp: yup
    .number()
    .typeError("Only numbers are allowed.")
    .required("OTP is required.")
    .positive("Negative numbers are not allowed.")
    .integer("Number can't contain a decimal.")
    .min(10000, "Minimum 5 digits are required.")
    .max(999999,  "Maximum 6 digits are allowed."),
  phone: yup
    .number()
    .min(1000000000, "Minimum 10 Digits are allowed")
    .max(999999999999, "Maximum 12 Digits are allowed")
    .typeError("Only numbers are allowed.")
    .required("Phone Number is required."),
  pincode: yup.string().length(8, "Enter Valid Zip/Pincode"),
};
