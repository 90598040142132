import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { FaHeart } from "react-icons/fa";
import { AllModal } from "../../../components";
import { useSelector } from "react-redux";
import { getProducts } from "../../../Barriers/apiHelper";
import classnames from "classnames";
import NoAddress from "./NoAddress";
import { useHistory } from "react-router";
import commands from "../../../commands";
import {GrEdit} from 'react-icons/gr';
import {RiDeleteBinLine} from 'react-icons/ri'
import UserState from "../../../redux/states/user";

function ExistAddress({onProps}) {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [userAddress, setUserAddress] = useState([]);
  const [ShowETModal, setShowETModal] = useState(false);
  const [delRecords, setDelRecords] = useState([]);
  const [check, setCheck] = useState("");
  const [ShowADModal, setShowADModal] = useState(false);
  const [ShowDeleteAddressModa, setDeleteAddressModa] = useState(false);
  const [showDefaultAddressModal, setDefaultAddressmModal] = useState(false);
  const [ShowAddress, setfunc] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const state = useSelector((state) => state.logInReducer.loginData);
  const userState = useSelector((state) => state.logInReducer);

  

  const onAddressSuccess = () => {
    onProps?.getAddress();
    // setUserAddress(userState.address);
  };

  const onAddessFailure = () => {};

  const openDeleteAddressModal = (x) => {
    setDelRecords(x);
    setDeleteAddressModa(!ShowDeleteAddressModa);
  };

  const openAddAddressModal = () => {
    // history.push('/profile/saveaddresses')
    setShowADModal(!ShowADModal);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const openEditAddressModal = (x) => {
    setCurrentRecord(x);
    setShowETModal(!ShowETModal);
  };

  function setDefaultAddress(x) {
    // console.log("Default address set to", x);
    x.is_default = true;
    commands.user.updateAddress(
      x,
      x.id,
      onSetDefaultSuccess,
      onSetDefaultFailure
    );
  }

  function onSetDefaultSuccess() {
    commands.user.getAddressList(onAddressSuccess, onAddessFailure);
    setDefaultAddressmModal(!showDefaultAddressModal);
  }

  function onSetDefaultFailure() {
    // setDefaultAddressmModal(!showDefaultAddressModal)
  }

  return (
    <>
      {onProps?.address?.length > 0 ? (
        <div className="">
          {ShowETModal && (
            <AllModal
              currentRecord={currentRecord}
              userData={state}
              setAddress={() => onAddressSuccess()}
              modalName="editaddress"
            />
          )}

          {ShowADModal && (
            <AllModal
              userData={state}
              modalName="Addaddress"
              setAddress={() => onAddressSuccess()}
            />
          )}

          {ShowDeleteAddressModa && (
            <AllModal
              deletedRecords={delRecords}
              setAddress={() => onAddressSuccess()}
              modalName="deleteaddress"
            />
          )}
          {showDefaultAddressModal && (
            <AllModal userData={state} modalName="DefaultAddress" />
          )}

          <div className="profile-pg-inner-wrapper">
            <div className="profile-pg-sa-address-main-wrap">
              <ul className="pp-sa-list-none p-0 m-0 pp-sa-all-addres-list">
                {onProps?.address.map((x) => {
                  return (
                    <li
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                      key={x.id}
                    >
                      {x.checked}
                      <div className="profile-pg-address-list bg-white radius-10 profile-pg-mb-30">
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="pp-sa-img-wrap d-flex">
                            <img
                              src={require("./images/address-icn-list.png")}
                              width="65"
                              height="65"
                            />
                            <div className="pp-sa-info-wrap ">
                              <Row>
                                <Col>
                                  <h2 className="pp-sa-type mt-0">
                                    {x.address_type}
                                  </h2>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={10}>
                                  <p className="pp-sa-address mb-0">
                                    {x.flat_no}, {x.address}, {x.city},{" "}
                                    {x.country},{x.zip_code}. {x.checked}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="pp-sa-action-wrap d-flex align-items-end justify-content-end">
                            <div className="pp-sa-edit pr-3 border-right">
                              <Button
                                color="link pp-add-edit-btn"
                                onClick={() => openEditAddressModal(x)}
                                title="Edit Address"
                              >
                                <GrEdit/>
                              </Button>
                            </div>
                            <div className="pp-sa-delet text-right pl-3">
                              {x.is_default ? (
                                <img
                                  src={require("./images/checked.png")}
                                  width="29"
                                  height="29"
                                  className="img-fluid d-block ml-auto mb-2"
                                />
                              ) : (
                                <img
                                  src={require("./images/unchecked.png")}
                                  width="29"
                                  height="29"
                                  className="img-fluid d-block ml-auto mb-2"
                                  onClick={() => {
                                    setDefaultAddress(x);
                                  }}
                                />
                              )}
                              <Button
                                color="link pr-0 pp-add-delete-btn"
                                onClick={() => openDeleteAddressModal(x)}
                                title="Delete Address ?"
                              >
                                <RiDeleteBinLine/>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="d-flex justify-content-end">
                <Button
                  color=" pp-no-addr-btn py-3 px-5"
                  onClick={openAddAddressModal}
                >
                  Add an Address
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoAddress {...onProps}/>
      )}
    </>
  );
}

export default ExistAddress;
