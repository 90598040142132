import React, { useEffect, useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { Button } from "react-bootstrap";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "./style.scoped.css";
import { NavLink } from "react-router-dom";
import service, { serviceTypes } from "../../services";

const ProductReview = (props) => {
  const [customerFeedback, setFeedback] = useState([]);
  const carousalArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <FiChevronLeft size={25}  className={customerFeedback.length === 2 ? "dont-show-me" : "show-me"}/>
      ) : (
        <FiChevronRight size={25}  className={customerFeedback.length === 2? "dont-show-me" : "show-me"}/>
      );
    return (
      <Button
        className="carousal-arrow"
        variant="flat"
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </Button>
    );
  };
  useEffect(() => {
    if(customerFeedback.length === 0){
      const serviceType = serviceTypes.customerFeedback();
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log(error, "ERROR /***");
      } else if (response?.data?.data) {
        setFeedback(response?.data?.data?.feedback);
      }
    });
    }
  }, [props]);

  const mybreakPoints =
    [{ width: 400, itemsToShow: 1 },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 2 },
    { width: 1450, itemsToShow: 2 },
    { width: 1750, itemsToShow: 2 },]
  return (
    <div className="quotes-wrapper">
      <h1 className="title">Trusted By Customers Worldwide</h1>
      <Carousel
        className="main-carousal"
        renderArrow={carousalArrow}
        itemsToShow={2}
        breakPoints={mybreakPoints}
        itemPadding={[15, 15]}
        pagination={false}
        style={{ textAlign: "left" }}
      >
        {customerFeedback?.map((product, index) => (
          <div className="carousal-item" key={index}>
            <img src={require("./images/Quote.svg")} alt='"' />
            <p className="summary">{product.title}</p>
            <p className="info">{product.description}</p>
            <div className="user-info">
              <span>-</span>
              <div className="location">
                <p>&nbsp;{product.customer_name}</p>
                <span>&nbsp;{product.customer_location}</span>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <NavLink to="/reviews" >
        <button type="button" className="btn btn-dark myReviewbtn py-2 px-4">
          View All Review
        </button>
      </NavLink>
    </div>
  );
};

export default ProductReview;
