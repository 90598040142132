import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { AllModal } from "..";
import { Row, Col, Button } from "reactstrap";
import "./css/index.scoped.css";
import { FaCamera } from "react-icons/fa";
import { useSelector } from "react-redux";
import ChangePassword from "../modal/ChangePassword";
import EditProfileModal from "../modal/editprofile";
import AuthState from "../../redux/states/auth";


const ProfileContennt = (props) => {
  
  const state = useSelector((state) => state.logInReducer.user);
  const data = AuthState.get();

  const [ShowEditProfileModal, setShowEditProfileModal] = useState(false);
  
  const [showChangePassworModal, setShowChangePasswordModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [username, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [img, setImg] = useState("");
  const openEditProfileModal = () =>
    setShowEditProfileModal(!ShowEditProfileModal);

  // useEffect(() => {
  //   if (props.userData && Object.keys(props.userData).length > 0) {
  //     setUserName(props.userData.name);
  //     setUserEmail(props.userData.email);
  //     setPhone(props.userData.phone_number);
  //     setImg(state.profile_picture);
  //   }
  // }, [props]);
  useEffect(() => {
    
    if (data.user && Object.keys(data.user).length > 0) {
      setUserName(data.user.name);
      setUserEmail(data.user.email);
      setPhone(data.user.phone_number);
      setImg(data.user.profile_picture);
    }
    
  }, [props]);

  return (
    <div className="profile-pg-inner-wrap p-4 bg-white radius-10 profile-pg-mb-30">
      <div className="profile-pg-inner-wrapper">
        <div className="profile-tab-content">
          <Row className="yt-cm-ptc-row">
            <Col md={12} className="yt-cm-ptc-col">
              <div className="d-flex align-items-center mb-5 yt-profile-img-nm-wrap">
                {props.userData?.profile_picture ? (
                  <div className="img-upload d-flex align-items-center justify-content-center">
                    <img
                      alt="Profile Pics"
                      // src={state?.profile_picture ? state?.profile_picture : ""}
                      src={props.userData?.profile_picture}
                      className="img-fluid"
                    />
                  </div>
                ) : (
                  <div className="img-upload d-flex align-items-center justify-content-center">
                    <FaCamera />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6} className="yt-cm-ptc-col">
              <div className="profile-data-wrap">
                <span className="profile-data-tag">Name</span>
                <p className="profile-user-name py-2">{props?.userData?.name}</p>
              </div>
            </Col>
            <Col md={6} className="yt-cm-ptc-col">
              <div className="profile-data-wrap">
                <span className="profile-data-tag">Email</span>
                <p className="profile-user-name py-2">{props?.userData?.email}</p>
              </div>
            </Col>
            <Col md={6} className="yt-cm-ptc-col">
              <div className="profile-data-wrap">
                <span className="profile-data-tag">Phone No</span>
                <p className="profile-user-name py-2">{props?.userData?.phone_number}</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="d-flex align-items-center justify-content-end justify-content-xl-between">
                <Button
                  onClick={() => setShowChangePasswordModal(true)}
                  color="link profile-edit-pass mr-2 p-xl-0"
                >
                  Change Password
                </Button>
                <Button
                  color=" profile-edit-btn"
                  onClick={openEditProfileModal}
                >
                  Edit Profile
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {ShowEditProfileModal && (
        <EditProfileModal userData={props} toggleFn={() => setShowEditProfileModal(false)} />
      )}
      {showChangePassworModal && (
        <ChangePassword toggleFn={() => setShowChangePasswordModal(false)} />
      )}
    </div>
  );
};

export default ProfileContennt;
