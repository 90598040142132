import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col } from "reactstrap";
import AllModal from "../../modal";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../../../config/firebase.config";
import { userSocialLogin } from "../../../redux/actions/loginActions";
function ConnectAccounts(props) {
  const state = useSelector((state) => state.logInReducer.user);
  const [activeTab, setActiveTab] = useState("1");
  const [modalHead,setModalHead] = useState("");
  const [activeSocial, setActiveSocial] = useState("");
  const [signupError, setSignupError] = useState("");
  const dispatch = useDispatch();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [ShowDisconnectModal, Disconnectfunc] = useState(false);
  const openDisconnectModal = () => Disconnectfunc(!ShowDisconnectModal);

  const DissconnectAccount = (value, name) => {
    if (value == activeTab) {
      setModalHead(name)
      console.log(value + " == " + name);
      console.log(activeTab);
      openDisconnectModal();
    }
  };

  function routeToAll(value) {
    if (value !== undefined) {
      let path = "/" + value;
      history.push(path);
    } else {
      let path = "/";
      history.push(path);
    }
  }
  console.log(state, "123");
  useEffect(() => {
    establishSocials();
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    // Load the SDK asynchronously
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function() {
      FB.init({
        appId: "1234953260198976",
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });
      FB.AppEvents.logPageView();
      FB.Event.subscribe("auth.statusChange", function(response) {
        if (response.authResponse) {
          checkLoginState();
        } else {
          console.log("---->User cancelled login or did not fully authorize.");
        }
      });
    };
  }, [firebase]);

  function establishSocials() {
    if (state?.is_social_login) {
      if (state.social_auths[0].provider === "google") {
        setActiveSocial("Google");
      } else {
        setActiveSocial("Fb");
      }
    }
  }

  function statusChangeCallback(response) {
    if (response.status === "connected") {
      const data = {
        access_token: response.authResponse.accessToken,
        loginType: 2,
        grant_type: "password",
        provider: "facebook",
      };
      let data1;
      try {
        data1 = dispatch(
          userSocialLogin(data, socialLoginSuccess, socialLoginFailure)
        );
      } catch (err) {
        setSignupError("Error while login with Facebook");
        console.log("Error: ", err);
      }
    } else if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      setSignupError("Error while login with Facebook");
    } else {
      setSignupError("Error while login with Facebook");
    }
  }

  function checkLoginState() {
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
    });
  }

  function connectFacebook() {
    FB.login(checkLoginState());
  }

  function connectGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    let accessToken;
    provider.addScope("profile");
    provider.addScope("email");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token.
        accessToken = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(accessToken);
        const data = {
          access_token: accessToken,
          loginType: 2,
          grant_type: "password",
          provider: "google",
        };
        let data1;
        try {
          data1 = dispatch(
            userSocialLogin(data, socialLoginSuccess, socialLoginFailure)
          );
        } catch (err) {
          setSignupError("Error while login with Google");
          console.log("Error: ", err);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const socialLoginSuccess = (res) => {
    routeToAll();
  };

  const socialLoginFailure = (err) => {
    setSignupError(err);
    console.log(err);
  };

  return (
    <div className="profile-pg-inner-wrap bg-white  profile-pg-mb-30">
      <div className="profile-pg-inner-wrapper">
        <div className="pp-ca-main-wrap radius-10">
          {ShowDisconnectModal ? <AllModal modalName="disconnect" head={modalHead} state={state}/> : ""}
          <ul className="m-0 p-0 pp-ca-list-none pp-ca-listing">
            {state?.social_auths.length > 0 ? (
              <>
                {state?.social_auths[0].provider === "google" ? (
                  <>
                    <li className={classnames({ active: activeTab === "2" })}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pp-ca-type d-flex align-items-center">
                          <img
                            src={require("./images/fb-icn-pp.png")}
                            width="35"
                            height="35"
                            className="img-fluid mr-3"
                          />
                          <div className="pp-ca-name d-flex align-items-center">
                            {activeTab === "2" ? (
                              <span className="pp-ca-tag-name  mb-2">
                                Connected as
                              </span>
                            ) : (
                              ""
                            )}
                            <h2
                              className="pp-ca-user-name mt-0 mb-0 cursordefault"
                              onClick={connectFacebook}
                            >
                              Connect Facebook Account
                            </h2>
                          </div>
                        </div>
                        <div className="pp-ca-remove">
                          {activeTab === "2" ? (
                            <img
                              src={require("./images/remove-icn.png")}
                              width="20"
                              height="20"
                              className="img-fluid"
                              onClick={() => {
                                DissconnectAccount("1", "FB");
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </li>
                    <li className={classnames({ active: activeTab === "1" })}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pp-ca-type d-flex align-items-center">
                          <img
                            src={require("./images/gg-icn-pp.png")}
                            width="35"
                            height="35"
                            className="img-fluid mr-3"
                          />
                          <div className="pp-ca-name d-flex align-items-center flex-wrap">
                            {activeTab === "1" ? (
                              <span className="pp-ca-tag-name  mb-2">
                                Connected as
                              </span>
                            ) : (
                              ""
                            )}

                            <h2 className="pp-ca-user-name mt-0 mb-0 w-100">
                              {state?.social_auths[0].display_name}
                            </h2>
                          </div>
                        </div>
                        <div className="pp-ca-remove">
                          {activeTab === "1" ? (
                            <img
                              src={require("./images/remove-icn.png")}
                              width="20"
                              height="20"
                              className="img-fluid"
                              onClick={() => {
                                DissconnectAccount("1", "FB");
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className={classnames({ active: activeTab === "1" })}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pp-ca-type d-flex align-items-center">
                          <img
                            src={require("./images/gg-icn-pp.png")}
                            width="35"
                            height="35"
                            className="img-fluid mr-3"
                          />
                          <div className="pp-ca-name d-flex align-items-center flex-wrap">
                            {activeTab === "1" ? (
                              <span className="pp-ca-tag-name  mb-2">
                                Connected as
                              </span>
                            ) : (
                              ""
                            )}

                            <h2 className="pp-ca-user-name mt-0 mb-0 w-100">
                              {state?.social_auths[0].display_name}
                            </h2>
                          </div>
                        </div>
                        <div className="pp-ca-remove">
                          {activeTab === "1" ? (
                            <img
                              src={require("./images/remove-icn.png")}
                              width="20"
                              height="20"
                              className="img-fluid"
                              onClick={() => {
                                DissconnectAccount("1", "FB");
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </li>
                    <li className={classnames({ active: activeTab === "2" })}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="pp-ca-type d-flex align-items-center">
                          <img
                            src={require("./images/gg-icn-pp.png")}
                            width="35"
                            height="35"
                            className="img-fluid mr-3"
                          />
                          <div className="pp-ca-name d-flex align-items-center">
                            {activeTab === "2" ? (
                              <span className="pp-ca-tag-name  mb-2">
                                Connected as
                              </span>
                            ) : (
                              ""
                            )}
                            <h2
                              className="pp-ca-user-name mt-0 mb-0 cursordefault"
                              onClick={connectGoogle}
                            >
                              Connect Google Account
                            </h2>
                          </div>
                        </div>
                        <div className="pp-ca-remove">
                          {activeTab === "2" ? (
                            <img
                              src={require("./images/remove-icn.png")}
                              width="20"
                              height="20"
                              className="img-fluid"
                              onClick={() => {
                                DissconnectAccount("1", "FB");
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </li>
                  </>
                )}
              </>
            ) : (
              <>
                <li className={classnames({ active: activeTab === "2" })}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pp-ca-type d-flex align-items-center">
                      <img
                        src={require("./images/fb-icn-pp.png")}
                        width="35"
                        height="35"
                        className="img-fluid mr-3"
                      />
                      <div className="pp-ca-name d-flex align-items-center">
                        {activeTab === "2" ? (
                          <span className="pp-ca-tag-name  mb-2">
                            Connected as
                          </span>
                        ) : (
                          ""
                        )}
                        <h2
                          className="pp-ca-user-name mt-0 mb-0 cursordefault"
                          onClick={connectFacebook}
                        >
                          Connect Facebook Account
                        </h2>
                      </div>
                    </div>
                    <div className="pp-ca-remove">
                      {activeTab === "2" ? (
                        <img
                          src={require("./images/remove-icn.png")}
                          width="20"
                          height="20"
                          className="img-fluid"
                          onClick={() => {
                            DissconnectAccount("1", "FB");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </li>
                <li className={classnames({ active: activeTab === "2" })}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="pp-ca-type d-flex align-items-center">
                      <img
                        src={require("./images/gg-icn-pp.png")}
                        width="35"
                        height="35"
                        className="img-fluid mr-3"
                      />
                      <div className="pp-ca-name d-flex align-items-center">
                        {activeTab === "2" ? (
                          <span className="pp-ca-tag-name  mb-2">
                            Connected as
                          </span>
                        ) : (
                          ""
                        )}
                        <h2
                          className="pp-ca-user-name mt-0 mb-0 cursordefault"
                          onClick={connectGoogle}
                        >
                          Connect Google Account
                        </h2>
                      </div>
                    </div>
                    <div className="pp-ca-remove">
                      {activeTab === "2" ? (
                        <img
                          src={require("./images/remove-icn.png")}
                          width="20"
                          height="20"
                          className="img-fluid"
                          onClick={() => {
                            DissconnectAccount("1", "FB");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default ConnectAccounts;
