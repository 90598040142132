import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import "../login/css/index.scoped.css";
import { Button, FormGroup, Input } from "reactstrap";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Formik, Form, Field } from "formik";
import { otpValidation } from "../Validation schemas/otpValidations";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { put } from "../../Barriers/apiHelper";
import { fetchUserSuccess } from "../../redux/actions/loginActions";
import commands from "../../commands";

import {
  fetchSignUpClear,
  fetchSignUpSucces,
} from "../../redux/actions/signupActions";
import language from "../../language/language";
function SignupAccountConfirm(props) {
  const [otpError, setOtpError] = useState("");
  const dispatch = useDispatch();
  let userData = [];
  if (props.location.state) {
    userData = props.location.state.data;
  }

  // console.log(Object.values(state).length);
  // if (state.signUpData.data) {
  //   userData = state.signUpData.data.data.user;
  // }

  useEffect(() => {
    if (props.location.pathname === "/loginconfirm") {
      commands.user.resendConfirmationOtp(userData.email);
    }
  }, []);

  const verifyOTP = (values) => {
    console.log(values);
    const data = {
      email: userData.email,
      confirmation_otp: values.otp,
    };
    put(`/users/confirm_account`, data)
      .then(async (res) => {
        console.log(res);
        setOtpError("");
        await dispatch(fetchUserSuccess(res.data.data));
        var qparams = new URLSearchParams(window.location.search);
        setTimeout(() => window.location.replace(qparams.get("redirect") || "/"), 500);
        
      })
      .catch((err) => {
        console.log(err);
        setOtpError("Invalid OTP");
      });
  };
  useEffect(() => {
    console.log("buddy");
  }, [otpError]);
  const navigateSignUp = async () => {
    // await dispatch(fetchSignUpClear());
    props.history.push("/login");
  };

  return (
    <div>
      <a onClick={navigateSignUp} className="d-flex navigate">
        <RiArrowLeftSLine className="mr-2 yt-fp-back-icn" />
        <h3 className="yt-fp-top-back-tag-name">Sign Up</h3>
      </a>
      <h2 className="yt-fp-tag-line">
        {language.auth.headerText}
      </h2>
      <h2 className="yt-fp-tag-line-2">{language.auth.otp_sent_email}</h2>
      <div className="yt-fp-form">
        <Formik
          initialValues={{ otp: "" }}
          onSubmit={verifyOTP}
          validationSchema={otpValidation}
        >
          {({ errors, touched, values }) => {
            if (touched.otp && errors.otp) {
              setOtpError("");
            }
            return (
              <Form>
                <FormGroup className="">
                  {/* <img
                    alt="Password Icon"
                    src={require("./images/key-icn.png")}
                    className={
                      (errors.otp && touched.otp) || otpError
                        ? "yt-fp-icn2"
                        : "yt-fp-icn"
                    }
                  /> */}
                  <Field
                    name="otp"
                    type="text"
                    placeholder="OTP"
                    value={values.otp}
                    className={
                      "form-control" +
                      (errors.otp && touched.otp ? " is-invalid" : "")
                    }
                  />
                  <span
                    id="yt-signup-email-error"
                    className="yt-login-message-show"
                  />
                  {touched.otp && errors.otp && (
                    <span className="invalid-feedback">{errors.otp}</span>
                  )}
                  {otpError ? (
                    <span className="err_invalid">{otpError}</span>
                  ) : (
                    <></>
                  )}
                </FormGroup>

                <Button type="submit" color=" yt-fp-btn" block>
                  {language.auth.verifyOTP}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
export default withRouter(SignupAccountConfirm);
