import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { post, get } from "../../Barriers/apiHelper";
import NewPassword from "./NewPassword";
import language from "../../language/language";
function ForgotPassFields() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [notRegister, setNotRegister] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [password, setNewPassword] = useState("");
  const [passwordError, setNewPassError] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const history = useHistory()

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function isValidOTP(otp) {
    const re = /^\d{5}$/gm.test(otp);
    return re;
  }

  const SendOtpBtn = (e) => {
    e.preventDefault();
    let res;
    if (email == "") {
      setEmailError("empty");
      return;
    } else {
      let d = isValidEmail(email);
      if (!d) {
        setNotRegister("");
        setEmailError("wrong");
      } else {
        if (emailError != "success") {
          emailConfirm();
        }
      }
      if (otp == "") {
        setOtpError("empty");
      } else {
        let v = isValidOTP(otp);
        if (!v) {
          setOtpError("invalid");
        } else {
          setOtpError("");
          verifyOTP();
        }
      }
    }
  };


  const emailConfirm = () => {
    let data = {
      user: {
        email: email,
      },
    };
    post(`/users/password`, data)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          setEmailError("success");
        }
        // setEmailError("");
        setNotRegister("");
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response.data.error === "The Email you've entered is not registered with us.") {
          setEmailError("");
          setNotRegister(err.response.data.error);
        } else if (err.response.data.error === "Please confirm your account to reset your password.") {
          history.push({
            pathname: "/loginconfirm",
            state: {
              data: { email: email },
            },
          });
        } else {
          setEmailError("");
          setNotRegister(err.response.data.error);
        }

      });
  };

  useEffect(() => {
    if (emailError === "success" && timeLeft > 0) {
      setTimeout(() =>
        setTimeLeft(timeLeft - 1)
        , 1000)
    }
  }, [emailError, timeLeft]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 0) {
      setOtp("");
    }
    if (emailError != "") {
      let check = isValidEmail(e.target.value);
      if (check) {
        setEmailError("");
      } else {
        setEmailError("wrong");
      }
    }
  };

  const handleOTP = (e) => {
    setOtp(e.target.value);
    if (otpError != "" || e.target.value.length > 5) {
      let check = isValidOTP(e.target.value);
      // console.log(check, "check");
      if (check) {
        setOtpError("");
      } else {
        setOtpError("invalid");
      }
    }
  };

  const verifyOTP = () => {
    get(`/users/verify_otp?otp=${otp}`)
      .then((res) => {
        setOtpToken(res.data.token);
      })
      .catch((err) => {
        setOtpError("invalidResponse");
        // console.log(err.response.data);
      });
  };

  return (
    <div className="yt-fp-wrap">
      {
        otpToken == "" ?
          <a href="/login" className="d-flex">
            <RiArrowLeftSLine className="mr-2 yt-fp-back-icn" />
            {
              <h3 className="yt-fp-top-back-tag-name">
                {language.auth.forgotPassword}
              </h3>
            }
          </a> :
          <h3 className="yt-fp-top-back-tag-name">
            {language.auth.createPassword}
          </h3>
      }
      <h2 className="yt-fp-tag-line">
        {language.auth.headerText}
      </h2>
      <h2 className="yt-fp-tag-line-2">
        {otpToken == "" && (
          <span>
            {emailError !== "success" ? language.auth.enterEmailOrPhone : language.auth.otp_sent_email}
          </span>
        )}
      </h2>
      <div className="yt-fp-form">
        {otpToken == "" ? (
          <Form onSubmit={SendOtpBtn}>
            <FormGroup>
              {/* <img
                alt="Email Icon"
                src={require("./images/emal-icn.png")}
                className={
                  emailError == "wrong" ||
                    emailError == "empty" ||
                    notRegister != ""
                    ? "yt-fp-icn2"
                    : "yt-fp-icn"
                }
              /> */}
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={handleEmail}
                className={
                  emailError == "" || emailError == "success"
                    ? "normal-input"
                    : "invalid-input"
                }
              />
              <span id="yt-fp-email-error" className="yt-fp-up-message-show" />
              {emailError == "empty" && (
                <p className="invalid">{language.auth.emailError}</p>
              )}
              {emailError == "wrong" && (
                <p className="invalid">{language.auth.emailInvalid}</p>
              )}
              {notRegister && (
                <p className="invalid">{notRegister}</p>
              )}
            </FormGroup>
            {emailError == "success" && (
              <FormGroup className="">
                {/* <img
                  alt="Password Icon"
                  src={require("./images/key-icn.png")}
                  className={otpError != "" ? "yt-fp-icn2" : "yt-fp-icn"}
                /> */}
                <Input
                  type="text"
                  name="otp"
                  id="otp"
                  value={otp}
                  placeholder="OTP"
                  onChange={handleOTP}
                />
                {/* <FaEye
                  className={
                    otpError != "" ? "yt-pas-eye-icn2" : "yt-pas-eye-icn"
                  }
                /> */}
                {otpError == "empty" && (
                  <p className="invalid">{language.auth.enterOTP}</p>
                )}
                {otpError == "invalid" && (
                  <p className="invalid">{language.auth.enterValidOpt}</p>
                )}
                {otpError == "invalidResponse" && (
                  <p className="invalid">{language.auth.invalidOtp}</p>
                )}
              </FormGroup>
            )}
            <Button type="submit" color=" yt-fp-btn" block>
              {
                emailError == "success"
                  ? language.auth.verifyOTP
                  : language.auth.otpSend
              }
            </Button>
            {
              emailError === "success" && (
                <div className="otp-div-style">
                  {
                    timeLeft !== 0 ?
                      <div className="otp-timer">
                        {
                          timeLeft < 10 ? `00:0${timeLeft}` : `00:${timeLeft}`
                        }
                      </div> :
                      <Button onClick={() => { emailConfirm(), setTimeLeft(60) }} color="link yt-resent-otp-btn">Resend OTP</Button>
                  }
                </div>)
            }
          </Form>
        ) : (
          <NewPassword token={otpToken} />
        )}
      </div>
    </div>
  );
}
export default ForgotPassFields;

