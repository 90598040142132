import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import "./css/pagination.css";
import {
  Footer,
  ThreePromo,
  AllProducts,
  ProductsFilterBar,
  Header,
} from "../../components";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { BannerHomeTwo } from "../home2";
import { NavLink } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import ReactPaginate from "react-paginate";

function Products(props) {
  const [currentRecommend, setcurrentRecommend] = useState("");
  const [isRecommended, setIsRecommended] = useState(false);

  const qparams = new URLSearchParams(window.location.search);

  const [YT_Category, setYT_Category] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [TrpeRangeDefailt, setTrpeRangeDefailt] = useState({
    min: 0,
    max: 50000,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState("");
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [YT_Clear_ALl, setYT_Clear_ALl] = useState(true);
  const [YtMbFilter, setYtMbFilter] = useState(false);
  const [typeFor, setTypeFor] = useState("");
  const [removeFilter, setRemoveFilter] = useState(() => null);
  // const [orderBy, setOrderBy] = useState("");
  const [paginationData, setPaginationData] = useState(undefined);
  const [categoryId, setCategoryId] = useState();

  const ToggleValue = async (id, type, arrayData) => {
    setTypeFilter(type);
    if (type !== undefined) {
      if (type == "category") {
        setYT_Category(arrayData);
      } else if (type == "tags") {
        setTagData(arrayData);
      } else if (type == "brands") {
        setBrandsData(arrayData);
      }
    }
  };
  const ytmbFilter = () => {
    setYtMbFilter(!YtMbFilter);
  };

  useEffect(() => {
    var data = qparams.get("category_id[]");
    let isRecommended = qparams.get("isRecommended");
    setIsRecommended(isRecommended);
    setCategoryId(
      YT_Category &&
        YT_Category.filter((y) => {
          return y.id == data;
        }).map((x) => {
          console.log('MYCARDS', x);
          let myEle = {categoryName :'Our Fine Jewels',image:'Rings.jpg'};
          if(x.name == 'Rings'){
            myEle = {categoryName :x.name,image:'Rings.jpg'}
          }
          if(x.name == 'Earings'){
            myEle = {categoryName :x.name,image:'Earnings.jpg'}
          }
          if(x.name == 'Pendants'){
            myEle = {categoryName :x.name,image:'Pendants.jpg'}
          }
          if(x.name == 'Necklaces'){
            myEle = {categoryName :x.name,image:'Necklaces.jpg'}
          }
          if(x.name == 'Bangles and Bracelets'){
            myEle = {categoryName :x.name,image:'Bangles.jpg'}
          }
          return myEle;
          
        })
    );
  }, [props.location.state, YT_Category]);

  useEffect(() => {
    const typeQuery = qparams.get("type");
    if (typeQuery === "1") {
      setTypeFor("I'm Getting Engaged");
    } else if (typeQuery === "2") {
      setTypeFor("Celebrating a Milestone");
    } else if (typeQuery === "3") {
      setTypeFor("Children's Customized Gifting");
    } else if (typeQuery === "4") {
      setTypeFor("Wedding Jewelry");
    } else if (typeQuery === "5") {
      setTypeFor("For Him");
    } else if (typeQuery === "6") {
      setTypeFor("Matched Sets");
    } else if (typeQuery === "7") {
      setTypeFor("Everyday Wear");
    } else if (typeQuery === "8") {
      setTypeFor("Find Me My Solitaire");
    } else if (typeQuery === "9") {
      setTypeFor("For Her");
    } else if (typeQuery === "10") {
      setTypeFor("Valentines/ Love Gifts");
    } else {
      setTypeFor("Our Fine Jewels");
    }
  });
  console.log('MYPRODUCT', categoryId);
  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />

      <section >
        <div className="image-container">
          <img
            className="img-fluid myImage"
            src={categoryId&&categoryId[0] ? require(`./images/${categoryId[0]?.image}`) : require("./images/Earnings.jpg")}
          />
          <div className="after banner-content">
            <h3
              className="banner-title"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {(categoryId && categoryId[0]?.categoryName) || typeFor}
            </h3>
            <p
              className="banner-subtitle "
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              {/* SP - 10-04-21 Updated below code as per new updates */}
              We do custom {(categoryId && categoryId[0]?.categoryName) || typeFor} , made to
              your specification.
              {/* POOJA DUGGAL FINE JEWEL's uses standard US ring sizes. If you are
              not satisfied with your chosen size, send it back to us and we
              will size it for you free of charge (you only pay for shipping).
              *All eternity bands are final sale and cannot be resized. */}
            </p>
          </div>
        </div>
      </section>
      <section id="#scrollHere" >
        <Container>
          {/* <div className="pageroute">
            <NavLink to="/">Home</NavLink> {">"}{" "}
            <span className="currpage">Featured Collections</span>
          </div> */}
          <div className="filterpage">
            <Row className="yt-cm-row">
              <Col
                xs={12}
                sm={6}
                lg={3}
                // className={
                //   YtMbFilter ? "yt-cm-lt-col ytMbfilteropen" : "yt-cm-lt-col "
                // }
                className="d-none"
              >
                <ProductsFilterBar
                  onSelect={ToggleValue}
                  setRemoveFilter={setRemoveFilter}
                  orderBy={currentRecommend}
                  paginationData={paginationData}
                />
              </Col>
              <Col xs={12} sm={6} lg={12} className="yt-cm-rt-col">
                <div id="SingelnewProducts">
                  <AllProducts
                    category={YT_Category || []}
                    tags={tagData || []}
                    brands={brandsData || []}
                    type={typeFilter}
                    sortBy={currentRecommend}
                    page={paginationData?.current_page}
                    per_page={9}
                    paginationData={paginationData}
                    isRecommended={isRecommended}
                    setPaginationData={setPaginationData}
                  />
                </div>
                {!isRecommended &&
                  paginationData &&
                  (paginationData.next_page || paginationData.prev_page) && (
                    <div id="pagination">
                      <ReactPaginate
                        className="paginate-border"
                        previousLabel={"<<<"}
                        nextLabel={">>>"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={paginationData.total_pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={(val) => {
                          if ("URLSearchParams" in window) {
                            var searchParams = new URLSearchParams(
                              window.location.search
                            );
                            searchParams.set("page", val.selected + 1);
                            props.history.push(
                              "/shop?" + searchParams.toString()
                            );
                          }
                          let body = document.getElementById("#scrollHere");
                          body.scrollIntoView({
                            behavior: "smooth",
                          });
                          setPaginationData({
                            ...paginationData,
                            current_page: val.selected + 1,
                          });
                        }}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <ThreePromo />
      <BannerHomeTwo />
      <Footer />
    </div>
  );
}

export default Products;
