import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  Footer,
  Header,
  ThreePromo,
  ErrorOccuredBlock,
  PageLoadingBlock,
  ProductReview,
  FeatureBar,
} from "../../components";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "./css/index.scoped.css";
import { FaLongArrowAltLeft } from "react-icons/fa";
import service, { serviceTypes } from "../../services";
import { BannerHomeTwo } from "../home";

function TermsAndCondition(props) {
  const tabName = props.match.params.slug;
  const [activeTab, setActiveTab] = useState("1");
  const [activeMobileTab, setactiveMobileTab] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [helpCenterData, setHelpCenterData] = useState(undefined);

  useEffect(() => {
    getHelpceterData();
  }, []);

  function getHelpceterData() {
    const serviceType = serviceTypes.getHelpCenterData();
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/"),
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setHelpCenterData(undefined);
                getHelpceterData();
              },
            });
          }
        } else if (response) {
          if (typeof response.data.data.help_centers === "object") {
            setHelpCenterData(response?.data?.data?.help_centers);
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setHelpCenterData(undefined);
                getHelpceterData();
              },
            });
          }
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const history = useHistory();
  const routeToProfile = () => {
    let path = "/";
    history.push(path);
  };
  const routeHelpCenter = (value) => {
    if (value !== undefined) {
      let path = "/terms-and-conditions/" + value;
      history.push(path);
    } else {
      let path = "/terms-and-conditions";
      history.push(path);
    }
  };
  const routeHelpCenterMb = (value) => {
    if (value !== undefined) {
      let path = "/terms-and-conditions/" + value;
      history.push(path);
      setactiveMobileTab(!activeMobileTab);
    } else {
      let path = "/terms-and-conditions";
      history.push(path);
      setactiveMobileTab(!activeMobileTab);
    }
  };

  if (tabName !== undefined && helpCenterData !== undefined) {
    let matchTabName = "";
    helpCenterData.map((data, index) => {
      if (tabName === data.title) {
        matchTabName = index + 1;
      }
    });
    toggle(matchTabName);
  }

  if (tabName === undefined && helpCenterData !== undefined) {
    toggle(1);
  }

  return (
    <div>
      <Header onProps={props} />
      <Container
        className={activeMobileTab ? "yt-mobile-datapg" : "yt-desktop-datapg"}
      >
        <section className="mb-4 d-block hc-mb-30">
          <Row className="yt-cm-row">
            <Col xs={12} sm={5} lg={4} className="yt-cm-lt-col ">
              <div className="hc-beckfrom-ct-page d-flex align-items-center hc-mb-30">
                <FaLongArrowAltLeft
                  className="hcp-back-arrow"
                  onClick={() => {
                    routeToProfile();
                  }}
                />{" "}
                <span className="pl-2 hc-back-tag">Terms & Conditions</span>
              </div>
              {helpCenterData && (
                <div className="hc-inner-wrap bg-white radius-10 hc-mb-30">
                  <div className="hc-inner-contnet yt-desk-hc">
                    <ul className="p-0 m-0 list-style-none hc-tabs-name">
                      {helpCenterData.map((data, index) => (
                        <li
                          className={classnames({
                            active: activeTab === index + 1,
                          })}
                          onClick={() => {
                            routeHelpCenter(data.title);
                          }}
                          key={index}
                        >
                          {data.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="hc-inner-contnet yt-tab-movile-hc">
                    <ul className="p-0 m-0 list-style-none hc-tabs-name">
                      {helpCenterData.map((data, index) => (
                        <li
                          className={classnames({
                            active: activeTab === index + 1,
                          })}
                          onClick={() => {
                            routeHelpCenter(data.title);
                          }}
                          key={index}
                        >
                          {data.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </Col>
            <Col xs={12} sm={7} lg={8} className="yt-cm-rt-col ">
              {!helpCenterData && (
                <Fragment>
                  {!loading && (
                    <ErrorOccuredBlock
                      title={errorData?.title || "Oh Noes!, Error Occured"}
                      message={errorData?.message || "Unknown Error Occured."}
                      buttonText={errorData?.buttonText || "Go to Home"}
                      onButtonPress={
                        errorData?.onButtonPress ||
                        (() => props.history.push("/"))
                      }
                    />
                  )}
                  {loading && (
                    <PageLoadingBlock
                      title={"Please wait..."}
                      message={"Fetching faq data"}
                      buttonText={"Go to Home"}
                      onButtonPress={() => props.history.push("/")}
                    />
                  )}
                </Fragment>
              )}
              {helpCenterData && (
                <div className="hc-inner-wrap hc-tab-content bg-white radius-10 hc-mb-30">
                  <div className="hc-inner-content">
                    {helpCenterData.map((data, index) => (
                      <TabContent activeTab={activeTab} key={index}>
                        <TabPane tabId={index + 1}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.content.replace(/\n/g, "<br />"),
                            }}
                          />
                        </TabPane>
                      </TabContent>
                    ))}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </section>
      </Container>
      <section className="hc-product-promo hc-mt-30 hc-mb-30">
        <ThreePromo />
      </section>
      <BannerHomeTwo />
      <section className="my-4">
        <ProductReview />
      </section>
      <FeatureBar />
      <Footer />
    </div>
  );
}

export default TermsAndCondition;
