import getHomeProducts from "./get-home-products";
import getBanners from "./get-banners";
import getPositionedBanners from "./get-positioned-banners";
import getWebBanners from "./get-web-banners";
import getPositionedWebBanners from './get-positioned-web-banners';

export default {
  getHomeProducts,
  getBanners,
  getPositionedBanners,
  getPositionedWebBanners,
  getWebBanners
  
};
