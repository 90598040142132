import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Container } from "reactstrap";
import Carousel, { consts } from "react-elastic-carousel";
import { FavouriteProductSet } from "../../components";
import { FiChevronLeft, FiChevronRight, FiHeart } from "react-icons/fi";
import { useHistory } from "react-router";
import commands from "../../commands";
import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import { Button, Card, CardImg } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import ImageController from "../imagecontroller";
import AllModal from "../modal";
import config from "../../config";

const TitleBar = ({ name }) => {
  return (
    <div className="title-bar ">
      <h4 className=" text-uppercase">{name}</h4>
      <NavLink to="/shop">View all</NavLink>
    </div>
  );
};

const homeBreakPoints = [
  { width: 320, itemsToShow: 1 },
  { width: 400, itemsToShow: 2 },
  { width: 700, itemsToShow: 3 },
  { width: 850, itemsToShow: 4 },
  { width: 1150, itemsToShow: 5 },
];

const ProductCard = (props) => {
  const history = useHistory();
  const state = AuthState.get();

  const [products, setProducts] = useState(undefined);
  const [ShowWLModal, setShowWLModal] = useState(false);
  const [favProduct, setfavProduct] = useState();
  const openRemoveWhishListModal = (prod) => {
    setShowWLModal(!ShowWLModal);
    setfavProduct(prod);
  };
  const [ShowEnquireModal, setShowEnquireModal] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);
  const openEnquireModal = (product) => {
    setShowEnquireModal(!ShowEnquireModal),
    setEnquiryData(product);
  };
 
  useEffect(() => {
    if (props.products?.length > 0) {
      setProducts(props.products);
    }
  }, [props]);

  const productDetails = (a) => {
    history.push({ pathname: `/shop/${a.id}`, state: { a } });
  };

  async function proceedToEnquireForm(product){
    if (commands.user.isLoggedIn()) {
      console.log("Logged In")
      openEnquireModal(product)
    } else {
      history.push(`/login?redirect=${history.location.pathname}`);
    }
  }

  const addToWishlist = (product) => {
    const serviceType = serviceTypes.addToWishlist(state.user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product added to wishlist successfully",
            type: "success",
          },
        ]);
        commands.cache.getHomeProducts();
      }
    });
  };

  const removeFromWishlist = (product) => {
    // console.log("Removing from wishlist");
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product removed from wishlist successfully",
            type: "danger",
          },
        ]);
        commands.cache.getHomeProducts();
      }
    });
  };

  const carousalArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <FiChevronLeft size={25} color="#94979D" />
      ) : (
        <FiChevronRight size={25} color="#94979D" />
      );
    return (
      <Button
        className="carousal-arrow"
        variant="flat"
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </Button>
    );
  };

  function getProductList(data) {
    get(data)
      .then((res) => {
        // console.log(res);
        setProductList(res.data?.data);

        if (!_.isEqual(props.paginationData, res.data?.meta?.pagination)) {
          props.setPaginationData(res.data?.meta?.pagination);
        }
      })
      .catch((err) => {
        // console.log(err);
        // console.log("Status is ", typeof err.response?.status);
        if (err.response?.status === 404) {
          setProductList([]);
        }
        window.notify([
          {
            message: err.response?.data?.error || err.message,
            type: "success",
          },
        ]);
      })
      .finally(() => setShowSpinner(false));
  }
  return products && Array.isArray(products) && products.length > 0 ? (
    <section className="my-5">
      {ShowWLModal ? (
        <AllModal
          modalName="removewhishlist"
          onConfirm={() => removeFromWishlist(favProduct)}
        />
      ) : (
        ""
      )}
      {ShowEnquireModal ? (
        <AllModal modalName="enquireNow" mydata={enquiryData} />
      ) : (
        ""
      )}
      <Container>
        <TitleBar name={props.name} />
        <Carousel
          renderArrow={carousalArrow}
          itemsToShow={5}
          pagination={false}
          itemPadding={[15, 15]}
          breakPoints={homeBreakPoints}
        >
          {products.map(
            (product, index) => (
              <Card key={product.id} className="product-card">
                <Card className="product-card">
                  <ImageController
                    imageClick={() => productDetails(product)}
                    src={
                      product.images?.length > 0
                        ? product.images[0].image
                        : null
                    }
                  />
                  <div variant="flat" className="btn-heart">
                    <FavouriteProductSet
                      onDataId={index}
                      onPageType="shop"
                      isFav={product.is_wishlisted}
                      onClick={() =>
                        product.is_wishlisted
                          ? openRemoveWhishListModal(product)
                          : addToWishlist(product)
                      }
                    />
                  </div>
                  {/* {product.on_sale === true ? (
                <div className="discount-tag">
                  <span>
                    {Math.round(
                      ((product.price - product.sale_price) / product.price) * 100
                    )}
                    % Off
                  </span>
                </div>
              ) : (
                <></>
              )} */}
                  <Card.Body className="text-center p-0">
                    <div className="product-name" title={product.name}>
                      {product.name}
                    </div>
                    <p>
                      <Button
                        className="btn-add-to-cart"
                        variant="flat"
                        // onClick={() => productDetails(product)}
                        onClick={() => proceedToEnquireForm(product)}
                      >
                        Enquire Product
                      </Button>
                      {/* {product.on_sale === true ? (
                    <>
                      <span className="product-price-now">
                        {config().currency} {product.sale_price}
                      </span>
                      <span className="product-price-original">
                        {config().currency} {product.price}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="product-price-now">
                        {config().currency} {product.price}
                      </span>
                    </>
                  )} */}
                    </p>
                    {/* {product.stock_qty >= 1 &&
                    product.stock_qty - product.block_qty >= 1 &&
                    product.current_availability === "in_stock" ? (
                      <>
                        {product.product_variants.length >= 1 ? (
                          <Button
                            className="btn-add-to-cart"
                            variant="flat"
                            onClick={() => {
                              commands.cart.addItem({
                                productId: product.id,
                                variantId: product?.product_variants[0]?.id,
                                quantity: 1,
                                onSuccess: () => getProductList(),
                              });
                            }}
                          >
                            {product.is_in_cart
                              ? "Already in Cart"
                              : "Add to Cart "}
                          </Button>
                        ) : (
                          <Button
                            className="btn-add-to-cart"
                            variant="flat"
                            onClick={() => {
                              commands.cart.addItem({
                                productId: product.id,
                                quantity: 1,
                                onSuccess: () => getProductList(),
                              });
                            }}
                          >
                            {product.is_in_cart
                              ? "Already in Cart"
                              : "Add to Cart"}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        className="btn-add-to-cart"
                        variant="flat"
                        onClick={() => {
                          window.notify({
                            message: "Product is out of stock!",
                            type: "danger",
                          });
                        }}
                      >
                        Out Of Stock
                      </Button>
                    )} */}
                  </Card.Body>
                </Card>
              </Card>
            )

            // product.product_variants?.map((variant, idx) => (
            //   <Card key={product.id} className="product-card">
            //     <Card className="product-card">
            //       <ImageController
            //         imageClick={() => productDetails(product)}
            //         src={
            //           product.images?.length > 0
            //             ? product.images[0].image
            //             : null
            //         }
            //       />
            //       <div variant="flat" className="btn-heart">
            //         <FavouriteProductSet
            //           onDataId={index}
            //           onPageType="shop"
            //           isFav={product.is_wishlisted}
            //           onClick={() =>
            //             product.is_wishlisted
            //               ? openRemoveWhishListModal(product)
            //               : addToWishlist(product)
            //           }
            //         />
            //       </div>
            //       {variant.actual_price != variant.sale_price &&
            //       variant.sale_price > 0 ? (
            //         <div className="discount-tag">
            //           <span>
            //             -
            //             {Math.round(
            //               ((variant.actual_price - variant.sale_price) /
            //                 variant.actual_price) *
            //                 100
            //             )}
            //             %
            //           </span>
            //         </div>
            //       ) : (
            //         <></>
            //       )}
            //       <Card.Body className="text-center p-0">
            //         <div className="product-name" title={product.name}>
            //           {product.name}
            //         </div>
            //         <p>
            //           {variant.actual_price != variant.sale_price &&
            //           variant.sale_price > 0 ? (
            //             <>
            //               <span className="product-price-now">
            //                 {config().currency} {variant.sale_price}
            //               </span>
            //               <span className="product-price-original">
            //                 {config().currency} {variant.actual_price}
            //               </span>
            //             </>
            //           ) : (
            //             <span className="product-price-now">
            //               {config().currency} {product.price}
            //             </span>
            //           )}
            //         </p>
            //         {product.stock_qty >= 1 && product.stock_qty-product.block_qty >=1 &&
            //         product.current_availability === "in_stock" ? (
            //           <Button
            //             className="btn-add-to-cart"
            //             variant="flat"
            //             onClick={() => {
            //               commands.cart.addItem({
            //                 productId: product.id,
            //                 variantId: variant.id || "",
            //                 quantity: 1,
            //                 onSuccess: () => getProductList(),
            //               });
            //             }}
            //           >
            //             {product.is_in_cart ? "Already in Cart" : "Add to Cart"}
            //           </Button>
            //         ) : (
            //           <Button
            //             className="btn-add-to-cart"
            //             variant="flat"
            //             onClick={() => {
            //               window.notify({message:"Product is out of stock!",type:"danger"})
            //             }}
            //           >
            //             Out Of Stock
            //           </Button>
            //         )}
            //       </Card.Body>
            //     </Card>
            //   </Card>
            // ))
          )}
        </Carousel>
      </Container>
    </section>
  ) : null;
};

export default ProductCard;
