import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { AllModal } from "../../../components";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import service, { serviceTypes } from "../../../services";
import AuthState from "../../../redux/states/auth";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
function OrderDetailsPage() {
  const state = useLocation().state;
  const user = AuthState.get();
  const [ShowCOModal, settt] = useState(false);
  const [orderHistory, setOrders] = useState(undefined);
  const [orderAddress, setAddress] = useState(undefined);
  const [orderDetails, setOrderDetail] = useState(undefined);
  const [currentOrder, setCurrentOrder] = useState({});
  // const openCancelOrderModal = () => settt(!ShowCOModal);
  const openCancelOrderModal = (order, item) => {
    setCurrentOrder({ orderId: order?.id, itemId: item?.id });
    settt(!ShowCOModal);
  };
  const [ShowPRModal, pRsettt] = useState(false);
  const openProductRatingModal = () => pRsettt(!ShowPRModal);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: black;
  `;
  useEffect(() => {
    const serviceType = serviceTypes.refreshCart(
      user.user?.id,
      state.order?.id
    );
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log("ERROR", error);
      }

      console.log(response.data?.data.order, "RESPONSE");
      setOrders(response.data?.data.order.order_items[0].item_history);
      setAddress(response.data?.data.order?.delivery_addresses[0]);
      setOrderDetail(response.data?.data.order);
    });
  }, [state]);

  return (
    <Container>
      {ShowCOModal ? (
        <AllModal modalName="CancelOrder" order={currentOrder} />
      ) : (
        ""
      )}
      {ShowPRModal ? <AllModal modalName="Productrate" /> : ""}
      <Row>
        <Col md={12}>
          <div className="pageroute hc-breadcrumbs my-3">
            <NavLink to="/">
              <span className="hc-home">Home {">"}</span>
            </NavLink>
            <NavLink to="/profile">
              <span className="hc-mid"> Profile {">"}</span>{" "}
            </NavLink>
            <NavLink to="/profile/myorder">
              <span className="hc-mid"> My Orders {">"}</span>{" "}
            </NavLink>
            <span className="currpage hc-current">Order Details</span>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={12} lg={12} className="px-3 col-xxl-9">
          <div className="od-cm-col-pad">
            <div className="hc-beckfrom-ct-page d-flex align-items-center hc-mb-30">
              <NavLink to="/profile/myorder">
                <FaLongArrowAltLeft className="hcp-back-arrow" />{" "}
              </NavLink>
              <span className="pl-2 hc-back-tag">Order Details</span>
            </div>
            <div className="order-details-main-wrapper bg-white radius-10 mt-3 hc-mb-80">
              <div className="d-flex flex-wrap justify-content-between yt-sp-my-order-tdet-wrap">
                <div className="d-flex align-items-center flex-wrap sinlge-mo-dt-wrap ">
                  <div className="order-number-wrap">
                    <span className="order-tag">Order Number : </span>
                    <span className="order-tag-val">
                      {state.order.order_number}
                    </span>
                  </div>
                  <div className="order-bdr-between" />
                  <div className="order-date-wrap">
                    <span className="order-tag">Ordered on : </span>
                    <span className="order-tag-val">{state.order.order_date}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap sinlge-mo-dt-wrap ">
                  <div className="order-date-wrap">
                    <span
                      className=""
                      style={{ fontSize: "15px", fontWeight: "500" }}
                    >
                      {orderDetails?.status === "cancelled" ? (
                        <BounceLoader
                          css={override}
                          size={35}
                          color={"red"}
                          loading={true}
                        />
                      ) : (
                        <BounceLoader
                          css={override}
                          size={35}
                          color={"green"}
                          loading={true}
                        />
                      )}

                      {orderDetails?.status.toUpperCase()}
                    </span>
                  </div>
                </div>
                {/* {state.order.status === "delivered" ||
                state.order.status === "returned" ? (
                  <div className="order-review text-right">
                    <Button
                      color="link order-write-review px-0"
                      // onClick={openProductRatingModal}
                    >
                      Write a Review
                    </Button>
                  </div>
                ) : (
                  <></>
                )} */}
              </div>

              {state.order.order_items.map((product, index) => (
                <div className="py-3 d-flex align-items-center mb-3 od-single-prd-details-wrap" key={index}>
                  <div className="od-product-img">
                    <img
                      // src={require("./images/order-product-img.png")}
                      src={product.product_image}
                      className="img-fluid"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="order-product-info ">
                      <h2 className="pp-order-product-ttl my-0">
                        {product.product_name}
                      </h2>
                      {/* <h4 className="yt-pp-od-prdt-sub-ttl">
                      Women Gold-Plated Handcrafted Jewellery Set
                    </h4> */}
                      {product.product_variant
                        ?.product_variant_properties[0] ? (
                        <Table className="mb-0 order-prodict-type" borderless>
                          <tbody>
                            <tr>
                              <th>
                                {
                                  product.product_variant
                                    ?.product_variant_properties[0]
                                    .property_name
                                }
                              </th>
                            </tr>
                            <tr>
                              <td>
                                {
                                  product.product_variant
                                    ?.product_variant_properties[0].variant_name
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <></>
                      )}

                      <ul className="p-0 order-ul-list-none mb-0 mt-2 d-flex flex-wrap align-items-center">
                        <li className="op-order-product-price pr-4">
                          <span className="order-product-price">
                            ${product.total_price}
                          </span>
                        </li>
                        {/* <li>
                        <span className="order-tracing-details">
                          Tracking ID:{" "}
                          <span className="order-track-id">49879849491234</span>{" "}
                        </span>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div className="order-product-quanity text-center text-sm-right">
                      <ul className="p-0 order-ul-list-none m-0 ">
                        <li className="op-order-quantity mb-3">
                          Quantity :{" "}
                          <span className="ord-product-quantity">
                            {product.quantity}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
              <div style={{ paddingLeft: "123px" }} className="text-sm-right">
                <div>
                  <ul className="order-ul-list-none">
                    <li className="pp-order-product-ttl">
                      Sub Total :{" "}
                      <span className="op-order-product-price">
                        ${state.order.sub_total}
                      </span>
                    </li>
                  </ul>
                  <ul className="order-ul-list-none">
                    <li className="pp-order-product-ttl">
                      Total Tax :{" "}
                      <span className="op-order-product-price">
                        ${state.order.total_tax}
                      </span>
                    </li>
                  </ul>
                </div>
                <ul className="order-ul-list-none">
                  <li className="pp-order-product-ttl">
                    Total :{" "}
                    <span className="op-order-product-price">
                      ${state.order.total}
                    </span>
                  </li>
                </ul>
              </div>
              <Row>
                <Col md={12}>
                  <div className="order-details-status-bar py-3 my-3">
                    <h2 className="order-details-sub-title">Order Status</h2>

                    <ul className="pl-2 order-ul-list-none mb-0 ml-3 order-details-status-wrap">
                      {orderHistory?.map((order, index) => (
                        <li key={index}>
                          <img
                            alt="status check"
                            src={require("./images/statuss-check.png")}
                            className="order-details-status-icn"
                          />
                          <div className="order-step-1 order-st-otw">
                            <h4 className="d-flex align-items-center">
                              {order.status}{" "}
                              <span className="order-status-date">
                                {order.order_date}
                              </span>
                            </h4>
                            <p className="order-details-message">
                              {order.msg} {order.order_datetime}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="order-details-shipping-barmy-3">
                    <h2 className="order-details-sub-title">
                      Shipping Address
                    </h2>
                    <div className="order-shipping-address-wrap">
                      <h2 className="order-details-address-type">
                        {/* {state.order.delivery_addresses[0]?.address_type} */}
                        {orderAddress?.address_type}
                      </h2>
                      <p className="order-details-address-details">
                        {/* {state.order.delivery_addresses[0]?.flat_no}{" "}
                        {state.order.delivery_addresses[0]?.address},{" "}
                        {state.order.delivery_addresses[0]?.city} (
                        {state.order.delivery_addresses[0]?.state}){" "}
                        {state.order.delivery_addresses[0]?.country}{" "}
                        {state.order.delivery_addresses[0]?.zip_code} */}
                        {orderAddress?.flat_no} {orderAddress?.address},{" "}
                        {orderAddress?.city} ({orderAddress?.state}){" "}
                        {orderAddress?.country} {orderAddress?.zip_code}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="order-details-contact">
                      Phone Number:{" "}
                      <span className="order-details-phone">
                        {/* {state.order.delivery_addresses[0]?.phone_number} */}
                        {orderAddress?.phone_number}
                      </span>
                    </div>
                    {/* {state.order.status === "confirmed" ? (
                      <div className="order-cancel-wrap text-right">
                        <Button
                          color="link order-cancel-btn px-0"
                          
                        >
                          Cancel Order
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )} */}

                    {orderDetails?.status === "delivered" ? (
                      <></>
                    ) : (
                      <div className="order-cancel-wrap text-right">
                        {orderDetails?.status === "cancelled" ? (
                          <></>
                        ) : (
                          <Button
                            color="link order-cancel-btn px-0"
                            onClick={() => openCancelOrderModal(state.order)}
                            style={{ color: "blue" }}
                          >
                            Cancel Order
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderDetailsPage;
