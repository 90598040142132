import React, { useState, useEffect, Fragment } from "react";
import "./css/singleproductdetails.scoped.css";
import _ from "lodash";
import {
  Footer,
  ProductReview,
  Header,
  ProductImageWithSlider,
  ErrorOccuredBlock,
  PageLoadingBlock,
  FeatureBar,
} from "../../components";
import { Container, Row, Col } from "reactstrap";
import { AllModal, FavouriteProductSet } from "../../components";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import service, { serviceTypes } from "../../services";
import commands from "../../commands";
import { useHistory } from "react-router-dom";
import { BannerHomeTwo } from "../home";
import ScrollToTop from "react-scroll-to-top";
import Sticky from "react-sticky-el";
import parse from "html-react-parser";
import { BsChevronCompactRight } from "react-icons/bs";

function ProductInStock(props) {
  const [activeIdx, setActiveidx] = useState([]);

  const [ShowNMModal, setShowNMModal] = useState(false);
  const [ShowEnquireModal, setShowEnquireModal] = useState(false);
  const openEnquireModal = () => setShowEnquireModal(!ShowEnquireModal);
  const history = useHistory();

  async function proceedToEnquireForm() {
    if (commands.user.isLoggedIn()) {
      console.log("Logged In");
      openEnquireModal();
    } else {
      history.push(`/login?redirect=${history.location.pathname}`);
    }
  }

  return (
    <div className="right-inner-content-wrap">
      {ShowNMModal ? <AllModal modalName="notifyme" /> : ""}
      {ShowEnquireModal ? (
        <AllModal modalName="enquireNow" mydata={props.product} />
      ) : (
        ""
      )}
      <div
        className="sp-inner-wrap bg-white radius-10"
        style={{ paddingTop: "35px" }}
      >
        <div className="d-flex align-items-center justify-content-between yt-sp-title-wrapper">
          <h1
            className="product-title m-0 font-weight-bold"
            style={{ color: "black" }}
          >
            {props.product?.name}
          </h1>
          <div variant="flat" className="btn-heart">
            <FavouriteProductSet
              onDataId="1"
              onPageType="shop"
              isFav={props.product.is_wishlisted}
              onClick={props.product.toggleWishlist}
            />
          </div>
        </div>
        <div style={{ paddingTop: "12px" }}>
          <p>
            <span style={{ color: "#8b8f95" }}>
              {parse(props.product?.short_description || "")}
            </span>
          </p>
        </div>
        <div>
          <h2 className="product-title" style={{ fontSize: "20px" }}>
            Description
          </h2>
        </div>
        <div>
          <p>
            <span style={{ color: "#8b8f95", fontSize: "17px" }}>
              {parse(props.product.description || "")}
            </span>
          </p>
        </div>

        {props.product.product_attributes?.color?.length > 0 && (
          <div className="sp-price-wrap  sp-border-top-bottom yt-mb-space-border">
            <p className="m-0 sp-small-tag-name">COLOR</p>
            <ul className="p-0 mt-3 list-style-none d-flex align-items-center">
              {props.product.product_attributes.color.map((item, idx) => (
                <li className={idx === 0 ? "" : "mx-2"} key={idx}>
                  <div
                    className={`${item.active ? "active " : ""} sp-ccolor-box`}
                    style={{ backgroundColor: item.name }}
                    onClick={() => props.product.setActiveColor(item.name)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {props.product.product_attributes?.size?.length > 0 && (
          <div className="sp-size-wrap pb-0">
            <p className="m-0 sp-small-tag-name">SIZE</p>
            <ul
              className="mb-0 p-0 mt-3 list-style-none d-flex flex-wrap align-items-center justify-content-start"
              id="sp-size-data"
            >
              {props.product.product_attributes.size.map((item, idx) => (
                <li
                  className={`${
                    idx === 0 ? "ml-md-0" : ""
                  } mx-2 my-2 sp-size-col`}
                  key={idx}
                >
                  <div
                    className={`${
                      item.active ? "active" : ""
                    } sp-size-details p-2 text-center`}
                    data-item="XS"
                    onClick={() => props.product.setActiveSize(item.name)}
                  >
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {props.product?.product_variants.length > 0 && (
          <div className="sp-size-wrap pb-0">
            <p className="m-0 sp-small-tag-name" style={{ fontSize: "16px" }}>
              Variants
            </p>
            <ul
              className="mb-0 p-0 mt-3 list-style-none d-flex flex-wrap align-items-center justify-content-start"
              id="sp-size-data"
            >
              {props.product?.product_variants.map((item, idx) => (
                <li
                  className={`${
                    idx === 0 ? "ml-md-0" : ""
                  } mx-2 my-2 sp-size-col`}
                  key={idx}
                >
                  <div
                    className={`${
                      activeIdx?.id === item?.id ? "active" : ""
                    } sp-variant-details p-2 `}
                    data-item="XS"
                    onClick={() => {
                      activeIdx?.id === item?.id
                        ? setActiveidx([])
                        : setActiveidx(item);
                    }}
                  >
                    {item?.product_variant_properties.map((variantProp, i) => (
                      <p key={i}>
                        {variantProp.variant_name} : {variantProp.property_name}
                      </p>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center justify-content-md-end ">
        <ul className="p-0  mb-0 list-style-none d-flex align-items-center sp-product-add-action-wrap">
          {props.product.is_in_cart === true ? (
            <Fragment>
              <li className="mx-2">
                <button
                  type="button"
                  className="btn btn-light sp-addto-cart-btn py-3"
                  onClick={() =>
                    commands.cart.removeItem({
                      productId: props.product.id,
                      variantId: activeIdx?.id,
                    })
                  }
                >
                  Remove
                </button>
              </li>

              <li className="mx-2">
                <button
                  type="button"
                  className="btn btn-light sp-buy-now-btn py-3"
                  onClick={() => history.push("/cart")}
                >
                  Buy Now
                </button>
              </li>
            </Fragment>
          ) : (
            <Fragment>
              <li className="mx-2">
                <button
                  type="button"
                  className="btn btn-light sp-buy-now-btn py-3"
                  onClick={() => {
                    proceedToEnquireForm();
                  }}
                >
                  Enquire Product
                  <BsChevronCompactRight />
                </button>
              </li>
            </Fragment>
          )}
        </ul>
      </div>
    </div>
  );
}

function ProductDetails(props) {
  const [ProductDesc, setProductDesc] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const forceUpdate = () => setRefresh(!refresh);
  const [activeColor, setActiveColor] = useState("");
  const [activeSize, setActiveSize] = useState("");
  const [activeVariant, setActiveVariant] = useState("");
  const [whishlisted, setWhishlisted] = useState(false);
  const [notify, setNotify] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [currentImage, setCurrentImage] = useState("null");
  const [product, setProduct] = useState(undefined);
  const [reviewData, setReviewData] = useState({});
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scrolledToTop, setScrolledToTop] = useState(false);
  const dispatch = useDispatch();
  const loginData = useSelector((store) => store.logInReducer.loginData);
  const user = useSelector((store) => store.logInReducer.user || {});
  const cartState = useSelector((store) => store.cartReducer || {});
  const enableSticky = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    getProductDetails();
  }, [props]);

  useEffect(() => {
    if (!scrolledToTop) {
      const body = document.querySelector("#root");
      body.scrollIntoView(
        {
          behavior: "smooth",
        },
        500
      );
    }
    setScrolledToTop(false);
  }, []);

  if (currentImage === "null" && product !== undefined) {
    if (product.images.length) {
      setCurrentImage(product.images[0].image);
    }
  }

  function toggleWishlist() {
    if (!whishlisted) {
      addToWishlist();
    } else {
      removeFromWishlist();
    }
    setWhishlisted(!whishlisted);
  }

  function getProductDetails() {
    const serviceType = serviceTypes.getProductDetails(
      props.match?.params?.productId
    );
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
          if (error.status === 404) {
            setErrorData({
              title: "Oh Noes!, 404 Not Found",
              message: "The product you are looking for is not available!",
              buttonText: "Go to Home",
              onButtonPress: () => props.history.push("/"),
            });
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: error.message,
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setProduct(undefined);
                getProductDetails();
              },
            });
          }
        } else if (response) {
          if (typeof response.data.data.product === "object") {
            setProduct(response?.data?.data?.product);
            var reviewData = response?.data?.data?.logged_in_user_reviews;
            var orderItemId = response?.data?.data?.order_item_id;
            var orderId = response?.data?.data?.order_id;
            var reviewId =
              Array.isArray(reviewData) && reviewData.length > 0
                ? reviewData[reviewData.length - 1].id
                : undefined;
            if (orderItemId && orderId) {
              setReviewData({ orderItemId, orderId, reviewId });
            }
            setErrorData(null);
          } else {
            setErrorData({
              title: "Oh Noes!, Error Occured",
              message: "Unknown response received from server.",
              buttonText: "Retry",
              onButtonPress: () => {
                setLoading(true);
                setErrorData(null);
                setProduct(undefined);
                getProductDetails();
              },
            });
          }
        } else {
          console.log("OK");
        }
      })
      .finally(() => setTimeout(() => setLoading(false), 1000));
  }

  useEffect(() => {
    if (product === undefined) {
      getProductDetails();
    } else {
      try {
        setWhishlisted(product?.is_wishlisted);
        setNotify(product?.is_notify_product);
      } catch (err) {
        window.notify([{ message: "Error in Processing Data" }]);
      }
    }
  }, [product]);

  useEffect(() => {
    getProductDetails();
  }, [cartState.cart]);

  function subscribeForProductNotification() {
    const serviceType = serviceTypes.subscribeForProductNotification(
      product.id
    );

    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
        setNotify(!notify);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Subscribed for notifications successfully",
            type: "success",
          },
        ]);
        getProductDetails();
      }
    });
  }

  function addToWishlist() {
    const serviceType = serviceTypes.addToWishlist(user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
        setWhishlisted(false);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product added to wishlist successfully",
            type: "success",
          },
        ]);
        getProductDetails();
      }
    });
  }

  function removeFromWishlist() {
    const serviceType = serviceTypes.removeFromWishlist(user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
        setWhishlisted(false);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product removed from wishlist successfully",
            type: "success",
          },
        ]);
        getProductDetails();
      }
    });
  }

  const [ShowPRModal, setShowPRModal] = useState(false);
  const openProductRatingModal = () => setShowPRModal(!ShowPRModal);
  const styles = {
    item: {
      margin: "0 10px",
    },
  };
  const items = [
    "apple",
    "grape",
    "banana",
    "melon",
    "kiwi",
    "peach",
    "mango",
    "tomato",
    "pineapple",
    "blueberry",
    "avocado",
  ];
  const secondExample = {
    size: 20,
    count: 5,
    color: "#DBDBDB",
    activeColor: "#D4C96D",
    value: product?.average_rating || 0,
    a11y: true,
    isHalf: true,
    emptyIcon: <BsStarFill className="m-1" />,
    halfIcon: <BsStarHalf className="m-1" />,
    filledIcon: <BsStarFill className="m-1" />,
  };

  function getCurrentProduct() {
    if (!product) return product;
    var clone = _.cloneDeep(product);

    var variantIndex = undefined;

    if (activeColor === "" && activeSize === "") {
      clone.product_variants.forEach((vitem, vidx) => {
        if (vitem.is_master) {
          vitem.product_variant_properties.forEach((item, idx) => {
            if (item.variant_name === "color")
              setActiveColor(item.property_name);

            if (item.variant_name === "size") setActiveSize(item.property_name);

            if (item.variant_name === "V-1")
              setActiveVariant(item.property_name);
          });
        }
      });
    } else {
      clone.product_variants.forEach((vitem, vidx) => {
        var variantColor = undefined;
        var variantSize = undefined;
        var variantDefault = undefined;
        vitem.product_variant_properties.forEach((item, idx) => {
          if (item.variant_name === "color") {
            variantColor = item.property_name;
          }
          if (item.variant_name === "size") {
            variantSize = item.property_name;
          }
          if (item.variant_name === "V-1") {
            variantDefault = item.property_name;
          }
        });

        if (
          activeColor === variantColor &&
          activeSize === variantSize &&
          activeVariant === variantDefault
        ) {
          variantIndex = vidx;
        }
      });
    }

    if (variantIndex !== undefined) {
      clone = { ...clone, ...clone.product_variants[variantIndex] };
      clone.price = clone.actual_price;
      clone.variant_id = clone.id;
      clone.id = product.id;
    } else {
    }

    if (clone.product_attributes) {
      clone.product_attributes?.Color?.forEach(
        (item) => (item.active = item.name === activeColor ? true : false)
      );
      clone.product_attributes?.size?.forEach(
        (item) => (item.active = item.name === activeSize ? true : false)
      );
      clone.product_attributes?.V1?.forEach(
        (item) => (item.active = item.name === activeVariant ? true : false)
      );
    }
    clone.is_wishlisted = whishlisted;
    clone.is_notify_product = notify;
    clone.quantity = quantity;
    clone.setActiveColor = setActiveColor;
    clone.setActiveSize = setActiveSize;
    clone.setActiveVariant = setActiveVariant;
    clone.toggleWishlist = toggleWishlist;
    clone.setQuantity = setQuantity;

    return clone;
  }

  const currentProduct = getCurrentProduct();
  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      {product ? (
        <section>
          <Container>
            <AllModal
              modalName="Productrate"
              reviewData={reviewData}
              isOpen={ShowPRModal}
              toggle={() => setShowPRModal(!ShowPRModal)}
              onSuccess={getProductDetails}
            />
            <Row className="yt-cm-row">
              <Col xs={12} sm={6} lg={6} className="yt-cm-lt-col">
                <ProductImageWithSlider
                  images={currentProduct.images}
                  currentImage={currentImage}
                  setCurrentImage={setCurrentImage}
                />
              </Col>
              <Col xs={12} sm={6} lg={6} className="yt-cm-rt-col">
                <Sticky
                  boundaryElement={"#stickyboundary"}
                  disabled={enableSticky}
                  hideOnBoundaryHit={false}
                >
                  <ProductInStock
                    product={currentProduct}
                    subscribe={subscribeForProductNotification}
                  />
                </Sticky>
              </Col>
            </Row>
            <div id="stickyboundary" />
          </Container>
        </section>
      ) : (
        <Fragment>
          {!loading && (
            <ErrorOccuredBlock
              title={errorData?.title || "Oh Noes!, Error Occured"}
              message={errorData?.message || "Unknown Error Occured."}
              buttonText={errorData?.buttonText || "Go to Home"}
              onButtonPress={
                errorData?.onButtonPress || (() => props.history.push("/"))
              }
            />
          )}
          {loading && (
            <PageLoadingBlock
              title={"Please wait..."}
              message={"The product you are looking for is loading!"}
              buttonText={"Go to Home"}
              onButtonPress={() => props.history.push("/")}
            />
          )}
        </Fragment>
      )}
      <BannerHomeTwo />
      <section className="my-4">
        <ProductReview />
      </section>
      <FeatureBar />
      <Footer />
    </div>
  );
}

export default ProductDetails;
