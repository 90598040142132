import React, { useState } from "react";
import classnames from "classnames";
import "./css/index.scoped.css";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import SignUpPage from "./SignUp";
import LoginPage from "./Login";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

const LoginComponent = () => {
  const [activeTab, setActiveTab] = useState("2");
  const authState = useSelector((state) => state.logInReducer);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container className="login-wrapper" >
      <Nav>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            SIGN UP
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            SIGN IN
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <SignUpPage />
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="2">
          <LoginPage />
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default LoginComponent;
