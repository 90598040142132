import React from "react";
import CancelOrder from "./cancelorder";
import ProductRating from "./productrating";
import AddaddressModal from "./addaddress";
import EnquirenowModal from './EnquireNowProduct';
import EditaddressModal from "./editaddress";
import UserLogout from "./logout";
import DisconnectAccount from "./disconnectaccount";
import EditProfileModal from "./editprofile";
import UserNotifyMe from "./notifyme";
import SelectAddressModal from "./selectaddress";
import UserDeleteAddress from "./deleteaddress";
import RemoveWhishList from "./removewhishlist";
import DefaultAddress from "./DefaultAddress";
import ChangePassword from "./ChangePassword";
import RemoveFromCart from "./RemoveFromCart";
import MessageSent from "./MessageSent";

function AllModal(props) {
  const ModalType = props.modalName;
  if (ModalType != undefined) {
    switch (ModalType) {
      case "CancelOrder":
        return <CancelOrder  {...props}  />;
      case "Productrate":
        return <ProductRating {...props}/>;
      case "Addaddress":
        return <AddaddressModal setAddress={props.setAddress} {...props}/>;
      case "editaddress":
        return <EditaddressModal setAddress={props.setAddress} userData={props.userData} record={props.currentRecord}/>;
      case "logout":
        return <UserLogout />;
      case "disconnect":
        return <DisconnectAccount {...props}/>;
      case "editprofile":
        return <EditProfileModal userData={props.userData}/>;
      case "notifyme":
        return <UserNotifyMe />;
      case "selectaddress":
        return <SelectAddressModal {...props}/>;
      case "deleteaddress":
        return <UserDeleteAddress deletedRecords={props.deletedRecords} setAddress={props.setAddress} />;
      case "removewhishlist":
        return <RemoveWhishList {...props} />;
      case "removefromcart":
        return <RemoveFromCart {...props} />;
      case "DefaultAddress":
        return <DefaultAddress />
      case "changePassword":
        return <ChangePassword />;
      case "messagesent":
        return <MessageSent {...props} />;
      case "enquireNow":
        return <EnquirenowModal {...props}/>;
      default:
        return <h1>No project match</h1>;
    }
  } else {
    return <div>Please Select Modal Type</div>;
  }
}
export default AllModal;
