import React from "react";
import "./css/index.scoped.css";
import {
  Footer,
  Header,
  OrderDetailsPage,
  ProductReview,
  FeatureBar,
} from "../../components";
import { BannerHomeTwo } from "../home";

function OrderDetailPage(props) {

  return (
    <div>
      <Header onProps={props} />
      <OrderDetailsPage />
      <BannerHomeTwo />
      <section className="my-4">
        <ProductReview />
      </section>
      <FeatureBar />
      <Footer />
    </div>
  );
}
export default OrderDetailPage;
