import React, { useRef, useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button } from "reactstrap";
import { FaHeart } from "react-icons/fa";
import { AllModal } from "../../../components";
import { useHistory } from "react-router";
import AuthState from "../../../redux/states/auth";
import commands from "../../../commands";
import service, { serviceTypes } from "../../../services";
import { RiErrorWarningLine } from "react-icons/ri";

function WhishListProductComponent(props) {
  const state = AuthState.get();
  const [ShowWLModal, setShowWLModal] = useState(false);
  const [favProduct, setfavProduct] = useState();
  const history = useHistory();
  const openRemoveWhishListModal = (prod) => {
    setShowWLModal(!ShowWLModal);
    setfavProduct(prod);
  };

  const productDetails = (a) => {
    history.push({ pathname: `/shop/${a.id}`, state: { a } });
  };

  const removeFromWishlist = (product) => {
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product removed from wishlist successfully",
            type: "success",
          },
        ]);
        props.getWishlist();
      }
    });
  };

  return (
    <>
      {props.products.length > 0 ? (
        <div className="profile-pg-whish-lt-inner-wrap profile-pg-inner-wrap bg-white radius-10 profile-pg-mb-30 profile-p-20">
          <div className="profile-pg-inner-wrapper">
            <div className="profile-tab-content">
              <div className="profile-pg-wl-allproduct-main-wrap">
                {ShowWLModal ? (
                  <AllModal
                    modalName="removewhishlist"
                    onConfirm={() => removeFromWishlist(favProduct)}
                  />
                ) : (
                  ""
                )}
                <Row className="profile-pg-wl-cm-row-margin">
                  {props.products.map(
                    (value, index) => (
                      // value.product_variants.map((variant, idx) => (
                      <Col
                        md={6}
                        lg={4}
                        className="px-3 col-xxl-4 yt-cm-wl-col"
                        key={index}
                      >
                        <div className="product profile-pg-wl-sgl-product-cpnt text-center mb-4">
                          <div className="d-flex justify-content-between align-items-center yt-sp-top-fav-act">
                            {/* {value.on_sale ? (
                              <div className="profile-yt-sgl-product-off text-center p-1">
                                Sale
                              </div>
                            ) : (
                              <div className="text-center p-1" />
                            )} */}
                            <div
                              className="text-right p-2 rounded-circle bg-white yt-fav-circle-wrap"
                              onClick={() => openRemoveWhishListModal(value)}
                            >
                              <FaHeart className="profile-pg-wl-sglproduct-fav" />
                            </div>
                          </div>
                          <div
                            className="yt-product-bg-image w3-ripple"
                            onClick={() => productDetails(value)}
                            style={{
                              backgroundImage: `url(${value.images[0].image})`,
                              cursor: "default",
                            }}
                          />
                          <div className="mt-3 profile-pg-wl-sgl-product-title text-center">
                            {value.name}
                          </div>
                          {/* <div className="price profile-pg-wl-sgl-price-wrap text-center ">
                            <span className="price1 profile-pg-wl-sgl-product-sale-price">
                              ${value.on_sale ? value.sale_price : value.price}
                            </span>
                            <span className="price2 profile-pg-wl-sgl-product-reg-price2">
                              {value.on_sale && <del>{value.price}</del>}
                            </span>
                          </div> */}
                          {/* {value.stock_qty >= 1 &&
                          value.stock_qty - value.block_qty >= 1 &&
                          value.current_availability === "in_stock" ? (
                            <Button
                              className="econdary profile-pg-wl-sgl-product-add-btn buttoncart py-3"
                              onClick={() => {
                                commands.cart.addItem({
                                  productId: value.id,
                                  variantId: value.product_variants[0].id || "",
                                  quantity: 1,
                                  onSuccess: () => window.location.reload(),
                                });
                              }}
                            >
                              {value.is_in_cart
                                ? "Already in Cart"
                                : "Add to Cart"}
                            </Button>
                          ) : (
                            <Button
                              className="econdary profile-pg-wl-sgl-product-add-btn buttoncart py-3"
                              onClick={() => {
                                window.notify({
                                  message: "Product is out of stock!",
                                  type: "danger",
                                });
                              }}
                            >
                              Out Of Stock
                            </Button>
                          )} */}
                          <Button
                            className="econdary profile-pg-wl-sgl-product-add-btn buttoncart py-3"
                            onClick={() => productDetails(value)}
                          >
                            View Product
                          </Button>
                        </div>
                      </Col>
                    )
                    // ))
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile-pg-inner-wrap profile-pg-inner-no-add bg-white radius-10 profile-pg-mb-30">
          <div className="profile-pg-sa-no-address-main-wrap text-center">
            <img src={require("./images/noWishlist.png")} className="img-fluid mb-5" />
            <div className="pp-sa-no-add-wrap mt-2 mb-5">
              <h2 className="pp-na-ttl mt-0 mb-3">No Wishlist</h2>
              <p className="pp-na-text mb-0">
                You haven't wishlisted any Products, Browse Products  and wishlist them
              </p>
            </div>
            <Button
              color=" pp-no-addr-btn py-3"
              onClick={()=>history.push("/shop?page=1&per_page=9")}
            >
              Browse Products
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
function UserWhishList(props) {
  return (
    <>
      <WhishListProductComponent products={props.products} {...props} />
    </>
  );
}
export default UserWhishList;
