import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./css/index.scoped.css";
import { useSelector } from "react-redux";
import { AllModal } from "../../components";
import {
  UserWhishList,
  MyOrderPage,
  MyEnquiryPage,
  SavedAddress,
  ConnectAccounts,
} from "../../components";
import { Container, Row, Col, TabContent, TabPane, Button } from "reactstrap";
import classnames from "classnames";
import "./css/index.scoped.css";
import { FaCamera } from "react-icons/fa";
import ProfilePics from "./images/user-pics.png";
import ProfileBreadcrumbs from "./ProfileBreadcrumbs";
import ProfileContent from "./ProfileContent";
import service, { serviceTypes } from "../../services";
import { useMediaQuery } from "react-responsive";
import Sticky from "react-sticky-el";
// function ProfileContennt() {
//   const [ShowEditProfileModal, EditProfilefunc] = useState(false);
//   const openEditProfileModal = () => EditProfilefunc(!ShowEditProfileModal);
//   const [isProfilePics, setisProfilePics] = useState(false);
//   const history = useHistory();
//   const routeToChangePassword = () => {
//     let path = "/profile/changepassword";
//     history.push(path);
//   };
//   return (
//     <div className="profile-pg-inner-wrap p-4 bg-white radius-10 profile-pg-mb-30">
//       {ShowEditProfileModal ? <AllModal modalName="editprofile" /> : ""}
//       <div className="profile-pg-inner-wrapper">
//         <div className="profile-tab-content">
//           <Row>
//             <Col md={12}>
//               <div className="d-flex align-items-center mb-5">
//                 {isProfilePics ? (
//                   <div className="img-upload d-flex align-items-center justify-content-center">
//                     <img
//                       alt="Profile Pics"
//                       src={ProfilePics}
//                       className="img-fluid"
//                     />
//                   </div>
//                 ) : (
//                   <div className="img-upload d-flex align-items-center justify-content-center">
//                     <FaCamera />
//                   </div>
//                 )}
//               </div>
//             </Col>
//             <Col md={6}>
//               <div className="profile-data-wrap">
//                 <span className="profile-data-tag">Name</span>
//                 <p className="profile-user-name py-2">Sahil</p>
//               </div>
//             </Col>
//             <Col md={6}>
//               <div className="profile-data-wrap">
//                 <span className="profile-data-tag">Email</span>
//                 <p className="profile-user-name py-2">sahilhost0h@gmail.com</p>
//               </div>
//             </Col>
//             <Col md={6}>
//               <div className="profile-data-wrap">
//                 <span className="profile-data-tag">Phone No</span>
//                 <p className="profile-user-name py-2">+112910348291</p>
//               </div>
//             </Col>
//             <Col md={12}>
//               <div className="d-flex align-items-center justify-content-end">
//                 <Button
//                   color="link profile-edit-pass mr-2"
//                   onClick={routeToChangePassword}
//                 >
//                   Change Password
//                 </Button>
//                 <Button
//                   color="secondary profile-edit-btn"
//                   onClick={openEditProfileModal}
//                 >
//                   Edit Profile
//                 </Button>
//               </div>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </div>
//   );
// }
function ContentSidebarTitle(activeTab) {
  const tabName = activeTab.tabnmae;
  if (tabName !== undefined) {
    switch (tabName) {
      case "1":
        return "Profile";
      case "2":
        return "Wishlist";
      case "3":
        return "My Orders";
      case "4":
        return "Saved Addresses";
      case "5":
        return "Connected Accounts";
      default:
        return "My Enquiries";
    }
  }
  return <></>;
}

function ProfileComponent(props) {
  const state = useSelector((state) => state.logInReducer.user);
  const [customerDetail, setCustomerDetail] = useState([]);
  const [userData, setUserData] = useState([]);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const enableSticky = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      setEmail(state.email);
      setUserName(state.name);
      setProfileImage(state.profile_picture);
    }
    const serviceType = serviceTypes.getCustomerdetail(state.id);
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log(error, "321");
      }
      setCustomerDetail(response?.data?.data);
    });
  }, []);

  const urlTabName = props.onProps.match.params.slug;
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const history = useHistory();
  const routeToHelpCenter = () => {
    let path = "/help-center";
    history.push(path);
  };
  const [ShowLogoutModal, Logoutfunc] = useState(false);
  const openLogoutModal = () => Logoutfunc(!ShowLogoutModal);
  const [isProfilePics, setisProfilePics] = useState(false);

  if (urlTabName !== undefined) {
    let matchTabName = "";
    switch (urlTabName) {
      case "profile":
        matchTabName = "1";
        break;
      case "wishlist":
        matchTabName = "2";
        break;
      case "myorder":
        matchTabName = "3";
        break;
      case "saveaddresses":
        matchTabName = "4";
        break;
      case "connectaccount":
        matchTabName = "5";
        break;
      case "myenquiry":
        matchTabName = "8";
        break;
      default:
        matchTabName = "1";
        break;
    }
    toggle(matchTabName);
  } else {
    toggle("1");
  }
  const routeToProfile = (value) => {
    if (value !== undefined) {
      let path = "/profile/" + value;
      history.push(path);
    } else {
      let path = "/profile";
      history.push(path);
    }
  };
  return (
    <Container>
      <Row>
        <Col md={12}>
          <ProfileBreadcrumbs onProfile={props} />
        </Col>
      </Row>
      <section className="mb-4 d-block profile-pg-mb-30">
        {ShowLogoutModal ? <AllModal modalName="logout" /> : ""}
        <Row className="yt-cm-row flex-wrap">
          <Col className="yt-cm-lt-col">
            <Sticky
              boundaryElement={"#stickyboundary"}
              disabled={enableSticky}
              hideOnBoundaryHit={false}
            >
              <h2 className="yt-profile-mb-ttl profile-pg-title mb-4 mt-0">
                <ContentSidebarTitle tabnmae={activeTab} />
              </h2>
              <div className="profile-pg-inner-wrap profile-inner-tab-wrap p-40 bg-white radius-10 profile-pg-mb-30">
                <div className="profile-pg-inner-contnet">
                  <ul className="p-0 m-0 list-style-none profile-pg-tabs-name pg-profile-box">
                    <li
                      className={classnames({
                        "pt-0 active": activeTab === "1",
                        "pt-0": activeTab !== "1",
                      })}
                      onClick={() => {
                        routeToProfile();
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {customerDetail?.profile_picture ? (
                          <div className="img-upload d-flex align-items-center justify-content-center">
                            <img
                              alt="Profile Pics"
                              // src={profileImage}
                              src={customerDetail?.profile_picture}
                              className="img-fluid"
                            />
                          </div>
                        ) : (
                          <div className="img-upload d-flex align-items-center justify-content-center">
                            <FaCamera />
                          </div>
                        )}

                        <div className="user-profileinfo ml-3">
                          <h3 className="profile-name mt-0">
                            {customerDetail?.name}
                          </h3>
                          <h5 className="profile-email mb-0">
                            {customerDetail?.email}
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="profile-pg-inner-wrap profile-inner-tab-content p-40 bg-white radius-10 profile-pg-mb-30">
                <div className="profile-pg-inner-contnet">
                  <ul className="p-0 m-0 list-style-none profile-pg-tabs-name">
                    <li
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        routeToProfile("wishlist");
                      }}
                    >
                      <div className="profile-item-wrap d-flex align-items-center">
                        <img
                          alt="Whish List"
                          I
                          src={require("./images/whish-list-icn.png")}
                          width="65"
                          height="65"
                          className="profile-item-icn img-fluid mr-4"
                        />
                        <span className="profile-item-name">Wishlist</span>
                        {props.wishlist?.length > 0 && (
                          <span className="profile-notiy">
                            {props.wishlist.length}
                          </span>
                        )}
                      </div>
                    </li>
                    {/* <li
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      routeToProfile("myorder");
                    }}
                  >
                    <div className="profile-item-wrap d-flex align-items-center">
                      <img
                        alt="My Order"
                        src={require("./images/my-order-icn.png")}
                        width="65"
                        height="65"
                        className="profile-item-icn img-fluid mr-4"
                      />
                      <span className="profile-item-name">My Orders</span>
                    </div>
                  </li> */}
                    <li
                      className={classnames({ active: activeTab === "8" })}
                      onClick={() => {
                        routeToProfile("myenquiry");
                      }}
                    >
                      <div className="profile-item-wrap d-flex align-items-center">
                        <img
                          alt="Address"
                          src={require("./images/Inquiry.png")}
                          width="65"
                          height="65"
                          className="profile-item-icn img-fluid mr-4"
                        />
                        <span className="profile-item-name">My Enquiries</span>
                      </div>
                    </li>
                    <li
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        routeToProfile("saveaddresses");
                      }}
                    >
                      <div className="profile-item-wrap d-flex align-items-center">
                        <img
                          alt="Address"
                          src={require("./images/address-icn.png")}
                          width="65"
                          height="65"
                          className="profile-item-icn img-fluid mr-4"
                        />
                        <span className="profile-item-name">
                          Saved Addresses
                        </span>
                      </div>
                    </li>
                    {/* <li
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      routeToProfile("connectaccount");
                    }}
                  >
                    <div className="profile-item-wrap d-flex align-items-center">
                      <img
                        alt="Connect"
                        src={require("./images/connected-icn.png")}
                        width="65"
                        height="65"
                        className="profile-item-icn img-fluid mr-4"
                      />
                      <span className="profile-item-name">
                        Connected Accounts
                      </span>
                    </div>
                  </li> */}
                    <li
                      className={classnames({ active: activeTab === "6" })}
                      onClick={() => {
                        routeToHelpCenter();
                      }}
                    >
                      <div className="profile-item-wrap d-flex align-items-center">
                        <img
                          alt="Help Center"
                          src={require("./images/help-icn.png")}
                          width="65"
                          height="65"
                          className="profile-item-icn img-fluid mr-4"
                        />

                        <span className="profile-item-name">Help Center</span>
                      </div>
                    </li>
                    <li
                      className={classnames({ active: activeTab === "7" })}
                      onClick={openLogoutModal}
                    >
                      <div className="profile-item-wrap d-flex align-items-center">
                        <img
                          alt="Logout"
                          src={require("./images/logout-icn.png")}
                          width="65"
                          height="65"
                          className="profile-item-icn img-fluid mr-4"
                        />
                        <span className="profile-item-name">Logout</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Sticky>
          </Col>
          <div id="stickyboundary" />
          <Col className="yt-cm-rt-col">
            <h2 className="profile-pg-title mb-4 mt-0">
              <ContentSidebarTitle tabnmae={activeTab} />
            </h2>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ProfileContent userData={customerDetail} />
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                <UserWhishList products={props.wishlist} {...props} />
              </TabPane>
            </TabContent>
            {/* <TabContent activeTab={activeTab}>
              <TabPane tabId="3">
                <MyOrderPage orders={props.order} user={customerDetail} {...props} />
              </TabPane>
            </TabContent> */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="4">
                <SavedAddress activeTab={activeTab} {...props} />
              </TabPane>
            </TabContent>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="8">
                <MyEnquiryPage
                  enquiry={props.enquiries}
                  user={customerDetail}
                  {...props}
                />
              </TabPane>
            </TabContent>
            {/* <TabContent activeTab={activeTab}>
              <TabPane tabId="5">
                <ConnectAccounts activeTab={activeTab}/>
              </TabPane>
            </TabContent> */}
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default ProfileComponent;
