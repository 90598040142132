import React, { useState } from "react";
import "./css/index.scoped.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import SignupAccountConfirm from "./SignupAccountConfirm";
import { useHistory } from "react-router";

function SignUpConfirmComponent() {
  const history = useHistory();
  return (
    <Container className="login-wrapper">
        <SignupAccountConfirm />
    </Container>
  );
}
export default SignUpConfirmComponent;
