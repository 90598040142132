import React, { useEffect, useState, Fragment } from "react";
import "./css/index.scoped.css";
import { CartQuantityField } from "../../components";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { AllModal, LoginComponent } from "../../components";
import { useHistory } from "react-router-dom";
import commands from "../../commands";
import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";
import Ripple from "react-ripples";
import * as validationSchemas from "../../validationSchemas";
import { Formik } from "formik";
import { addAddressToCart } from "../../services/serviceTypes";
import Alert from "reactstrap/lib/Alert";
import language from "../../language/language";
import { useDispatch } from "react-redux";
import { clearCartOrders } from "../../redux/actions/cartActions";
import getConfig from "../../config";
import { NavLink } from "react-router-dom";
import UserState from "../../redux/states/user";
import Blink from "react-blink-text";

const config = getConfig();

function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52", marginBottom: "10px" }}>{error}</div>
  ) : null;
}
function EmptyCartContent() {
  const history = useHistory();
  const routeToshop = () => {
    let path = "/shop";
    history.push(path);
  };
  return (
    <>
      <CartBreadCrumbs cartTitle={true} />

      <section >
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="cart-page-main-title mt-0 empty-main-ttl">Cart</h1>
            </Col>
          </Row>
          <div className="yt-empty-cart-wrap cart-pg-inner-wrap p-4 bg-white radius-10 cart-pg-mb-30 mt-5">
            <div className="cart-pg-empty-main-wrap text-center py-5">
              <img
                src={require("./images/cart-empty-icn.png")}
                className="img-fluid"
                width="170"
                height="212"
              />
              <div className="cartno-wrap">
                <h2 className="empty-cartn-ttl mt-0">Your cart is empty !</h2>
                <p className="empty-cart-text mb-0">
                  You haven’t added any items in your cart yet !
                </p>
              </div>
              <Ripple>
                <Button
                  color="secondary empty-cart-btn py-3 px-5"
                  onClick={routeToshop}
                >
                  Browse Products
                </Button>
              </Ripple>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
function CartBreadCrumbs(props) {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="pageroute cart-pg-breadcrumbs my-3">
            <NavLink to="/">
              <span className="cart-pg-home">Home {">"}</span>
            </NavLink>{" "}
            <span className={props.cartTitle ? "cart-pg-current" : "cart-pg-home"}>
              Cart
            </span>
            {!props.cartTitle && (
              <span className="cart-pg-current"> {">"} Secure Checkout</span>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function CartCheckoutform(props) {
  const [ShowSelectAddressModal, setShowSelectAddressModal] = useState(false);
  const [
    ShowShippingSelectAddressModal,
    setShowShippingSelectAddressModal,
  ] = useState(false);
  const [addressId, setAddressid] = useState(null);

  const onContinueAddressModal = ({ type, address, setFieldValue, values }) => {
    setAddressid(address.id);
    const addressToFill = commands.cart.convertAddress({
      type,
      extaddress: address,
    });

    if (typeof addressToFill === "object") {
      // Object.keys(addressToFill).forEach((key, idx) => setFieldValue(key, addressToFill[key]));
      setFieldValue({ ...values, ...addressToFill });
    }
    setShowSelectAddressModal(false);
    setShowShippingSelectAddressModal(false);
  };

  const [ifUserLoggedIn, setifUserLoggedIn] = useState("");

  const [isShippingAddressSame, setIsShippingAddressSame] = useState(true);
  const [saveBillingAddress, setSaveBillingAddress] = useState(false);
  const [saveShippingAddress, setSaveShippingAddress] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [values, setValues] = useState(undefined);
  const paymentModes = {
    cashondelivery: "cashondelivery",
    debitcard: "debitcard",
  };
  const [paymentMode, setPaymentMode] = useState(paymentModes.cashondelivery);
  const [hyperPayId, setHyperPayId] = useState(undefined);
  const [iFrameHeight, setiFrameHeight] = useState("300px");
  const [initialAddress, setInitialAddress] = useState(undefined);
  const history = useHistory();
  const cartState = CartState.get();
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (values !== undefined) {
      addAddressToCart(values);
    }
  }, [values]);

  useEffect(() => {
    if (initialAddress === undefined) {
      setInitialAddress(commands.cart.getCurrentAddress());
    }
  }, []);

  async function addAddressToCart(values) {
    try {
      var billingAddress = {};
      var shippingAddress = {};
      var finalAddress = {};

      billingAddress = {
        name: values.bname,
        flat_no: values.bhouseNumber,
        address: values.baddressLine1,
        address_line_2: values.baddressLine2,
        city: values.bcity,
        state: values.bstate,
        country: values.bcountry,
        zip_code: values.bpincode,
        phone_number: values.bphone,
        is_default: false,
        save_address: values.saveBillingAddress,
      };

      shippingAddress = {
        name: values.sname,
        flat_no: values.shouseNumber,
        address: values.saddressLine1,
        address_line_2: values.saddressLine2,
        city: values.scity,
        state: values.sstate,
        country: values.scountry,
        zip_code: values.spincode,
        phone_number: values.sphone,
        is_default: false,
        save_address: values.saveShippingAddress,
      };

      if (values.isShippingAddressSame === true) {
        finalAddress = {
          billing_same_as_shipping: true,
          address: billingAddress,
        };
      } else {
        finalAddress = {
          billing_same_as_shipping: false,
          address: {
            ...shippingAddress,
            billing_address: billingAddress,
          },
        };
      }

      const success = await commands.cart.addAddress({
        address: finalAddress,
        id: addressId,
      });
      if (success === true) {
        setShowPaymentOptions(true);
      }
    } catch (error) {
      window.notify([
        {
          message:
            error.message || "Error occured while adding address to cart.",
        },
      ]);
    } finally {
      setValues(undefined);
    }
  }

  async function placeOrder() {
    const orderPlaced = await commands.cart.placeOrder();
    if (orderPlaced !== false) {
      history.push("/orderplaced?cartId=" + orderPlaced.id);
    }
  }

  async function updateBlockqty(values) {
    const isAllProductsAvailable = await commands.cart.checkAvailabilityAndBlock();
    // console.log("Returned value is ", isAllProductsAvailable);
    if (isAllProductsAvailable) {
      // addAddressToCart(values);
      setValues(values);
    }
  }

  // async function getHyperPayId() {
  //   const paymentType = "DB";
  //   const id = await commands.cart.getHyperPayTransactionId({ paymentType });
  //   console.log("Received id is ", id);
  //   if (typeof id === "string") {
  //     setHyperPayId(id);
  //   }
  // }

  // useEffect(() => {
  //   if (paymentMode === paymentModes.debitcard) {
  //     getHyperPayId();
  //   }
  // }, [paymentMode]);

  useEffect(() => {
    window.onmessage = (e) => {
      console.log("E data is ", e.data);
      setiFrameHeight(e.data?.frameHeight + "px");
    };

    return () => (window.onmessage = undefined);
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const scrollTo = () => {
    window.scrollTo(0, 0);
  };

  const displayRazorPay = async () => {
    // loading script
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load");
      return;
    }

    // creating order
    const orderPlaced = await commands.cart.createOrder();
    if (orderPlaced) {
      // get user data
      const authState = AuthState.get();
      const {
        user: { name, email, phone_number },
      } = authState;

      // Getting the order details back
      const { total, razorpay_order_id } = orderPlaced;
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: (total * 100).toString(), // 2000 paise = INR 20, amount in paisa
        name: "Escape Privé",
        description: "Test Transaction",
        order_id: razorpay_order_id,
        currency: "INR",
        image: require("./images/Logo.svg"),
        handler: async (response) => {
          // in test mode only razorpay_payment_id is received
          setProcessing(true);
          const data = {
            // order_creation_id: id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };
          const result = await commands.cart.verifySignature(data);
          if (result?.data?.success) {
            dispatch(clearCartOrders());
            history.push("/orderplaced?cartId=" + razorpay_order_id);
          } else {
            setPaymentMode(paymentModes.cashondelivery);
          }
          setProcessing(false);
        },
        prefill: {
          name,
          email,
          contact: phone_number,
        },
        notes: {
          address: "Escape Prive, USA.",
        },
        theme: {
          color: "#000",
        },
        modal: {
          ondismiss: () => setPaymentMode(paymentModes.cashondelivery),
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      alert("Server error");
    }
  };

  return (
    <Fragment>
      <div className="checkout-form-wrap">
        <Form>
          {!showPaymentOptions ? (
            <Fragment>
              {initialAddress && (
                <Formik
                  initialValues={initialAddress}
                  onSubmit={(values) => updateBlockqty(values)}
                  validationSchema={validationSchemas.addressForm}
                >
                  {({
                    values,
                    handleChange,
                    errors,
                    setFieldTouched,
                    touched,
                    handleSubmit,
                    setValues,
                  }) => (
                    <Fragment>
                      <AllModal
                        modalName="selectaddress"
                        type="billing"
                        isOpen={ShowSelectAddressModal}
                        toggle={
                          (() =>
                            setShowSelectAddressModal(!ShowSelectAddressModal),
                          () => history.push(`/profile/saveaddresses`))
                        }
                        onContinue={onContinueAddressModal}
                        setFieldValue={setValues}
                        values={values}
                      />
                      <AllModal
                        modalName="selectaddress"
                        type="shipping"
                        isOpen={ShowShippingSelectAddressModal}
                        toggle={
                          (() =>
                            setShowShippingSelectAddressModal(
                              !ShowShippingSelectAddressModal
                            ),
                          () => history.push(`/profile/saveaddresses`))
                        }
                        onContinue={onContinueAddressModal}
                        setFieldValue={setValues}
                      />
                      <div className="cart-pg-inner-wrap bg-white radius-10 cart-pg-mb-30">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h2 className="cart-checkout-tile mt-0">
                            Billing Address
                          </h2>
                          <Button
                            color="link cart-select-aar-btn"
                            onClick={() => setShowSelectAddressModal(true)}
                          >
                            <Blink
                              color="black"
                              text="Select Address"
                              fontSize="20"
                            >
                              Select Address
                            </Blink>
                          </Button>
                        </div>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">Name</span>
                              <Input
                                type="text"
                                name="bname"
                                id="checkout-form-name"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bname")}
                                value={values.bname}
                              />
                              <FieldError
                                error={errors.bname}
                                touched={touched.bname}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">
                                Flat / House / Apartment No.
                              </span>
                              <Input
                                type="text"
                                name="bhouseNumber"
                                id="checkout-form-address"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bhouseNumber")}
                                value={values.bhouseNumber}
                              />
                              <FieldError
                                error={errors.bhouseNumber}
                                touched={touched.bhouseNumber}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">
                                Address Line 1
                              </span>
                              <Input
                                type="text"
                                name="baddressLine1"
                                id="checkout-form-address-2"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("baddressLine1")}
                                value={values.baddressLine1}
                              />
                              <FieldError
                                error={errors.baddressLine1}
                                touched={touched.baddressLine1}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">
                                Address Line 2
                              </span>
                              <Input
                                type="text"
                                name="baddressLine2"
                                id="checkout-form-address-3"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("baddressLine2")}
                                value={values.baddressLine2}
                              />
                              <FieldError
                                error={errors.baddressLine2}
                                touched={touched.baddressLine2}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">City</span>
                              <Input
                                type="text"
                                name="bcity"
                                id="checkout-form-city"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bcity")}
                                value={values.bcity}
                              />
                              <FieldError
                                error={errors.bcity}
                                touched={touched.bcity}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">State</span>
                              <Input
                                type="text"
                                name="bstate"
                                id="checkout-form-state"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bstate")}
                                value={values.bstate}
                              />
                              <FieldError
                                error={errors.bstate}
                                touched={touched.bstate}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">Country</span>
                              <Input
                                type="text"
                                name="bcountry"
                                id="checkout-form-country"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bcountry")}
                                value={values.bcountry}
                              />
                              <FieldError
                                error={errors.bcountry}
                                touched={touched.bcountry}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">Postal Code</span>
                              <Input
                                type="text"
                                name="bpincode"
                                id="checkout-form-pincode"
                                className="py-2 border-0 pl-0"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bpincode")}
                                value={values.bpincode}
                              />
                              <FieldError
                                error={errors.bpincode}
                                touched={touched.bpincode}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <span className="checkout-form-label">
                                Phone Number
                              </span>
                              <Input
                                type="number"
                                name="bphone"
                                id="checkout-form-ph-number"
                                className="py-2 border-0 pl-0 yt-remove-arrow"
                                onChange={handleChange}
                                onBlur={() => setFieldTouched("bphone")}
                                value={values.bphone}
                              />
                              <FieldError
                                error={errors.bphone}
                                touched={touched.bphone}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <div className="checkout-checkbox d-flex flex-wrap my-3">
                              <FormGroup className="mr-5">
                                <span className="yt-checkout-chekbox-label">
                                  My billing and shipping address are the same
                                </span>
                                <Input
                                  type="checkbox"
                                  id="checkout-billing-addr"
                                  name="isShippingAddressSame"
                                  checked={values.isShippingAddressSame}
                                  onChange={handleChange}
                                />{" "}
                                <Label
                                  className="yt-checkout-form-chk-box"
                                  check
                                />
                              </FormGroup>
                              {/* <FormGroup>
                                <span className="yt-checkout-chekbox-label">
                                  Save Address
                                </span>
                                <Input
                                  type="checkbox"
                                  id="save-addr"
                                  name="saveBillingAddress"
                                  checked={values.saveBillingAddress}
                                  onChange={handleChange}
                                />{" "}
                                <Label
                                  className="yt-checkout-form-chk-box"
                                  check
                                />
                              </FormGroup> */}
                            </div>
                          </Col>
                        </Row>
                        {!values.isShippingAddressSame && (
                          <Fragment>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <h2 className="cart-checkout-tile mt-0">
                                Shipping Address
                              </h2>
                              <Button
                                color="link cart-select-aar-btn"
                                onClick={() =>
                                  setShowShippingSelectAddressModal(true)
                                }
                              >
                                Select Address
                              </Button>
                            </div>
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">Name</span>
                                  <Input
                                    type="text"
                                    name="sname"
                                    id="checkout-form-shiping-name"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("sname")}
                                    value={values.sname}
                                  />
                                  <FieldError
                                    error={errors.sname}
                                    touched={touched.sname}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">
                                    Flat / House / Apartment No.
                                  </span>
                                  <Input
                                    type="text"
                                    name="shouseNumber"
                                    id="checkout-form-shiping-address"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() =>
                                      setFieldTouched("shouseNumber")
                                    }
                                    value={values.shouseNumber}
                                  />
                                  <FieldError
                                    error={errors.shouseNumber}
                                    touched={touched.shouseNumber}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">
                                    Address Line 1
                                  </span>
                                  <Input
                                    type="text"
                                    name="saddressLine1"
                                    id="checkout-form-shiping-address-1"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() =>
                                      setFieldTouched("saddressLine1")
                                    }
                                    value={values.saddressLine1}
                                  />
                                  <FieldError
                                    error={errors.saddressLine1}
                                    touched={touched.saddressLine1}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">
                                    Address Line 2
                                  </span>
                                  <Input
                                    type="text"
                                    name="saddressLine2"
                                    id="checkout-form-shiping-address-2"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() =>
                                      setFieldTouched("saddressLine2")
                                    }
                                    value={values.saddressLine2}
                                  />
                                  <FieldError
                                    error={errors.saddressLine2}
                                    touched={touched.saddressLine2}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">City</span>
                                  <Input
                                    type="text"
                                    name="scity"
                                    id="checkout-form-shiping-city"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("scity")}
                                    value={values.scity}
                                  />
                                  <FieldError
                                    error={errors.scity}
                                    touched={touched.scity}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">State</span>
                                  <Input
                                    type="text"
                                    name="sstate"
                                    id="checkout-form-shiping-state"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("sstate")}
                                    value={values.sstate}
                                  />
                                  <FieldError
                                    error={errors.sstate}
                                    touched={touched.sstate}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">
                                    Country
                                  </span>
                                  <Input
                                    type="text"
                                    name="scountry"
                                    id="checkout-form-shiping-country"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("scountry")}
                                    value={values.scountry}
                                  />
                                  <FieldError
                                    error={errors.scountry}
                                    touched={touched.scountry}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">
                                    Postal Code
                                  </span>
                                  <Input
                                    type="text"
                                    name="spincode"
                                    id="checkout-form-shiping-pincode"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("spincode")}
                                    value={values.spincode}
                                  />
                                  <FieldError
                                    error={errors.spincode}
                                    touched={touched.spincode}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <span className="checkout-form-label">
                                    Phone Number
                                  </span>
                                  <Input
                                    type="number"
                                    name="sphone"
                                    id="checkout-form-shiping-ph-number"
                                    className="py-2 border-0 pl-0"
                                    onChange={handleChange}
                                    onBlur={() => setFieldTouched("sphone")}
                                    value={values.sphone}
                                  />
                                  <FieldError
                                    error={errors.sphone}
                                    touched={touched.sphone}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={12}>
                                <div className="checkout-checkbox d-flex flex-wrap my-3">
                                  <FormGroup>
                                    <span className="yt-checkout-chekbox-label">
                                      Save Address
                                    </span>
                                    <Input
                                      type="checkbox"
                                      id="checkout-form-shiping-save-address"
                                      name="saveShippingAddress"
                                      checked={values.saveShippingAddress}
                                      onChange={handleChange}
                                    />{" "}
                                    <Label
                                      className="yt-checkout-form-chk-box"
                                      check
                                    />
                                  </FormGroup>
                                </div>
                              </Col>
                            </Row>
                          </Fragment>
                        )}
                      </div>
                      <div className="text-right">
                        <Ripple>
                          <Button
                            color="btn btn-secondary cart-proceed-to-checkput py-3 px-5"
                            onClick={handleSubmit}
                          >
                            Proceed To Pay
                          </Button>
                        </Ripple>
                      </div>
                    </Fragment>
                  )}
                </Formik>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className="cart-pg-inner-wrap bg-white radius-10 cart-pg-mb-30">
                <h2 className="cart-checkout-tile mt-0 mb-3">Payment Option</h2>
                <Row>
                  <Col md={12}>
                    <div className="yt-chekout-radio d-flex flex-wrap my-3">
                      <FormGroup className="mr-2">
                        <span className="checkout-form-label">
                          Cash On Delivery
                        </span>

                        <Input
                          checked={paymentMode === paymentModes.cashondelivery}
                          type="radio"
                          id="checkout-form-payment-radio1"
                          name="checkout-form-payment-radio1"
                          data-item="payment1"
                          onChange={() => {
                            setPaymentMode(paymentModes.cashondelivery);
                          }}
                        />
                        <Label className="yt-checkout-form-rado-box" check />
                      </FormGroup>
                      <FormGroup>
                        <span className="checkout-form-label">Online Payment</span>
                        <Input
                          checked={paymentMode === paymentModes.debitcard}
                          type="radio"
                          id="checkout-form-payment-radio2"
                          name="checkout-form-payment-radio1"
                          data-item="payment2"
                          onChange={() => {
                            setPaymentMode(paymentModes.debitcard);
                            displayRazorPay();
                          }}
                        />{" "}
                        <Label className="yt-checkout-form-rado-box" check />
                      </FormGroup>
                    </div>
                  </Col>
                  {paymentMode === paymentModes.debitcard && processing && (
                    <Col md={12}>
                      <Alert className="payment-alert">
                        <div className="spinner">
                          <div className="rect1" />
                          <div className="rect2" />
                          <div className="rect3" />
                          <div className="rect4" />
                          <div className="rect5" />
                        </div>
                        <p className="processing-text">
                          {language.payment.processing}
                        </p>
                      </Alert>
                    </Col>
                  )}
                </Row>
              </div>
              <div className="text-right">
                <Ripple>
                  {paymentMode === paymentModes.cashondelivery && (
                    <Button
                      color="btn btn-secondary cart-proceed-to-checkput py-3 px-5"
                      onClick={placeOrder}
                    >
                      Place Order
                    </Button>
                  )}
                </Ripple>
              </div>
            </Fragment>
          )}
        </Form>
      </div>
    </Fragment>
  );
}
function CartPageCheckoutContent() {
  return (
    <div className="cart-checkout-wrap">
      <Row>
        <Col md={12}>
          <CartCheckoutform />
        </Col>
      </Row>
    </div>
  );
}
function CartProductListData(props) {
  const [ShowCheckout, setShowCheckout] = useState(false);
  const [ShowCart, setShowCart] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false);
  const [cartProd, setCartprod] = useState([]);
  const authState = AuthState.get();
  const cartState = CartState.get();
  const history = useHistory();

  const { cartTitle, setcarttitle } = props;
  const CheckoutBtn = () => {
    setShowCheckout(!ShowCheckout);
    setcarttitle(!cartTitle);
  };

  useEffect(() => {
    getMycart();
    showCartForm();
  }, [authState]);

  function showLoginForm() {
    setShowLogin(true);
    setShowCart(false);
    setShowCheckout(false);
  }
  function getMycart() {
    const serviceType = serviceTypes.refreshCart(
      authState?.user?.id,
      cartState?.cart?.id
    );
    service(serviceType).then(({response,error})=>{
      if(error){
        console.log(error,"ERROR")
      }
      setCartprod(response?.data?.data?.order?.order_items);
    })
  }

  function showCartForm() {
    setShowLogin(false);
    setShowCart(true);
    setShowCheckout(false);
  }
  function showCheckoutForm() {
    setcarttitle(!cartTitle);
    setShowLogin(false);
    setShowCart(false);
    setShowCheckout(true);
  }

  function getProducts() {
    var products = [];
    // var items = cartState.cart?.order_items;
    var myCart = CartState.get();
    var items = cartState.cart?.order_items;
    if (Array.isArray(items)) {
      products = items.map((item, idx) => (
        <CartProduct key={idx} product={item} />
      ));
    }

    return products;
  }
 
  async function proceedToCheckoutForm() {
    if (commands.user.isLoggedIn()) {
      showCheckoutForm();
    } else {
      history.push(`/login?redirect=${history.location.pathname}`);
    }
  }

  return (
    <>
      <section className="cat-main-wrapper mb-4">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="cart-page-main-title mt-0">
                {cartTitle ? "Cart" : "Secure Checkout"}
              </h1>
            </Col>
          </Row>

          {ShowLogin && (
            <Row>
              <Col md={12}>
                <LoginComponent
                  className="login-on-cart-page login-box-top"
                  container={true}
                  active="2"
                />
              </Col>
            </Row>
          )}
          <Row className="yt-cm-row">
            <Col md={7} className="yt-cm-lt-col">
              {ShowLogin ? (
                <LoginComponent
                  className="login-on-cart-page login-box-bottom"
                  container={true}
                  active="2"
                />
              ) : (
                <Fragment>
                  {ShowCheckout ? (
                    <CartPageCheckoutContent />
                  ) : (
                    <Fragment>{getProducts()}</Fragment>
                  )}
                </Fragment>
              )}
            </Col>
            <Col md={5} className="yt-cm-rt-col">
              {(ShowCart || ShowCheckout) && (
                <CartAmount cart={cartState.cart} />
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              {ShowCheckout ? (
                <div className="text-right">
                  <Ripple>
                    <Button
                      color="btn btn-secondary cart-proceed-to-checkput cart-pp-btn cart-btn-bill-pg py-3 px-5"
                      onClick={CheckoutBtn}
                    >
                      Proceed to Pay
                    </Button>
                  </Ripple>
                </div>
              ) : (
                <Fragment>
                  {ShowCart && (
                    <div className="text-right row">
                      <div className="col-md-6 col-md-offset-3">
                        <Ripple>
                          <Button
                            color="btn btn-secondary cart-proceed-to-checkput cart-pp-btn py-3 px-5"
                            onClick={proceedToCheckoutForm}
                          >
                            Proceed
                          </Button>
                        </Ripple>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
function CartPageContent() {
  const [cartTitle, setcarttitle] = useState(true);

  return (
    <>
      <CartBreadCrumbs cartTitle={cartTitle} />

      <CartProductListData cartTitle={cartTitle} setcarttitle={setcarttitle} />
    </>
  );
}


function CartAmount({ cart, ...props }) {
  const [CheckCouponCode, setCheckCouponCode] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [enableInput, setEnableInput] = useState(true);
  const checkCoupon = () => {
    setCheckCouponCode(!CheckCouponCode);
  };

  function getProducts() {
    var items = [];
    if (Array.isArray(cart.order_items) && cart.order_items.length) {
      cart.order_items.forEach((item, idx) =>
        items.push(
          <tr key={idx}>
            <td>
              <span className="cart-product-amount-ttl">{item.product_name}</span>
            </td>
            <td className="cart-price-column">
              <span className="cart-product-amount-price">
                {config.currency} {item.total_price}
                {/* {item.product.product_variants.length > 0 ? (
                  <>
                    {item.product.product_variants[0]?.on_sale === true ? (
                      <>
                        {config.currency}{" "}
                        {item.product.product_variants[0]?.sale_price}
                      </>
                    ) : (
                      <>
                        {config.currency}{" "}
                        {item.product.product_variants[0]?.actual_price}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {item.product.on_sale === true ? (
                      <>
                        {config.currency} {item.product?.sale_price}
                      </>
                    ) : (
                      <>
                        {config.currency} {item.product.price}
                      </>
                    )}
                  </>
                )} */}
              </span>
            </td>
          </tr>
        )
      );
    }
    return items;
  }

  useEffect(() => {
    if (cart.coupon === null) {
      setEnableInput(true);
    } else {
      setEnableInput(false);
    }
    setCouponCode(cart.coupon?.code || "");
  }, [cart.coupon, cart.coupon?.code]);
  return (
    <div className="radius-10 bg-white yt-cart-price-lister">
      <Table className="mb-0 cart-prodict-amount " borderless>
        <thead>
          <tr>
            <th>Your Cart</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>{getProducts()}</tbody>
      </Table>
      <span className="cart-divider" />
      <Table className="mb-0 cart-prodict-total-amount " borderless>
        <tbody>
          <tr>
            <td>
              <span className="cart-product-amount-ttl">Taxes</span>
            </td>
            <td className="cart-price-column">
              <span className="cart-product-amount-price">
                {config.currency} {cart.total_tax}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="cart-product-amount-ttl">Delivery Charges</span>
            </td>
            <td>
              <span className="cart-product-amount-price">
                {config.currency} {cart.delivery_charges}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
      <span className="cart-divider" />
      <div className="cart-coupon my-3">
        <Form className="pb-4">
          <FormGroup className="m-0 error">
            <input
              type="text"
              className="form-control"
              id="cart-total-products-amount"
              placeholder="Apply Coupon"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              disabled={!enableInput}
            />
            <div className="pb-3 d-flex align-items-center cart-coupon-bottom-wrapper justify-content-between">
              {cart.coupon && !codeError && (
                <span
                  className="cart-coupon-code-message success-message"
                  style={{ color: "#43b7a7", display: "block" }}
                >
                  Great ! Coupon Code Applied
                </span>
              )}

              {codeError && (
                <span className="cart-coupon-code-message error-message">
                  Oops ! Coupon Code is Not Valid
                </span>
              )}

              {cart.coupon && !enableInput && (
                <>
                  <Button
                    color=" cart-coupon-change-btn p-0"
                    onClick={() => {
                      setEnableInput(true);
                      setCodeError(false);
                    }}
                  >
                    Change Coupon
                  </Button>
                </>
              )}
            </div>
            {enableInput && (
              <Button
                color=" cart-coupon-btn"
                onClick={() => {
                  setCodeError(false);
                  commands.cart.applyCoupon({
                    couponCode,
                    onError: () => setCodeError(true),
                  });
                }}
              >
                Apply
              </Button>
            )}
            {cart.coupon && !enableInput && (
              <Button
                color=" cart-remove-coupon-btn"
                onClick={() => {
                  setCodeError(false);
                  commands.cart.removeCoupon({
                    onError: () => setCodeError(true),
                  });
                }}
              >
                Remove
              </Button>
            )}
          </FormGroup>
        </Form>
        {cart.coupon && (
          <div>
            <Table className="mt-2 mb-0 cart-prodict-total-amount " borderless>
              <tbody>
                <tr>
                  <td>
                    <span className="cart-product-amount-ttl">Discount</span>
                  </td>
                  <td>
                    <span className="cart-product-amount-price">
                      - {config.currency} {cart.applied_discount}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
            <span className="cart-divider" />
          </div>
        )}
      </div>
      <Table className="mb-0 cart-prodict-sub-total-amount " borderless>
        <tbody>
          <tr>
            <td>
              <span className="cart-product-amount-ttl">Sub Total</span>
            </td>
            <td>
              <span className="cart-product-amount-price cart-sub-total">
                {config.currency} {cart.total}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

function CartProduct({ product, ...props }) {
 
  const [quantity, setQuantity] = useState(product.quantity || 0);
  const [ShowWLModal, setShowWLModal] = useState(false);
  const [favProduct, setfavProduct] = useState();
  const openRemoveWhishListModal = (prod) => {
    setShowWLModal(!ShowWLModal);
    setfavProduct(prod);
  };
  const [ShowCLModal, setShowCLModal] = useState(false);
  const [cartProduct, setcartProduct] = useState();
  const openRemoveCartListModal = (prod) => {
    setShowCLModal(!ShowCLModal);
    setcartProduct(prod);
  };
  const maxQuantity = product.product_variant?.stock_qty || 0;
  const product_variant = product.product_variant;
  const state = AuthState.get();
  const myCart = CartState.get();
  var color = "";
  var size = "";
  const history = useHistory();
  function getColorAndSize() {
    var properties = product.product_variant?.product_variant_properties;
    if (Array.isArray(properties)) {
      properties.forEach((item, idx) => {
        if (item.variant_name === "color") {
          color = item.property_name || "";
        } else if (item.variant_name === "size") {
          size = item.property_name || "";
        }
      });
    }
  }
  getColorAndSize();
 
  if (product.quantity !== quantity) {
    setQuantity(product.quantity);
  }

  const addToWishlist = (product) => {
    const serviceType = serviceTypes.addToWishlist(state.user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        commands.cart.removeItem({
          productId: product.id,
          variantId: product.product_variants[0].id || "",
        });
        window.notify([
          {
            message: "Product added to wishlist successfully",
            type: "success",
          },
        ]);
        product.is_wishlisted = true;
        commands.cache.getHomeProducts();
      }
    });
  };

  const removeFromWishlist = (product) => {
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product removed from wishlist successfully",
            type: "danger",
          },
        ]);
        product.is_wishlisted = false;
        commands.cache.getHomeProducts();
      }
    });
  };

  const removeCartItem = (product) => {
  {
      product.product_variants.length > 0
        ? commands.cart.removeItem(
            {
              productId: product?.id,
              variantId: product.product_variants[0]?.id,
              order_item_id: myCart?.cart.order_items[0]?.id,
            },
            commands.cache.getHomeProducts()
          )
        : commands.cart.removeItem(
            {
              productId: product?.id,
              order_item_id: myCart?.cart.order_items[0]?.id,
            },
            commands.cache.getHomeProducts()
          );
    }
  };

  return (
    <div className="cart-produt-list-wrap radius-10 bg-white cart-pg-mb-30">
      {ShowWLModal ? (
        <AllModal
          modalName="removewhishlist"
          onConfirm={() => removeFromWishlist(favProduct)}
        />
      ) : (
        ""
      )}
      {ShowCLModal ? (
        <AllModal
          modalName="removefromcart"
          onConfirm={() => removeCartItem(cartProduct)}
        />
      ) : (
        ""
      )}
      <div className="d-flex flex-wrap cart-pg-product-list-row">
        <div className="cart-pg-list-image">
          <div
            className="cart-product-image w3-ripple"
            onClick={() => history.push(`/shop/${product?.product?.id}`)}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <>
              {product.product.images.length > 0 ? (
                <>
                  <img
                    src={
                      product.product?.images[0]?.image ||
                      "#null" ||
                      require("./images/order-product-img.png")
                    }
                    className="img-fluid"
                  />
                </>
              ) : (
                <>
                  <img
                    src={
                      "/images/thumb/missing.png" ||
                      require("./images/order-product-img.png")
                    }
                    className="img-fluid"
                  />
                </>
              )}
            </>
          </div>
        </div>
        <div className="cart-pg-list-prdt-info d-flex justify-content-between">
          <div
            className="cart-prodict-info w3-ripple"
            style={{ cursor: "default" }}
            onClick={() => history.push(`/shop/${product?.product?.id}`)}
          >
            <h2 className="cart-product-title mt-0">{product.product_name}.</h2>
            <h4 className="cart-subttl-box">{product?.product.description}</h4>
            {console.log(product,"MYPRODUCT")}
            <Table className="cart-prodict-type w-auto" borderless>
              <tbody>
              {product?.product_variant?.product_variant_properties?.length > 0 ? (<>
              {product?.product_variant?.product_variant_properties.map((variant,ind)=>(
                <tr>
                {<th>{variant.variant_name} :</th>} {<td>{variant.property_name}</td>}
              </tr>
              ))}
              </>) : (<></>)}
                
              </tbody>
            </Table>
          </div>
          <div className="cart-list-other-act">
            <span className="cart-product-price">
              {config.currency} {product.total_price}
            </span>
            <div className="cart-action-wrap text-right">
              <div className="cart-quantity-box">
                {product?.product?.product_variants.length > 0 ? (
                  <CartQuantityField
                    CartQuantityVal={quantity}
                    setCartQuantityVal={(val) => {
                      console.log(val,"VALE")
                      setQuantity(val);
                      commands.cart.updateItem({
                        productId: product?.product?.id,
                        variantId: product?.product_variant[0]?.id,
                        quantity: val,
                      });
                    }}
                    maxQuantity={maxQuantity}
                  />
                ) : (
                  <CartQuantityField
                    CartQuantityVal={quantity}
                    setCartQuantityVal={(val) => {
                      setQuantity(val);
                      commands.cart.updateItem({
                        productId: product?.product?.id,
                        quantity: val,
                      });
                    }}
                    maxQuantity={maxQuantity}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="cart-action-btm-wrap text-right d-flex align-items-center">
            <div
              className="cart-product-delete w3-ripple"
              onClick={() => openRemoveCartListModal(product?.product)}
            >
              Remove
            </div>
            <div className="yt-cart-pg-middle-sep" />
            <div
              className="yt-cart-mv-list"
              onDataId="1"
              onClick={() =>
                product?.product.is_wishlisted
                  ? openRemoveWhishListModal(product?.product)
                  : addToWishlist(product?.product)
              }
            >
              {product?.product.is_wishlisted
                ? "Remove from Wish List"
                : "Move to Wish List"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CartPageComponent() {
  const { cart } = CartState.get();
  var ShowCart = false;

  if (typeof cart === "object") {
    if (cart.order_items?.length > 0) {
      ShowCart = true;
    }
  } else {
    ShowCart = false;
    window.notify([{ message: "Unable to read cart", type: "danger" }]);
  }

  useEffect(() => {
    commands.user.getAddressList();
  }, []);

  return ShowCart ? <CartPageContent /> : <EmptyCartContent />;
}
export default CartPageComponent;
