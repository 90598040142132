import React, { useEffect, useState, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import "./css/index.scoped.css";
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
} from "reactstrap";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import classnames from "classnames";
import { AllModal } from "../../components";
import SearchData from "./SearchData";
import { useSelector } from "react-redux";
import { get, getProducts } from "../../Barriers/apiHelper";
import { passCategoryData } from "../../redux/actions/cartActions";
import { useDispatch } from "react-redux";
import { NavLink as RouterNav } from "react-router-dom";
import commands from "../../commands";
import { BsHeart } from "react-icons/bs";
import AuthState from "../../redux/states/auth";
import service, { serviceTypes } from "../../services";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import Hamburger from "hamburger-react";

const cleanArray = (actual) => {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
};

function Header(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = AuthState.get();
  const currentPageArray = props.onProps.location.pathname;
  const currentpagestr = cleanArray(currentPageArray.split("/"));
  const [isOpen, setIsOpen] = useState(false);
  // const [userData, setuserData] = useState([]);
  const [name, setName] = useState("");
  const state = useSelector((state) => state.logInReducer);
  const cartState = useSelector((state) => state.cartReducer);
  // console.log(cartState, "cartStateS");
  const [category, setCategoryData] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [incomingCategories, setIncomingcat] = useState(undefined);
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [userDataStatus, setUserDataStatus] = useState(true);
  const [SearchDropDown, setSearchDropDown] = useState(false);
  const [quickResults, setQuickResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showService, setShowservice] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [ShowLogoutModal, Logoutfunc] = useState(false);
  const openLogoutModal = () => Logoutfunc(!ShowLogoutModal);
  let currentPageActive = "";
  if (currentpagestr.length === 0) {
    currentPageActive = "/";
  } else {
    currentPageActive = currentpagestr.shift();
  }
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      setHeaderScrolled(false);
    } else if (window.scrollY > 250) {
      setHeaderScrolled(true);
    }
  };
  const toggle = () => setIsOpen(!isOpen);

  const OpenSearchDropDown = () => {
    setSearchDropDown(!SearchDropDown);
  };
  const [ShowNotifyMeModal] = useState(false);
  const callCategoriesUuid = (uuid) => {
    get(`/categories?with_subcategory=true&uuid=${uuid}`)
      .then((res) => {
        dispatch(passCategoryData(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const callCategoriesToken = ({ access_token, token_type }) => {
    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token_type} ${access_token}`,
      },
    };
    getProducts(`/categories?with_subcategory=true`, header)
      .then((res) => {
        dispatch(passCategoryData(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function quickSearch() {
    if (searchQuery !== "") {
      commands.products
        .getQuickSearch({ query: searchQuery })
        .then((results) => {
          setQuickResults(results || []);
        });
    }
  }
  useEffect(() => {
    if (incomingCategories === undefined) {
      getCategories();
    }
    if (state.loginData?.user?.name) {
      setName(state.loginData?.user?.name);
      // getWishlistProducts();
    }
    if (
      Object.keys(state.loginData)?.length > 0 &&
      cartState.categotyData?.length === 0
    ) {
      callCategoriesToken(state.loginData.token);
    } else if (state.uuId?.length > 0 && cartState.categotyData?.length == 0) {
      callCategoriesUuid(state.uuId);
    } else {
      setCategoryData(cartState.categotyData);
    }
  }, [props, category, state, cartState]);

  function getCategories() {
    const serviceType = serviceTypes.getCategories();
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log(error, "CATER");
      } else {
        setIncomingcat(response?.data?.data);
      }
    });
  }
  
  function reset() {
    setSearchDropDown(false);
    setShowSearch(false);
    setSearchQuery("");
  }

  return (
    <header
      className={
        headerScrolled
          ? "main_header myHeaderStyle2"
          : "main_header myHeaderStyle"
      }
      id="myDiv"
      onMouseLeave={() => {
        setShowservice(false);
        setShowCategory(false);
      }}
    >
      {" "}
      {ShowLogoutModal ? <AllModal modalName="logout" /> : ""}
      {ShowNotifyMeModal ? <AllModal modalName="notifyme" /> : ""}
      <Navbar expand="md" className="myNavbar">
        <div className="d-md-none menuicon" onClick={toggle}>
          <Hamburger
            size={16}
            label="Show Menu"
            duration={1}
            direction="right"
            color="white"
          />
        </div>
        <NavbarBrand href="/" onClick={() => history.push("/")}>
          <img
            src={require("./images/Logo2.png")}
            className="img-fluid"
            alt="Pooja Duggal"
          />
        </NavbarBrand>

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <Dropdown
              nav
              inNavbar
              isOpen={showService}
              onMouseEnter={() => {
                setShowservice(true);
                setShowCategory(false);
              }}
              toggle={() => {
                setShowservice(!showService);
                setShowCategory(false);
              }}
            >
              <DropdownToggle nav className="nav-link ">
                SERVICES &nbsp;
                <BsChevronRight className="head-dropdown-arrow-icn iconRight" />
                <BsChevronDown className="head-dropdown-arrow-icn iconDown" />
              </DropdownToggle>
              <DropdownMenu className="cm_big_dropmenu">
                <div className="square" />
                {/* <DropdownItem className="category_name">
                  I'm Getting Engaged
                </DropdownItem>
                <span className="cart-divider" /> */}
                <div className="sub_drop_menu">
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/ourservices?type=1`
                      )
                    }
                  >
                    I'm Getting Engaged
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/ourservices?type=2`
                      )
                    }
                  >
                    Celebrating a Milestone
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/ourservices?type=3`
                      )
                    }
                  >
                    Children's Customized Gifting
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/ourservices?type=4`
                      )
                    }
                  >
                    Wedding Jewelry
                  </DropdownItem>
                  {/* <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/shop?page=1&per_page=9&type=5`
                      )
                    }
                  >
                    For Him
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/shop?page=1&per_page=9&type=6`
                      )
                    }
                  >
                    Matched Sets
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/shop?page=1&per_page=9&type=7`
                      )
                    }
                  >
                    Everyday Wear
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/shop?page=1&per_page=9&type=8`
                      )
                    }
                  >
                    Find Me My Solitaire
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/shop?page=1&per_page=9&type=9`
                      )
                    }
                  >
                    For Her
                  </DropdownItem>
                  <span className="cart-divider" />
                  <DropdownItem
                    onClick={() =>
                      history.push(
                        `/shop?page=1&per_page=9&type=10`
                      )
                    }
                  >
                    Valentines/ Love Gifts
                  </DropdownItem> */}
                </div>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              nav
              inNavbar
              isOpen={showCategory}
              onMouseEnter={() => {
                setShowCategory(true);
                setShowservice(false);
              }}
              toggle={() => {
                setShowCategory(!showCategory);
                setShowservice(false);
              }}
            >
              <DropdownToggle nav className="nav-link ">
                COLLECTIONS &nbsp;
                <BsChevronRight className="head-dropdown-arrow-icn iconRight" />
                <BsChevronDown className="head-dropdown-arrow-icn iconDown" />
              </DropdownToggle>
              <DropdownMenu className="cm_big_dropmenu">
                <div className="square" />
                {incomingCategories?.length > 0 ? (
                  incomingCategories.map((x, index) => {
                    // if (x.sub_categories.length > 1) {
                    //   return null;
                    // }
                    return (
                      <>
                        <DropdownItem
                          className="category_name"
                          onClick={() =>
                            history.push(`/shop/?category_id[]=${x.id}&page=1&per_page=9`, {
                              state: { categoryName: x.name },
                            })
                          }
                        >
                          {x.name}
                        </DropdownItem>
                        {incomingCategories.length - 1 !== index ? (
                          <span className="my-cart-divider" />
                        ) : null}
                        {/* {x.sub_categories.length > 0 ? (
                          x.sub_categories.map((sub, idx) => {
                            return (
                              <div className="sub_drop_menu" key={idx}>
                                <DropdownItem>
                                  {sub.name}
                                </DropdownItem>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )} */}
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </DropdownMenu>
            </Dropdown>
            <NavItem>
              <RouterNav
                className={
                  history.location.pathname === "/aboutus"
                    ? "nav-link iamactive"
                    : "nav-link hoverMe"
                }
                to="/aboutus"
              >
                ABOUT US
              </RouterNav>
            </NavItem>
            <NavItem>
              <RouterNav
                className={
                  history.location.pathname === "/contactus"
                    ? "nav-link iamactive"
                    : "nav-link hoverMe"
                }
                to="/contactus"
              >
                CONTACT US
              </RouterNav>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem className="search-icon">
              <div className="nav-link">
                <AiOutlineSearch onClick={() => setShowSearch(true)} />
              </div>
              <div
                className={showSearch ? "search-input active" : "search-input"}
              >
                <AiOutlineSearch className="searchInput-icon" />
                <input
                  type="text"
                  placeholder="Search"
                  className="searchinput"
                  onClick={() => setSearchDropDown(true)}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyUp={() => quickSearch()}
                />
                <IoCloseOutline
                  className="cancel-search-icon"
                  onClick={() => reset()}
                />
              </div>
              {SearchDropDown && searchQuery != "" && (
                <SearchData hideSearch={() => reset()} results={quickResults} />
              )}
            </NavItem>

            <NavItem>
              {!commands.user.isLoggedIn() ? (
                <RouterNav className="nav-link user-icon" to="/login">
                  <FaUserCircle />
                </RouterNav>
              ) : (
                <RouterNav className="nav-link user-icon" to="/profile">
                  <div className="text-center">
                    <FaUserCircle />
                    <div className="mt-2 user-name text-capitalize">
                      {authState.user.name}
                    </div>
                  </div>
                </RouterNav>
              )}
            </NavItem>
            {isOpen && state?.guest_user === false ? (
              <>
                <div className="borderTop" />
                <NavItem onClick={() => setIsOpen(false)}>
                  <RouterNav className="nav-link" to="/profile/wishlist">
                    WISHLIST
                  </RouterNav>
                </NavItem>
                <NavItem onClick={() => setIsOpen(false)}>
                  <RouterNav className="nav-link" to="/profile/myenquiry">
                    MY ENQUIRIES
                  </RouterNav>
                </NavItem>
                <NavItem onClick={() => setIsOpen(false)}>
                  <RouterNav className="nav-link" to="/profile/saveaddresses">
                    SAVED ADDRESS
                  </RouterNav>
                </NavItem>
                <NavItem onClick={() => setIsOpen(false)}>
                  <RouterNav className="nav-link" to="/help-center">
                    HELP CENTER
                  </RouterNav>
                </NavItem>
                <NavItem onClick={openLogoutModal}>
                  <NavLink>LOGOUT</NavLink>
                </NavItem>
              </>
            ) : null}
          </Nav>
        </Collapse>
      </Navbar>
      <MobileSideNav data={isOpen} />
    </header>
  );
}
function MobileSideNav(props) {
  const isMobileNAv = props.data;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [MobileUserNav, setMobileUserNav] = useState(false);
  const [SearchDropDown, setSearchDropDown] = useState(false);
  const history = useHistory();

  const MobileNAvClickProfile = () => {
    setMobileUserNav(!MobileUserNav);
  };

  const OpenSearchDropDown = () => {
    setSearchDropDown(!SearchDropDown);
  };
  return (
    <div className={isOpen ? "yt-only-mobile-vw tr" : "yt-only-mobile-vw "}>
      <div className="yt-mobile-side-nav-wrap">
        <div className="yt-mobile-side-inner-content">
          <div className="yt-inner-cnt-logo">
            <div className="logobox-mb">
              <img
                src={require("./images/Logo@3x.png")}
                className="logoimage"
                alt="pooja-duggal-fine-jewels"
              />
            </div>
          </div>
          <div className="yt-inner-cnt">
            {MobileUserNav ? (
              <div className="yt-mb-user-profile d-flex">
                <div
                  className="yt-mb-nav-login-btn"
                  onClick={MobileNAvClickProfile}
                >
                  Login / Signup
                </div>
              </div>
            ) : (
              <div className="yt-mb-user-profile d-flex">
                <div className="yt-header-user-img" />
                <div
                  className="yt-head-user-det"
                  onClick={MobileNAvClickProfile}
                >
                  <div className="yt-head-name">Sahil Pandita</div>
                  <div className="yt-head-email">sahilhost0h@gmail.com</div>
                </div>
              </div>
            )}
            {MobileUserNav && (
              <div className="yt-mb-innet-search">
                <div className="yt-mb-side-srch header-search-field-wrap">
                  <img
                    src={require("./images/magnifying-glass@3x.png")}
                    alt="search"
                    className="searchicon"
                  />
                  <input
                    type="text"
                    placeholder="Search Products..."
                    className="searchinput"
                    onClick={OpenSearchDropDown}
                  />
                  {SearchDropDown ? <SearchData /> : ""}
                </div>
              </div>
            )}
          </div>
          <div className="yt-mobile-nav-content">
            <Navbar color="light" light expand="md">
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavLink>Wishlist</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>My Orders</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>Saved Addresses</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>Connected Accounts</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>Help Center</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>Logout</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
