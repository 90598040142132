import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { Formik, Field, Form } from "formik";
import * as validationSchemas from "../../../validationSchemas";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from "react-redux";
import { postUpdate } from "../../../Barriers/apiHelper";
import { useHistory } from "react-router";
import commands from "../../../commands";
import { AllModal } from "../../../components";

function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}

function EnquirenowModal(props) {
  console.log(props);
  const history = useHistory();
  const [modal, setModal] = useState(true);
  const [required, setRequired] = useState("");
  const [values, setValues] = useState(undefined);
  const [selectAddress, setSelectAddress] = useState(false);
  const [myAddress, setMyAddress] = useState({});
  const openSelectAddress = () => setSelectAddress(!selectAddress);
  const toggle = () => setModal(!modal);
  const userData = useSelector((state) => state.logInReducer);
  const initialValues = {
    bhouseNumber: myAddress?.flat_no || "",
    baddressLine1: myAddress?.address || "",
    baddressLine2: myAddress?.address_line_2 || "",
    bcity: myAddress?.city || "",
    bstate: myAddress?.state || "",
    bcountry: myAddress?.country || "",
    bphone: myAddress?.phone_number || "",
    bpincode: myAddress?.zip_code || "",
    emessage: "",
  };

  const addAddress = async (values) => {
    let address =
      "House No : " +
      values.bhouseNumber +
      ", Address 1 : " +
      values.baddressLine1 +
      ", Address 2 : " +
      values.baddressLine2 +
      ", City : " +
      values.bcity +
      ", State : " +
      values.bstate +
      ", Country :" +
      values.bcountry +
      ", Pincode :" +
      values.bpincode +
      ", Phone : " +
      values.bphone;
    let product_id = props?.mydata.id;
    let message = values.emessage;
    const enquire = await commands.products.postenquireProduct({
      product_id,
      address,
      message,
      onAddAddressSuccess,
      onAddAddressFailure
    });
  
  };
  const onAddAddressSuccess = () => {
    history.push(`/enquiryproceeded`)
    toggle()
  };

  const onAddAddressFailure = () => {console.log("NOTPASSED")};

  const onContinueAddressModal = ({ type, address, setFieldValue, values }) => {
    (initialValues.bhouseNumber = address.flat_no),
      (initialValues.baddressLine1 = address.address),
      (initialValues.baddressLine2 = address.address_line_2),
      (initialValues.bcity = address.city),
      (initialValues.bstate = address.state),
      (initialValues.bcountry = address.country),
      (initialValues.bphone = address.phone_number),
      (initialValues.bpincode = address.zip_code),
      setSelectAddress(false);
    setMyAddress(address);
  };
  return selectAddress ? (
    <AllModal
      modalName="selectaddress"
      isOpen={selectAddress}
      toggle={() => setSelectAddress(!selectAddress)}
      onContinue={onContinueAddressModal}
      setFieldValue={setValues}
      values={values}
      type="billing"
    />
  ) : (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="cm-small-modal-6">
        <ModalHeader toggle={toggle} className="add-addr-title-bar p-4">
          <span>Help us know you better</span>
        </ModalHeader>
        <ModalBody className="py-4 px-5 yt-add-modal-body">
        <span className="select-address" onClick={openSelectAddress}>
              Select from Existing Address
            </span>
          <div className="ad-addr-body-wrap">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => addAddress(values)}
              validationSchema={validationSchemas.enquiryForm}
            >
              {({ values, errors, setFieldTouched, touched, handleChange }) => (
                <Form className="yt-fp-form">
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">
                        Flat / House / Apartment No.
                      </span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="bhouseNumber"
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("bhouseNumber")}
                      />
                      <FieldError
                        error={errors.bhouseNumber}
                        touched={touched.bhouseNumber}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">Address Line 1</span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="baddressLine1"
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("baddressLine1")}
                      />
                      <FieldError
                        error={errors.baddressLine1}
                        touched={touched.baddressLine1}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">Address Line 2</span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="baddressLine2"
                        id="baddressLine2"
                        onChange={handleChange}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">City</span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="bcity"
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("bcity")}
                      />
                      <FieldError
                        error={errors.bcity}
                        touched={touched.bcity}
                      />
                    </div>
                  </FormGroup>
                  <Row form className="justify-content-between">
                    <Col md={5} className="cm-edit-add-col">
                      <FormGroup>
                        <div>
                          <span className="profile-form-tag">State</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bstate"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("bstate")}
                          />
                          <FieldError
                            error={errors.bstate}
                            touched={touched.bstate}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6} className="cm-edit-add-col">
                      <FormGroup>
                        <div>
                          <span className="profile-form-tag">Country</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bcountry"
                            onBlur={() => setFieldTouched("bcountry")}
                            onChange={handleChange}
                          />
                          <FieldError
                            error={errors.bcountry}
                            touched={touched.bcountry}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={5} className="cm-edit-add-col">
                      <FormGroup className="yt-number-filed">
                        <div>
                          <span className="profile-form-tag">Postal Code</span>
                          <Field
                            className="py-2 form-control"
                            type="text"
                            name="bpincode"
                            onBlur={() => setFieldTouched("bpincode")}
                            onChange={handleChange}
                          />
                          <FieldError
                            error={errors.bpincode}
                            touched={touched.bpincode}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6} className="cm-edit-add-col">
                      <FormGroup className="yt-number-filed">
                        <div>
                          <span className="profile-form-tag">Phone No</span>
                          <Field
                            className="py-2 form-control"
                            type="number"
                            name="bphone"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("bphone")}
                          />
                        </div>
                        <FieldError
                          error={errors.bphone}
                          touched={touched.bphone}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <div>
                      <span className="profile-form-tag">
                        Drop a message about your requirements
                      </span>
                      <Field
                        className="py-2 form-control"
                        type="text"
                        name="emessage"
                        onChange={handleChange}
                        onBlur={() => setFieldTouched("emessage")}
                      />
                      <FieldError
                        error={errors.emessage}
                        touched={touched.emessage}
                      />
                    </div>
                  </FormGroup>
                  {required.length != 0 ? (
                    <span className="invalid_error">
                      Please Enter {required} Fields
                    </span>
                  ) : (
                    <></>
                  )}
                  <Button
                    color="secondary add-addr-btn py-3"
                    onClick={() => addAddress(values)}
                    block
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default EnquirenowModal;
