import React, { useState } from "react";
import "./css/index.scoped.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function RemoveFromCart(props) {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const ConfirmRemove = () => {
    setTimeout(function() {
      setModal(!modal);
      if (props.onConfirm){
        props.onConfirm();
      }        
      return "Deleted";
    }, 500);
  };

  const CloseDialog = () => {
    if (props.onClosed) {
        props.onClosed();
    }
  }
  return (
    <div className="cm-main-modal-wrapper">
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="cm-small-modal-4"
        centered={true}
        modalClassName="popopop"
      >
        <ModalHeader
          toggle={toggle}
          className="remove-cart-list-title-bar  border-0"
        >
          <span>Cart Item</span>
        </ModalHeader>
        <ModalBody className="py-4">
          <div className="text-center remove-cart-list-body-text pt-4">
            Are you sure you want to remove this product from the cart?
          </div>
        </ModalBody>
        <ModalFooter className="remove-cart-list-bottom-bar p-1 d-flex">
          <Button
            color="secondary pp-remove-cart-list-btn-modal p-3 pp-remove-cart-list-btn-dark-grey"
            onClick={ConfirmRemove}
            block
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default RemoveFromCart;
