/* global FB */
import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { useHistory, NavLink } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Button, FormGroup } from "reactstrap";
import { FaFacebookF, FaRegEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import { loginValidation } from "../Validation schemas/loginValidation";
import { userSocialLogin } from "../../redux/actions/loginActions";
import { get, post } from "../../Barriers/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserSuccess } from "../../redux/actions/loginActions";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../../config/firebase.config";
import language from "../../language/language";
import refreshCart from "../../commands/cart/refresh-cart";
import createCart from "../../commands/cart/create-cart";

function LoginPage(props) {
  const [loginError, setLoginError] = useState("");
  const [resData, setuserResData] = useState("");
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const showPassword = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  const state = useSelector((state) => state.logInReducer);
  const UUID = state.uuId;

  useEffect(() => {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      FB.init({
        appId: "1234953260198976",
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });
      FB.AppEvents.logPageView();
      FB.Event.subscribe("auth.statusChange", function (response) {
        if (response.authResponse) {
          checkLoginState();
        } else {
          console.log("---->User cancelled login or did not fully authorize.");
        }
      });
    };
  }, [firebase]);

  function statusChangeCallback(response) {
    if (response.status === "connected") {
      const data = {
        access_token: response.authResponse.accessToken,
        loginType: 2,
        grant_type: "password",
        provider: "facebook",
      };
      let data1;
      try {
        data1 = dispatch(
          userSocialLogin(data, socialLoginSuccess, socialLoginFailure)
        );
      } catch (err) {
        setLoginError("Error while login with Facebook");
        // console.log("Error: ", err);
      }
    } else if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      setLoginError("Error while login with Facebook");
    } else {
      setLoginError("Error while login with Facebook");
    }
  }

  function checkLoginState() {
    FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  }

  function logInWithFaceBook() {
    FB.login(checkLoginState());
  }
  // useEffect(() => {}, [resData, loginError, showPass]);

  async function signinUser(values) {
    // console.log(values,"VALUES")
    setLoginError("");
    const data = {
      email: values.Email,
      password: values.Password,
      grant_type: "password",
      loginType: 1,
      device_token: "",
    };
    post(`/oauth/token?uuid=${UUID}`, data)
      .then((res) => {
        // console.log(res,"MYRES");
        dispatch(fetchUserSuccess(res?.data?.data));
      })
      .then(() => {
        createCart();
      })
      .then(() => {
        let qparams = new URLSearchParams(window.location.search);
          setTimeout(
            () => window.location.replace(qparams.get('redirect') || '/'),
            500
          );
        
      })
      .catch((err) => {
        if (err.request.response && err.request.status !== 0) {
          let response = JSON.parse(err.request.response);
          setLoginError(response.error);

          if (response.error.includes("confirm")) {

            var qparams = new URLSearchParams(window.location.search);

            if (qparams.get("redirect")) {

              history.push({
                pathname: "/loginconfirm",
                search: "?redirect=" + qparams.get("redirect"),
                state: {
                  data: { email: values.Email },
                },
              });
            } else {
              history.push({
                pathname: "/loginconfirm",
                state: {
                  data: { email: values.Email },
                },
              });
            }
          }
        } else {
          setLoginError(err?.response?.data?.error)
        }
      });
  }

  function logInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    let accessToken;
    provider.addScope("profile");
    provider.addScope("email");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token.
        accessToken = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // console.log(accessToken);
        const data = {
          access_token: accessToken,
          loginType: 2,
          grant_type: "password",
          provider: "google",
        };
        let data1;
        try {
          data1 = dispatch(
            userSocialLogin(data, socialLoginSuccess, socialLoginFailure)
          );
        } catch (err) {
          setLoginError("Error while login with Google");
          // console.log("Error: ", err);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const socialLoginSuccess = (res) => history.push("/");

  const socialLoginFailure = (err) => {
    setLoginError(err);
    // console.log(err);
  };

  return (
    <div className="signup-wrapper">
      <h1 className="tagline-title">Welcome Back</h1>
      <h2 className="tagline">Sign in with your email and password.</h2>
      <div className="yt-login-form mb-4">
        <Formik
          initialValues={{ Email: "", Password: "" }}
          onSubmit={signinUser}
          validationSchema={loginValidation}
        >
          {({ errors, touched }) => {
            return (
              <Form className="sign-up-form">
                <FormGroup>
                  {/* <img alt="Email Icon" src={require("./images/email.svg")} /> */}
                  <Field
                    name="Email"
                    type="text"
                    placeholder="Email"
                    className={`form-control ${((touched.Email &&
                      errors.Email) ||
                      loginError) &&
                      "border border-danger"}`}
                  />
                  {touched.Email && errors.Email ? (
                    <p className="error">{errors.Email}</p>
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </FormGroup>

                <FormGroup className="mb-4">
                  {/* <img alt="Password" src={require("./images/key.svg")} /> */}
                  <Field
                    name="Password"
                    type={showPass ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    className={`form-control ${((touched.Password &&
                      errors.Password) ||
                      loginError) &&
                      "border border-danger"}`}
                  />

                  {showPass ? <FaEyeSlash
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showPassword}
                  /> :
                    <FaEye
                      className="eye-icon"
                      color="#989CA2"
                      onClick={showPassword}
                    />}
                  {touched.password && errors.password ? (
                    <p className="error">{errors.password}</p>
                  ) : loginError ? (
                    <p className="error text-center">{loginError}</p>
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </FormGroup>
                <NavLink to="/forgotpassword" className="forgot-password">
                  {language.auth.forgotPassword}
                </NavLink>
                <Button
                  type="submit"
                  variant="flat"
                  className="btn-sign-up"
                  block
                >
                  SIGN IN
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* <div className="other-actions">
        <a href="/forgotpassword" className="forgot-password">
          {language.auth.forgotPassword}
        </a>
        <p className="skip-line-2">or Continue via</p>
        <div className="login-action">
          <Button onClick={logInWithFaceBook} className="fb-button mr-5">
            <FaFacebookF className="mr-2" /> {language.auth.facebook}
          </Button>
          <Button onClick={logInWithGoogle} className="google-button">
            <FaRegEnvelope className="mr-2" /> {language.auth.google}
          </Button>
        </div>
        <p className="terms-tagline">{language.auth.tandc}</p>
        <p className="terms">
          <a href="#">{language.footer.t_and_c}</a>
          <span>&</span>
          <a href="#">{language.footer.privacy_policy}</a>
        </p>
        <NavLink to="/shop" className="skip-line-1">
          {language.auth.skipAndContinue}
        </NavLink>
      </div> */}
    </div>
  );
}
export default LoginPage;
