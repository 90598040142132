import React, { useEffect, useState } from "react";
import "./css/index.scoped.css";
import { Button, Modal, Form, FormGroup, FormLabel } from "react-bootstrap";
import { FiX } from "react-icons/fi";
import * as Yup from "yup";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import ChangePasswordSuccess from "../ChangePasswordSuccess";
import { useSelector } from "react-redux";
import { putUpdate } from "../../../Barriers/apiHelper";
import { FaEye,FaEyeSlash } from "react-icons/fa";

const changePasswordSchema = Yup.object({
  currentPassword: Yup.string().required("Field is required"),
  newPassword: Yup.string().required("Field is required"),
  reEnterPassword: Yup.string()
    .required("Field is required")
    .oneOf([Yup.ref("newPassword"), null], "Password Missmatch !"),
});

const ChangePassword = ({ toggleFn }) => {
  const [showPass, setShowPass] = useState(false);
  const[showCurrentPass,setCurrentPass] = useState(false);
  const[showRePass,setRePass] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const state = useSelector((state) => state.logInReducer);
  useEffect(() => {
    if (Object.keys(state.loginData).length > 0) {
      setAccessToken(state.loginData);
    }
  }, [state]);

  const [changePasswordState] = useState({
    currentPassword: "",
    newPassword: "",
    reEnterPassword: "",
  });

  const showCurrentPassword = (e) => {
    e.preventDefault();
    setCurrentPass(!showCurrentPass);
  };
  const showNewPassword = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };
  const showRePassword = (e) => {
    e.preventDefault();
    setRePass(!showRePass);
  };

  const changeUserPassword = (values) => {
      let userId = accessToken.user.id;
      let headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            accessToken.token.token_type + " " + accessToken.token.access_token,
        },
      };
      let data = {
        user: {
          current_password: values.currentPassword,
          password: values.newPassword,
        },
      };
      putUpdate(`/users/${userId}/update_password`, data, headers)
        .then((res) => {
          setShowSuccessModal(true);
        })
        .catch((err) => {
          if (err.response) {
            setErrorMessage(err.response?.data?.error);
          } else {
            setErrorMessage(err.message || "Unknown Error Occured");
          }
        });
  };

  const closeModals = () => {
    // Note: This callback Fn closes both modals after successful password reset
    setShowSuccessModal(false);
    toggleFn();
  }

  return showSuccessModal ? (
    <ChangePasswordSuccess toggleFn={closeModals} />
  ) : (
    <Modal keyboard centered show onHide={toggleFn} className="custom-modal">
      <Modal.Header className="p-2">
        <Modal.Title className="mx-auto my-2">Change Password</Modal.Title>
        <FiX size="18" className="close-btn" onClick={toggleFn} />
      </Modal.Header>
      <Modal.Body>
        <div className="custom-body">
          <p className="password-hint">
            Enter a password with alphabets A-z and a symbol
          </p>
          <Formik
            enableReinitialize
            validateOnChange
            validateOnBlur
            validationSchema={changePasswordSchema}
            initialValues={changePasswordState}
            onSubmit={changeUserPassword}
          >
            {({ handleSubmit, errors, touched }) => (
              <FormikForm
                className="main-form"
                noValidate
                onSubmit={handleSubmit}
              >
                <FormGroup className="pw_input">
                  <FormLabel className="custom-label">
                    Enter current password
                  </FormLabel>
                  <Field
                    name="currentPassword"
                    type={showCurrentPass ? "text" : "password"}
                    className={`form-control custom-input ${touched.currentPassword &&
                      errors.currentPassword &&
                      "invalid"}`}
                  />
                  {showCurrentPass && (
                    <FaEyeSlash
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showCurrentPassword}
                  />
                  )}
                  {!showCurrentPass &&(
                    <FaEye
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showCurrentPassword}
                  />
                  )

                  }
                  
                  <p className="error">
                    
                    <ErrorMessage name="currentPassword" />
                  </p>
                </FormGroup>

                <FormGroup className="pw_input">
                  <FormLabel className="custom-label">
                    Enter new password
                  </FormLabel>
                  <Field
                    name="newPassword"
                    type={showPass ? "text" : "password"}
                    // type="password"
                    className={`form-control custom-input ${touched.newPassword &&
                      errors.newPassword &&
                      "invalid"}`}
                  />
                  {showPass && (
                    <FaEyeSlash
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showNewPassword}
                  />
                  )}
                  {!showPass &&(
                    <FaEye
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showNewPassword}
                  />
                  )

                  }
                  <p className="error">
                    
                    <ErrorMessage name="newPassword" />
                  </p>
                </FormGroup>

                <FormGroup className="pw_input">
                  <FormLabel className="custom-label">
                    Re-enter new password
                  </FormLabel>
                  <Field
                    name="reEnterPassword"
                    type={showRePass ? "text" : "password"}
                    className={`form-control custom-input ${touched.reEnterPassword &&
                      errors.reEnterPassword &&
                      "invalid"}`}
                  />
                  {showRePass && (
                    <FaEyeSlash
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showRePassword}
                  />
                  )}
                  {!showRePass &&(
                    <FaEye
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showRePassword}
                  />
                  )

                  }
                  <p className="error">
                    
                    <ErrorMessage name="reEnterPassword" />
                  </p>
                  {errorMessage && <p className="validation-error">{errorMessage}</p>}
                </FormGroup>

                <Button variant="flat" type="submit" className="dark-btn" block>
                  Change Password
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
