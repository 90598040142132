import React, { useState } from "react";
import "./css/index.scoped.css";
import { FaCamera } from "react-icons/fa";
import ProfilePics from "./images/user-pics.png";
import RemoveIcn from "./images/close-icn.png";
import noImage from "./images/noImage-min.png";
import { useDispatch } from "react-redux";
import { changeUserState } from "../../../redux/actions/loginActions";
import { putUpdate } from "../../../Barriers/apiHelper";
import { Formik, Field, Form } from "formik";
import AuthState from "../../../redux/states/auth";
import * as validationSchemas from "../../../validationSchemas";
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";
import commands from "../../../commands";

function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}

const EditProfileModal = (props) => {
  const { toggleFn } = props;
  const data = AuthState.get();
  const initialValues = {
    name: props?.userData?.userData.name,
    email: props?.userData?.userData.email,
    phone_number: props?.userData?.userData.phone_number,
    profile_picture: props?.userData?.userData.profile_picture,
  };
  const [isProfilePics, setisProfilePics] = useState(null);
  const [nullProfilepic, setNullProfile] = useState(false);
  const dispatch = useDispatch();

  function openImageUpload() {
    var fileTag = document.getElementById("user_profile_pics");
    fileTag.addEventListener("change", function() {
      changeImage(this);
    });
    fileTag.click();
  }

  function removeImage() {
    setisProfilePics(null);
  }

  function changeImage(input) {
    var reader;
    if (input.files && input.files[0]) {
      reader = new FileReader();
      reader.onload = function(e) {
        console.log(e.target.result);
        setisProfilePics(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  const saveProfile = async (values) => {
    let userDetails = {
      name: values.name,
      email: values.email,
      phone_number: values.phone_number,
      profile_picture: isProfilePics,
    };
    let headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          data.loginData.token.token_type +
          " " +
          data.loginData.token.access_token,
      },
    };
    await putUpdate(`/users/${data.user.id}`, userDetails, headers);
    await dispatch(changeUserState(userDetails));
    window.notify([
      {
        message: "Profile Updated successfully",
        type: "success",
      },
    ]);
    toggleFn();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Modal isOpen={true} toggle={toggleFn}>
        <ModalHeader toggle={toggleFn}>
          <div className="text-center">Edit Profile</div>
        </ModalHeader>
        <ModalBody>
          <div className="edit-profile-body-wrap">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => saveProfile(values)}
              validationSchema={validationSchemas.editProfile}
            >
              {({ values, errors, setFieldTouched, touched, handleChange }) => (
                <Form>
                  <FormGroup row>
                    <Col md={12}>
                      <div className="d-none">
                        <Input
                          type="file"
                          name="user_profile_pics"
                          id="user_profile_pics"
                        />
                      </div>

                      <div className="d-flex align-items-end mb-4 ">
                        {props?.userData?.userData?.profile_picture ? (
                          <>
                            {isProfilePics ? (
                              <>
                                <div className="img-upload d-flex align-items-center justify-content-center">
                                  <img
                                    alt="Profile Pics"
                                    src={isProfilePics}
                                    className="img-fluid myImage"
                                  />
                                  <FaCamera
                                    onClick={openImageUpload}
                                    className="text-white"
                                  />
                                </div>
                                <div
                                  className="yt-remove-pic-wrap ml-4"
                                  onClick={removeImage}
                                >
                                  <img src={RemoveIcn} alt="Remove" />{" "}
                                  <span>Remove Picture</span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="img-upload d-flex align-items-center justify-content-center">
                                  {nullProfilepic === true ? (
                                    <img
                                      alt="Profile Pics"
                                      // src={props.userData.userData.profile_picture}
                                      className="img-fluid myImage"
                                    />
                                  ) : (
                                    <img
                                      alt="Profile Pics"
                                      src={
                                        props.userData.userData.profile_picture
                                      }
                                      className="img-fluid myImage"
                                    />
                                  )}

                                  <FaCamera onClick={openImageUpload} />
                                </div>
                                <div
                                  className="yt-remove-pic-wrap ml-4"
                                  onClick={() => setNullProfile(true)}
                                >
                                  <img src={RemoveIcn} alt="Remove" />{" "}
                                  <span>Remove Picture</span>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {isProfilePics ? (
                              <>
                                <div className="img-upload d-flex align-items-center justify-content-center">
                                  <img
                                    alt="Profile Pics"
                                    src={isProfilePics}
                                    className="img-fluid myImage"
                                  />
                                  <FaCamera
                                    onClick={openImageUpload}
                                    className="text-white"
                                  />
                                </div>
                                <div
                                  className="yt-remove-pic-wrap ml-4"
                                  onClick={removeImage}
                                >
                                  <img src={RemoveIcn} alt="Remove" />{" "}
                                  <span>Remove Picture</span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="img-upload d-flex align-items-center justify-content-center">
                                  {nullProfilepic === true ? (
                                    <img
                                      alt="Profile Pics"
                                      // src={props.userData.userData.profile_picture}
                                      className="img-fluid myImage"
                                    />
                                  ) : (
                                    <img
                                      alt="Profile Pics"
                                      src={noImage}
                                      className="img-fluid myImage"
                                    />
                                  )}

                                  <FaCamera onClick={openImageUpload} />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={12}>
                      <div className="profile-form-field fields-active">
                        <span className="profile-form-tag">Name</span>
                        <Field
                          className="py-3 form-control"
                          type="text"
                          name="name"
                          onChange={handleChange}
                        />
                        <FieldError
                          error={errors.name}
                          touched={touched.name}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="profile-form-field fields-active">
                        <span className="profile-form-tag">Email</span>
                        <Field
                          className="py-3 form-control"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          disabled={initialValues.email}
                        />
                        <FieldError
                          error={errors.email}
                          touched={touched.email}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={12}>
                      <div className="profile-form-field fields-active">
                        <span className="profile-form-tag">Phone No</span>
                        <Field
                          className="py-3 form-control"
                          type="number"
                          name="phone_number"
                          onChange={handleChange}
                          disabled={initialValues.phone_number}
                        />
                        <FieldError
                          error={errors.phone_number}
                          touched={touched.phone_number}
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="m-0">
                    <Button
                      color="secondary profile-edit-profile-btn py-3"
                      block
                      type="submit"
                    >
                      Save Profile
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditProfileModal;
