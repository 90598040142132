import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import commands from "../../../commands";

function EditaddressModal(props) {
  const history = useHistory();

  const [modal, setModal] = useState(true);
  const [deleteorder, SetDelete] = useState(false);
  const [name, setName] = useState(props.record.name);
  const [flatNo, setFlatNo] = useState(props.record.flat_no);
  const [address1, setAddress1] = useState(props.record.address);
  const [address2, setAddress2] = useState(props.record.address_line_2);
  const [city, setCity] = useState(props.record.city);
  const [state, setState] = useState(props.record.state);
  const [country, setCountry] = useState(props.record.country);
  const [pincode, setPincode] = useState(props.record.zip_code);
  const [phone, setPhone] = useState(props.record.phone_number);
  const [checkValidation, setCheckValidation] = useState(true);

  const [required, setRequired] = useState("");
  const toggle = () => setModal(!modal);
  const uuid = useSelector((state) => state.logInReducer.uuId);

  const callMe = (value) => {
    if (value.length > 0) return true;
    else return false;
  };

  const changeFormInput = (e) => {
    let name = e.target.name;
    switch (name) {
      case "name":
        setName(e.target.value);
        return callMe(e.target.value);
      case "flatNo":
        setFlatNo(e.target.value);
        return callMe(e.target.value);
      case "address1":
        setAddress1(e.target.value);
        return callMe(e.target.value);
      case "address2":
        setAddress2(e.target.value);
        return true;
      case "city":
        setCity(e.target.value);
        return callMe(e.target.value);
      case "state":
        setState(e.target.value);
        return callMe(e.target.value);
      case "country":
        setCountry(e.target.value);
        return callMe(e.target.value);
      case "pincode":
        setPincode(e.target.value);
        return callMe(e.target.value);
      case "phone":
        setPhone(e.target.value);
        return callMe(e.target.value);
      default:
        return true;
    }
  };

  const editAddress = () => {
    if (
      name.length != 0 &&
      flatNo.length != 0 &&
      address1.length != 0 &&
      city.length != 0 &&
      state.length != 0 &&
      country.length != 0 &&
      pincode.length != 0 &&
      phone.length != 0
    ) {
      setRequired("");
      let address = {
        name: name,
        flat_no: flatNo,
        address: address1,
        zip_code: pincode,
        phone_number: phone,
        city: city,
        address_line_2: address2,
        state: state,
        country: country,
        is_default: props.record.is_default,
      };
      commands.user.updateAddress(
        address,
        props.record.id,
        onUpdateSuccess,
        onUpdateFailure
      );
    } else {
      setRequired("Required");
      setCheckValidation(false);
    }
  };

  const onUpdateSuccess = () => {
    commands.user.getAddressList(
      () => {
        toggle();
        props.setAddress();
        history.push(`/profile/saveaddresses`);
      },
      () => {}
    );
  };

  const onUpdateFailure = () => {};

  useEffect(() => {}, [checkValidation]);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className="cm-small-modal-6">
        <ModalHeader toggle={toggle} className="edit-add-addr-title-bar p-4">
          <span>Edit Address</span>
        </ModalHeader>
        <ModalBody className="py-4 px-5 yt-edit-add-body">
          <div className="edit-add-addr-body-wrap">
            <Form className="yt-fp-form">
              <Row form className="justify-content-between">
                <Col md={12} className="cm-edit-add-col">
                  <FormGroup>
                    <div
                      className={
                        name.length > 0 || checkValidation
                          ? "profile-form-field fields-active"
                          : "profile-form-field fields-active error"
                      }
                    >
                      <span className="profile-form-tag">Name</span>
                      <Input
                        className="py-2"
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => changeFormInput(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md={12} className="cm-edit-add-col">
                  <FormGroup>
                    <div
                      className={
                        flatNo.length > 0 || checkValidation
                          ? "profile-form-field fields-active"
                          : "profile-form-field fields-active error"
                      }
                    >
                      <span className="profile-form-tag">
                        Flat / House / Apartment No.
                      </span>
                      <Input
                        className="py-2"
                        type="text"
                        name="flatNo"
                        id="flat"
                        value={flatNo}
                        onChange={(e) => changeFormInput(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <div
                  className={
                    address1.length > 0 || checkValidation
                      ? "profile-form-field fields-active"
                      : "profile-form-field fields-active error"
                  }
                >
                  <span className="profile-form-tag">Address Line 1</span>
                  <Input
                    className="py-2"
                    type="text"
                    name="address1"
                    id="address1"
                    value={address1}
                    onChange={(e) => changeFormInput(e)}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="profile-form-field fields-active">
                  <span className="profile-form-tag">Address Line 2</span>
                  <Input
                    className="py-2"
                    type="text"
                    name="address2"
                    id="address2"
                    value={address2}
                    onChange={(e) => changeFormInput(e)}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div
                  className={
                    city.length > 0 || checkValidation
                      ? "profile-form-field fields-active"
                      : "profile-form-field fields-active error"
                  }
                >
                  <span className="profile-form-tag">City</span>
                  <Input
                    className="py-2"
                    type="text"
                    name="city"
                    id="city"
                    value={city}
                    onChange={(e) => changeFormInput(e)}
                  />
                </div>
              </FormGroup>
              <Row form className="justify-content-between">
                <Col md={12} className="cm-edit-add-col">
                  <FormGroup>
                    <div
                      className={
                        state.length > 0 || checkValidation
                          ? "profile-form-field fields-active"
                          : "profile-form-field fields-active error"
                      }
                    >
                      <span className="profile-form-tag">State</span>
                      <Input
                        className="py-2"
                        type="text"
                        name="state"
                        id="state"
                        value={state}
                        onChange={(e) => changeFormInput(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md={12} className="cm-edit-add-col">
                  <FormGroup>
                    <div
                      className={
                        country.length > 0 || checkValidation
                          ? "profile-form-field fields-active"
                          : "profile-form-field fields-active error"
                      }
                    >
                      <span className="profile-form-tag">Country</span>
                      <Input
                        className="py-2"
                        type="text"
                        name="country"
                        id="country"
                        value={country}
                        onChange={(e) => changeFormInput(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md={12} className="cm-edit-add-col">
                  <FormGroup className="yt-number-filed">
                    <div
                      className={
                        pincode.length > 0 || checkValidation
                          ? "profile-form-field fields-active"
                          : "profile-form-field fields-active error"
                      }
                    >
                      <span className="profile-form-tag">Postal Code</span>
                      <Input
                        className="py-2 "
                        type="text"
                        name="pincode"
                        id="pincode"
                        value={pincode}
                        onChange={(e) => changeFormInput(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md={12} className="cm-edit-add-col">
                  <FormGroup className="yt-number-filed">
                    <div
                      className={
                        phone.length > 0 || checkValidation
                          ? "profile-form-field fields-active"
                          : "profile-form-field fields-active error"
                      }
                    >
                      <span className="profile-form-tag">Phone No</span>
                      <Input
                        className="py-2"
                        type="number"
                        name="phone"
                        id="phone"
                        value={phone}
                        onChange={(e) => changeFormInput(e)}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              {required.length != 0 ? (
                <span className="invalid_error">
                  Please Enter {required} Fields
                </span>
              ) : (
                <></>
              )}
              <Button
                color="secondary edit-addr-btn py-3"
                onClick={editAddress}
                block
              >
                Update Address
              </Button>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
export default EditaddressModal;
