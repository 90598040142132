import React, { useState, useEffect } from "react";
import { Button, FormGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import newPassValidation from "../Validation schemas/newPassValidation";
import { FaEye,FaEyeSlash } from "react-icons/fa";
import "./css/index.scoped.css";
import { put } from "../../Barriers/apiHelper";
import { withRouter } from "react-router-dom";
import language from "../../language/language";
function NewPassword(props) {
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setConfirmPass] = useState(false);
  const [message, setMessage] = useState("");
  const [invalidPass, setInvalidPass] = useState("");
  const history = useHistory()
  const setNewPass = (values) => {
    let data = {
      user: {
        reset_password_token: props.token,
        password: values.password,
      },
    };
    put(`/users/password`, data)
      .then((res) => {
        setMessage(res.data.message);
        setInvalidPass("");

        setTimeout(() => {
          history.push("/login");
        }, 2000);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        setInvalidPass(err.response.data.error);
      });
  };

  const showPassword = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  const showConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPass(!showConfirmPass);
  };

  return (
    <div className="yt-fp-form">
      <Formik
        initialValues={{ password: "", confirmpassword: "" }}
        onSubmit={setNewPass}
        validationSchema={newPassValidation}
      >
        {({ errors, touched }) => {
          if (
            (touched.password && errors.password) ||
            (touched.confirmpassword && errors.confirmpassword)
          ) {
            setInvalidPass("");
          }
          return (
            <Form>
              <FormGroup className="">
                {/* <img
                  alt="Password Icon"
                  src={require("./images/key-icn.png")}
                  className={
                    (errors.password && touched.password) || invalidPass
                      ? " yt-fp-icn2"
                      : "yt-fp-icn"
                  }
                /> */}
                <Field
                  name="password"
                  type={showPass ? "text" : "password"}
                  id="password"
                  placeholder="New Password"
                  className={
                    "form-control" +
                    (errors.password && touched.password
                      ? " is-invalid invalid-input"
                      : "")
                  }
                />
                {touched.password && errors.password ? (
                  <></>
                ) : (
                  <>
                  {showPass ? <FaEyeSlash
                    onClick={showPassword}
                    className={
                      invalidPass != ""
                        ? "yt-forgot-pass-vie-icn2"
                        : "yt-forgot-pass-vie-icn"
                    }
                  /> : <FaEye
                    onClick={showPassword}
                    className={
                      invalidPass != ""
                        ? "yt-forgot-pass-vie-icn2"
                        : "yt-forgot-pass-vie-icn"
                    }
                  />}
                  </>
                  
                )}
                {touched.password && errors.password && (
                  <span className="invalid-feedback">{errors.password}</span>
                )}
              </FormGroup>
              <FormGroup className="">
                {/* <img
                  alt="Password Icon"
                  src={require("./images/key-icn.png")}
                  className={
                    (errors.confirmpassword && touched.confirmpassword) ||
                    invalidPass
                      ? " yt-fp-icn1"
                      : "yt-fp-icn2"
                  }
                /> */}
                <Field
                  name="confirmpassword"
                  type={showConfirmPass ? "text" : "password"}
                  id="password"
                  placeholder="Confirm Password"
                  className={
                    "form-control" +
                    (errors.confirmpassword && touched.confirmpassword
                      ? " is-invalid invalid-input"
                      : "")
                  }
                />
                <p className="yt-pass-info">
                    {language.auth.passwordRule}
                </p>
                {message != "" && <p className="pass_success">{message}</p>}
                {touched.confirmpassword && errors.confirmpassword ? (
                  <></>
                ) : (
                  <>
                   {showConfirmPass?<FaEyeSlash
                    onClick={showConfirmPassword}
                    className={"yt-forgot-pass-vie-icn2"}
                  /> : <FaEye
                    onClick={showConfirmPassword}
                    className={"yt-forgot-pass-vie-icn2"}
                  />}
                  
                  </>
                 
                )}
                {touched.confirmpassword && errors.confirmpassword && (
                  <span className="invalid-feedback">
                    {errors.confirmpassword}
                  </span>
                )}
                {invalidPass && (
                  <span className="invalid">{invalidPass}</span>
                )}

              </FormGroup>
              <Button type="submit" color=" yt-cp-btn" block>
                {language.auth.resetPassword}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(NewPassword);
