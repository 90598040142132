import React from "react";
import "./css/index.scoped.css";
import { Footer, ContactForm, FeatureBar, Header } from "../../components";
import ProductReview from "../../components/sitereview";
import { BannerHomeTwo } from "../home2";
import ScrollToTop from "react-scroll-to-top";

function ContactUs(props) {
  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      <ContactForm />
      <BannerHomeTwo />
      <ProductReview />
      <FeatureBar />
      <Footer />
    </div>
  );
}

export default ContactUs;
