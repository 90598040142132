import React from "react";
import "./index.scoped.css";
import { Modal, ModalBody, Button } from "react-bootstrap";

const ChangePasswordSuccess = ({ toggleFn }) => {

  return (
    <Modal
      keyboard
      centered
      show
      onHide={toggleFn}
			dialogClassName="custom-modal"
    >
      <ModalBody className="custom-body">
				<img src={require("./success.png")} alt="success" />
				<h1>Password changed successfully !</h1>
				<p>Yo can go back and continue browsing products <br />Enjoy Shopping !</p>
				<Button onClick={toggleFn} variant="flat" className="dark-btn">Go to Profile</Button>
			</ModalBody>
    </Modal>
  );
};

export default ChangePasswordSuccess;
