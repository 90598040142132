import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { AllModal } from "../../../components";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Input,
  Button,
  Table,
} from "reactstrap";
import _ from "lodash";
import moment from "moment";
import service, { serviceTypes } from "../../../services";
import ReactPaginate from "react-paginate";

function SingleOrders(props) {
  // useEffect(() => {
  //   fetchMyorder();
  // }, []);
  const history = useHistory();
  const [ShowCOModal, settt] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [ShowPRModal, pRsettt] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  // const [paginationData, setPaginationdata] = useState([]);

  const { enquiry } = props;
  // const fetchMyorder = () => {
  //   const serviceType = serviceTypes.getMyOrdersList(props?.user?.id);
  //   service(serviceType).then(({ response, error }) => {
  //     if (error) {
  //       console.log(error, "MINEERROR");
  //     }
  //     console.log(response?.data, "MIMETM");
  //     setPaginationdata(response?.data);
  //   });
  // };

  return (
    <div className="profile-pg-inner-wrap bg-white radius-10 mb-4 yt-my-order-wrap yt-cc-ord">
      <div className="profile-pg-inner-wrapper">
        <div className="d-flex flex-wrap align-items-center justify-content-between yt-my-order-tdet-wrap">
          <div className="d-flex align-items-center flex-wrap profile-mo-dt-wrap">
            <div className="order-number-wrap">
              <span className="order-tag">Enquiry Number : </span>
              <span className="order-tag-val">{enquiry?.id}</span>
            </div>
            <div className="order-bdr-between" />
            <div className="order-date-wrap">
              <span className="order-tag">Enquried on : </span>
              <span className="order-tag-val">
                {moment(enquiry?.created_at).format("Do MMM YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="py-3 d-flex align-items-center yt-order-wrapper-box ">
          <div className="od-product-img p-1">
            <img
              src={enquiry?.product?.images[0]?.image}
              className="img-fluid"
              alt="pooja duggal"
            />
          </div>
          <div className="d-flex align-items-center">
            <div className="order-product-info">
              <h2 className="pp-order-product-ttl mt-0">
                {enquiry?.product?.name}
              </h2>
              <Table className="mb-0 order-prodict-type d-block" borderless>
                <thead>
                  <tr>
                    <th>Your Message</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{enquiry?.message}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="order-product-quanity text-center text-sm-right">
            <ul className="p-0 order-ul-list-none m-0 yt-qt-prc d-flex flex-wrap">
              {/* <li className="op-order-quantity mb-3">
                Address<span className="ord-product-quantity" />
              </li> */}
              <li className="op-order-product-price align-self-end">
                <span className="order-product-price" onClick={()=>history.push(`/shop/${enquiry?.product?.id}`)}>View Product</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
function EnquiryDetails(props) {
  const [paginationData, setPaginationdata] = useState([]);
  const [myPage, setPage] = useState(1);
  // const fetchMyorder = (val) => {
  //   const serviceType = serviceTypes.getEnquiries(props?.user?.id);
  //   const qparams = new URLSearchParams(window.location.search);
  //   {
  //     val
  //       ? (serviceType.params.page = val)
  //       : (serviceType.params.page = myPage);
  //   }

  //   serviceType.params.per_page = 10;
  //   service(serviceType).then(({ response, error }) => {
  //     if (error) {
  //       console.log(error, "MINEERROR");
  //     }
  //     setPaginationdata(response?.data);
  //   });
  // };
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   fetchMyorder();
  // }, [props]);

  return (
    <div className="order-main-wrapper">
      {/* {props.order.map((order, index) => ( */}
      {props?.enquiry?.map((order, index) => (
        <SingleOrders {...props} enquiry={order} key={index} />
      ))}

      {/*{paginationData?.meta?.pagination?.total_count >= 10 ? (
        <>
          <div id="pagination">
            <ReactPaginate
              previousLabel={"<<<"}
              nextLabel={">>>"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={paginationData?.meta?.pagination?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(val) => {
                // if ("URLSearchParams" in window) {
                //   var searchParams = new URLSearchParams(
                //     window.location.search
                //   );
                //   searchParams.set("page", val.selected + 1);
                //   console.log("Newton ", searchParams.toString());
                //   props.history.push("/shop?" + searchParams.toString());
                // }
                // setPage( val.selected + 1)
                fetchMyorder(val.selected + 1);
                // setPaginationData({
                //   ...paginationData,
                //   current_page: val.selected + 1,
                // });
              }}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </>
      ) : (
        <></>
      )}*/}
    </div>
  );
}

function NoEnquiry() {
  const history = useHistory();
  const routeToshop = () => {
    let path = "/shop";
    history.push(path);
  };

  return (
    <div className="profile-pg-inner-wrap profile-pg-inner-no-order p-3 bg-white radius-10 mb-4">
      <div className="profile-pg-inner-wrapper">
        <div className="profile-pg-order-main-wrap text-center ">
          <img
            src={require("./images/no-order-icn.png")}
            className="img-fluid  mb-5"
          />
          <div className="pp-sa-order-wrap mb-5 mt-2">
            <h2 className="pp-od-no-ttl mt-0 mb-3">No Enquiries Found !</h2>
            <p className="pp-od-no-text mb-0">
              You haven’t enquired any items, Browse items and enquire them.
            </p>
          </div>
          <Button color=" pp-no-order-btn py-3 px-3" onClick={routeToshop}>
            Browse Products
          </Button>
        </div>
      </div>
    </div>
  );
}

function MyEnquiryPage(props) {
  return (
    <>
      {props.enquiry && props.enquiry.length > 0 ? (
        <EnquiryDetails order={props.enquiry} {...props} />
      ) : (
        <NoEnquiry />
      )}
    </>
  );
}
export default MyEnquiryPage;
