import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import { AllModal } from "../../../components";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Input,
  Button,
  Table,
} from "reactstrap";
import _ from "lodash";
import service, { serviceTypes } from "../../../services";
import ReactPaginate from "react-paginate";

// function MultipleOrders() {
//   const history = useHistory();
//   const [ShowCOModal, settt] = useState(false);
//   const [ShowPRModal, pRsettt] = useState(false);
//   const openProductRatingModal = () => pRsettt(!ShowPRModal);
//   const [reviewData, setReviewData] = useState({});
//   const openCancelOrderModal = () => settt(!ShowCOModal);
//   const routeToOrderDetails = () => {
//     let path = "myorder/orderdetails";
//     history.push(path);
//   };
//   return (
//     <div className="profile-pg-inner-wrap bg-white radius-10 mb-4 yt-my-order-wrap">
//       <div className="profile-pg-inner-wrapper">
//         {ShowCOModal ? <AllModal modalName="CancelOrder" /> : ""}
//         {ShowPRModal ? <AllModal modalName="Productrate" /> : ""}
//         <div className="d-flex flex-wrap align-items-center justify-content-between yt-my-order-tdet-wrap">
//           <div className="d-flex align-items-center flex-wrap profile-mo-dt-wrap">
//             <div className="order-number-wrap">
//               <span className="order-tag">Order Number :</span>
//               <span className="order-tag-val">4987984949</span>
//             </div>
//             <div className="order-bdr-between" />
//             <div className="order-date-wrap">
//               <span className="order-tag">Ordered on </span>
//               <span className="order-tag-val">12 Jun 2020</span>
//             </div>
//           </div>
//           <div className="order-review text-right">
//             <Button
//               color="link order-write-review"
//               onClick={openProductRatingModal}
//             >
//               Write a Review
//             </Button>
//           </div>
//         </div>
//         <div className="py-3 d-flex align-items-center yt-order-wrapper-box">
//           <div className="od-product-img p-1">
//             <img
//               src={require("./images/order-product-img.png")}
//               className="img-fluid"
//             />
//           </div>
//           <div className="d-flex align-items-center">
//             <div className="order-product-info">
//               <h2
//                 className="pp-order-product-ttl mt-0"
//                 onClick={routeToOrderDetails}
//               >
//                 Beautiful Embroidered Yoke Brown Abaya
//               </h2>
//               <Table className="mb-0 order-prodict-type d-block" borderless>
//                 <thead>
//                   <tr>
//                     <th>COLOR</th>
//                     <th>SIZE</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Dark Blue</td>
//                     <td>XS</td>
//                   </tr>
//                 </tbody>
//               </Table>
//             </div>
//           </div>
//           <div className="order-product-quanity text-center text-sm-right">
//             <ul className="p-0 order-ul-list-none m-0 yt-qt-prc d-flex flex-wrap">
//               <li className="op-order-quantity mb-3">
//                 Quantity : <span className="ord-product-quantity">1</span>
//               </li>
//               <li className="op-order-product-price align-self-end">
//                 <span className="order-product-price">$120</span>
//               </li>
//             </ul>
//           </div>
//         </div>
//         <div className="py-3 d-flex align-items-center yt-order-wrapper-box">
//           <div className="od-product-img p-1">
//             <img
//               src={require("./images/order-product-img.png")}
//               className="img-fluid"
//             />
//           </div>
//           <div className="d-flex align-items-center">
//             <div className="order-product-info">
//               <h2
//                 className="pp-order-product-ttl mt-0"
//                 onClick={routeToOrderDetails}
//               >
//                 Beautiful Embroidered Yoke Brown Abaya
//               </h2>
//               <Table className="mb-0 order-prodict-type d-block" borderless>
//                 <thead>
//                   <tr>
//                     <th>COLOR</th>
//                     <th>SIZE</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Dark Blue</td>
//                     <td>XS</td>
//                   </tr>
//                 </tbody>
//               </Table>
//             </div>
//           </div>
//           <div className="order-product-quanity text-center text-sm-right">
//             <ul className="p-0 order-ul-list-none m-0 yt-qt-prc d-flex flex-wrap">
//               <li className="op-order-quantity mb-3">
//                 Quantity : <span className="ord-product-quantity">1</span>
//               </li>
//               <li className="op-order-product-price align-self-end">
//                 <span className="order-product-price">$120</span>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

function SingleOrders(props) {
  // useEffect(() => {
  //   fetchMyorder();
  // }, []);
  const history = useHistory();
  const [ShowCOModal, settt] = useState(false);
  const [reviewData, setReviewData] = useState({});
  const [ShowPRModal, pRsettt] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  // const [paginationData, setPaginationdata] = useState([]);
  const openProductRatingModal = () => {
    pRsettt(!ShowPRModal);
    setReviewData({ orderId: order.id, reviewId: order.reviews?.id });
  };
  const openCancelOrderModal = (order, item) => {
    setCurrentOrder({ orderId: order?.id, itemId: item?.id });
    settt(!ShowCOModal);
  };
  const { order } = props;
  // const fetchMyorder = () => {
  //   const serviceType = serviceTypes.getMyOrdersList(props?.user?.id);
  //   service(serviceType).then(({ response, error }) => {
  //     if (error) {
  //       console.log(error, "MINEERROR");
  //     }
  //     console.log(response?.data, "MIMETM");
  //     setPaginationdata(response?.data);
  //   });
  // };
  const routeToOrderDetails = (order, item) => {
    history.push({
      pathname: `myorder/${order.id}/${item.id}/orderdetails`,
      state: { order, item },
    });
  };

  return (
    <div className="profile-pg-inner-wrap bg-white radius-10 mb-4 yt-my-order-wrap yt-cc-ord">
      <div className="profile-pg-inner-wrapper">
        {ShowCOModal ? (
          <AllModal
            modalName="CancelOrder"
            order={currentOrder}
            getOrders={props.getOrders}
          />
        ) : (
          ""
        )}
        {ShowPRModal ? (
          <AllModal
            modalName="Productrate"
            isOpen={ShowPRModal}
            reviewData={reviewData}
            toggle={() => pRsettt(!ShowPRModal)}
          />
        ) : (
          ""
        )}

        <div className="d-flex flex-wrap align-items-center justify-content-between yt-my-order-tdet-wrap">
          <div className="d-flex align-items-center flex-wrap profile-mo-dt-wrap">
            <div className="order-number-wrap">
              <span className="order-tag">Order Number :</span>
              <span className="order-tag-val">{order.order_number}</span>
            </div>
            <div className="order-bdr-between" />
            <div className="order-date-wrap">
              <span className="order-tag">Ordered on </span>
              <span className="order-tag-val">{order.order_date}</span>
            </div>
          </div>
          {/* {!order.is_review_present && false && (
            <div className="order-review text-right">
              <Button
                color="link order-write-review"
                onClick={openProductRatingModal}
              >
                Write a Review
              </Button>
            </div>
          )} */}

          {order.status === "delivered" || order.status === "returned" ? (
            <div className=" text-right">
              <Button
                color=" order-write-review"
                onClick={openProductRatingModal}
                toggle={() => setShowPRModal(!ShowPRModal)}
              >
                Write a Review
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {order.order_items.map((item, index) => (
          <>
            <div
              className="py-3 d-flex align-items-center yt-order-wrapper-box w3-ripple"
              style={{ cursor: "default" }}
              onClick={() => routeToOrderDetails(order, item)}
              key={index}
            >
              <div className="od-product-img p-1">
                <img
                  // src={item.product_variant && item.product_variant.images[0] ? item.product_variant.images[0].image : item.product_image}
                  src={item.product_image}
                  className="img-fluid"
                />
              </div>
              <div className="d-flex align-items-center">
                <div className="order-product-info">
                  <h2 className="pp-order-product-ttl mt-0">
                    {item.product_name}
                  </h2>
                  {item.product_variant ? (
                    <Table
                      className="mb-0 order-prodict-type d-block"
                      borderless
                    >
                      <thead>
                        <tr>
                          {item.product_variant.product_variant_properties.map(
                            (value, idx) =>
                              idx < 2 && (
                                <th key={idx}>{value.variant_name?.toUpperCase()}</th>
                              )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {item.product_variant.product_variant_properties.map(
                            (value, idx) =>
                              idx < 2 && (
                                <td key={idx}>
                                  {_.capitalize(value.property_name || "")}
                                </td>
                              )
                          )}{" "}
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <div className="order-product-quanity text-center text-sm-right">
                <ul className="p-0 order-ul-list-none m-0 yt-qt-prc d-flex flex-wrap">
                  <li className="op-order-quantity mb-3">
                    Quantity :{" "}
                    <span className="ord-product-quantity">
                      {item.quantity}
                    </span>
                  </li>
                  <li className="op-order-product-price align-self-end">
                    <span className="order-product-price">
                      ${item.total_price}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
          </>
        ))}
      </div>

      <div className="text-right">
        {/* <Button
          color=" od-cancel-btn " 
          onClick={order.status === "cancelled" ? undefined : () => openCancelOrderModal(order)}
        >
          {order.status === "cancelled" ?
            <span style={{color:"white"}} className="btn btn-danger" onClick={()=>(window.notify({message:"This order was cancelled !",type:"danger"}))}>Order Cancelled</span>
            :
            <span style={{color:"white"}} className="btn btn-primary">Cancel Order</span>
          }
        </Button> */}
        {order.status === "delivered" ? (
          <Button color=" od-cancel-btn ">
            <span
              style={{ color: "green", cursor: "default" }}
              className="span-style"
            >
              Delivered
            </span>
          </Button>
        ) : (
          <Button
            color=" od-cancel-btn "
            onClick={
              order.status === "cancelled"
                ? undefined
                : () => openCancelOrderModal(order)
            }
          >
            {order.status === "cancelled" ? (
              <span
                style={{ color: "red", cursor: "default" }}
                className="span-style"
              >
                Cancelled
              </span>
            ) : (
              <span style={{ color: "blue" }} className="span-style">
                Cancel Order
              </span>
            )}
          </Button>
        )}
      </div>
    </div>
  );
}
function OrderDetails(props) {
  const [paginationData, setPaginationdata] = useState([]);
  const [myPage, setPage] = useState(1);
  const fetchMyorder = (val) => {
    
    const serviceType = serviceTypes.getMyOrdersList(props?.user?.id);
    const qparams = new URLSearchParams(window.location.search);
    {
      val
        ? (serviceType.params.page = val)
        : (serviceType.params.page = myPage);
    }

    serviceType.params.per_page = 10;
    service(serviceType).then(({ response, error }) => {
      if (error) {
        console.log(error, "MINEERROR");
      }
      setPaginationdata(response?.data);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMyorder();
  }, [props]);
  
  return (
    <div className="order-main-wrapper">
      {/* {props.order.map((order, index) => ( */}
      {paginationData?.data?.order.map((order, index) => (
        <SingleOrders {...props} order={order} key={index}/>
      ))}
      {paginationData?.meta?.pagination?.total_count >= 10 ? (
        <>
          <div id="pagination">
            <ReactPaginate
              previousLabel={"<<<"}
              nextLabel={">>>"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={paginationData?.meta?.pagination?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(val) => {
                // if ("URLSearchParams" in window) {
                //   var searchParams = new URLSearchParams(
                //     window.location.search
                //   );
                //   searchParams.set("page", val.selected + 1);
                //   console.log("Newton ", searchParams.toString());
                //   props.history.push("/shop?" + searchParams.toString());
                // }
                // setPage( val.selected + 1)
                fetchMyorder(val.selected + 1);
                // setPaginationData({
                //   ...paginationData,
                //   current_page: val.selected + 1,
                // });
              }}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

function NoOrder() {
  const history = useHistory();
  const routeToshop = () => {
    let path = "/shop";
    history.push(path);
  };

  return (
    <div className="profile-pg-inner-wrap profile-pg-inner-no-order p-3 bg-white radius-10 mb-4">
      <div className="profile-pg-inner-wrapper">
        <div className="profile-pg-order-main-wrap text-center ">
          <img
            src={require("./images/no-order-icn.png")}
            className="img-fluid  mb-5"
          />
          <div className="pp-sa-order-wrap mb-5 mt-2">
            <h2 className="pp-od-no-ttl mt-0 mb-3">No Order</h2>
            <p className="pp-od-no-text mb-0">
              You haven’t order any items, Browse items and order it
            </p>
          </div>
          <Button
            color="secondary pp-no-order-btn py-3 px-3"
            onClick={routeToshop}
          >
            Browse Products
          </Button>
        </div>
      </div>
    </div>
  );
}

function MyOrderPage(props) {
  const [ShowOrders, setShowOrders] = useState(true);

  // if(props.orders?.length){
  //   setShowOrders(true)
  // }
  return (
    <>
      {props.orders && props.orders.length > 0 ? (
        <OrderDetails order={props.orders} {...props} />
      ) : (
        <NoOrder />
      )}
    </>
  );
}
export default MyOrderPage;
