import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "./css/index.scoped.css";
import { BsChevronCompactRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import commands from "../../commands";

function DisplayBanner4() {
  const history = useHistory();
  const myBanner = commands.cache.getPositionedWebBanners({ position: 5 });
  return (
    <section className="looks_good_sec">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="item">
              <img
                className="img-fluid myImagebanner"
                // src={require("./images/banner1.jpg")}
                src={myBanner[0]?.images[0]?.image}
                data-aos="fade-up"
                data-aos-duration="1500"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div
              className="content"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <h2 className="text-uppercase wrap-my-text">
                {/* PICK YOUR FANCY . . . */}
                {myBanner[0]?.title}
                <br className="d-none d-md-block" />
              </h2>
              <p className="wrap-my-text">
                {/* Create your unique design using GIA certified Fancy Diamonds, contact us to learn more.
                 */}
                {myBanner[0]?.subtitle}
              </p>
              <Button
                color="transparent"
                className="my-btn text-uppercase mt-3"
                onClick={() => (window.location.href = myBanner[0]?.images[0]?.url)}
              >
                Discover &nbsp; <BsChevronCompactRight />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DisplayBanner4;
