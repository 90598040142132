import React from "react";
import "./css/index.scoped.css";
import { NavLink } from "react-router-dom";

function ProfileBreadcrumbs(props) {
  const urlMatch = props.onProfile.onProps.match.params.slug;
  let MactchBread = "";
  if (urlMatch !== undefined) {
    switch (urlMatch) {
      case "wishlist":
        MactchBread = "Wishlist";
        break;
      case "myorder":
        MactchBread = "My Orders";
        break;
      case "saveaddresses":
        MactchBread = "Saved Addresses";
        break;
      case "myenquiry":
        MactchBread = "My Enquiries";
        break;
      case "connectaccount":
        MactchBread = "Connected Accounts";
        break;
      default:
        MactchBread = "";
    }
    return (
      <div className="pageroute profile-pg-breadcrumbs mt-4 yt-mb-80">
        <NavLink to="/">
        <span className="profile-pg-home">Home {">"}</span></NavLink>{" "}
        <NavLink to="/profile"><span className="">Profile {">"} </span></NavLink>
        <span className="currpage profile-pg-current"> {MactchBread}</span>
      </div>
    );
  } else {
    return (
      <div className="pageroute profile-pg-breadcrumbs mt-4 yt-mb-80">
        <NavLink to="/"><span className="profile-pg-home">Home {">"}</span></NavLink>{" "}
        <span className="currpage profile-pg-current">Profile</span>
      </div>
    );
  }
}

export default ProfileBreadcrumbs;
