import React from "react";
import "./css/index.scoped.css";
import {
  TransactionFailedComponent,
  Footer,
  Header,
  ProductReview,
  FeatureBar,
} from "../../components";
import { BannerHomeTwo } from "../home";
function TransactionFailedPage(props) {
  console.log(props);
  return (
    <>
      <Header onProps={props} />
      <TransactionFailedComponent />
      <BannerHomeTwo />
      <section className="my-4">
        <ProductReview />
      </section>
      <FeatureBar />
      <Footer />
    </>
  );
}
export default TransactionFailedPage;
