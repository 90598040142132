/* global FB */
import React, { useState, useEffect } from "react";
import { Button, FormGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useHistory, NavLink } from "react-router-dom";
import { FaFacebookF, FaRegEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import "./css/index.scoped.css";
import { signUpValidation } from "../Validation schemas/signUpValidations";
import { useSelector } from "react-redux";
import { post } from "../../Barriers/apiHelper";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { userSignUp } from "../../redux/actions/signupActions";
import { userSocialLogin } from "../../redux/actions/loginActions";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../../config/firebase.config";
import language from "../../language/language";

function SignUpPage(props) {
  const [showPass, setShowPass] = useState(false);
  const [emailErr, setEmailErr] = useState("");
  const [signupError, setSignupError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  function routeToAll(value) {
    if (value !== undefined) {
      let path = "/" + value;
      history.push(path);
    } else {
      let path = "/";
      history.push(path);
    }
  }

  const showPassword = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  useEffect(() => {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    window.fbAsyncInit = function () {
      FB.init({
        appId: "1234953260198976",
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });
      FB.AppEvents.logPageView();
      FB.Event.subscribe("auth.statusChange", function (response) {
        if (response.authResponse) {
          checkLoginState();
        } else {
          console.log("---->User cancelled login or did not fully authorize.");
        }
      });
    };
  }, [firebase]);

  function statusChangeCallback(response) {
    if (response.status === "connected") {
      const data = {
        access_token: response.authResponse.accessToken,
        loginType: 2,
        grant_type: "password",
        provider: "facebook",
      };
      let data1;
      try {
        data1 = dispatch(
          userSocialLogin(data, socialLoginSuccess, socialLoginFailure)
        );
      } catch (err) {
        setSignupError("Error while login with Facebook");
        console.log("Error: ", err);
      }
    } else if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      setSignupError("Error while login with Facebook");
    } else {
      setSignupError("Error while login with Facebook");
    }
  }

  function checkLoginState() {
    FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  }

  function signUPnWithFacebook() {
    FB.login(checkLoginState());
  }

  const signupUser = (values) => {
    setEmailErr("");
    const data = {
      user: {
        name: values.FullName,
        email: values.Email,
        password: values.password,
        phone_number: values.Phone
      },
    };
    console.log(data);

    post(`/users`, data)
      .then((res) => {
        console.log(res);
        props.history.push({
          pathname: "/signupconfirm",
          state: {
            data: res.data.data.user,
          },
        });
      })
      .catch((err) => {
        console.log(err.response.data);
        setSignupError(err.response.data.error);
      });
  };

  function signUPnWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    let accessToken;
    provider.addScope("profile");
    provider.addScope("email");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token.
        accessToken = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(accessToken);
        const data = {
          access_token: accessToken,
          loginType: 2,
          grant_type: "password",
          provider: "google",
        };
        let data1;
        try {
          data1 = dispatch(
            userSocialLogin(data, socialLoginSuccess, socialLoginFailure)
          );
        } catch (err) {
          setSignupError("Error while login with Google");
          console.log("Error: ", err);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const socialLoginSuccess = (res) => {
    routeToAll();
  };

  const socialLoginFailure = (err) => {
    setSignupError(err);
    console.log(err);
  };

  useEffect(() => { }, [showPass.emailErr]);

  return (
    <div className="signup-wrapper">
      <h1 className="tagline-title">Welcome Back</h1>
      <h2 className="tagline">Create an account to enjoy a personalized experience with us</h2>
      <div className="mb-3">
        <Formik
          initialValues={{ FullName: "", Email: "", password: "", Phone: "" }}
          onSubmit={signupUser}
          validationSchema={signUpValidation}
        >
          {({ errors, touched }) => {
            return (
              <Form className="sign-up-form">
                <FormGroup>
                  {/* <img alt="User" src={require("./images/user.svg")} /> */}
                  <Field
                    name="FullName"
                    type="text"
                    id="FullName"
                    placeholder="Full Name"
                    className={`form-control ${touched.FullName &&
                      errors.FullName &&
                      "border border-danger"}`}
                  />
                  {errors.FullName && touched.FullName ? (
                    <p className="error">{errors.FullName}</p>
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </FormGroup>
                <FormGroup>
                  {/* <img alt="Email Icon" src={require("./images/email.svg")} /> */}
                  <Field
                    name="Email"
                    type="text"
                    placeholder="Email"
                    className={`form-control ${((touched.Email &&
                      errors.Email) ||
                      emailErr) &&
                      "border border-danger"}`}
                  />
                  {touched.Email && errors.Email ? (
                    <p className="error">{errors.Email}</p>
                  ) : emailErr ? (
                    <p className="text-center error">{emailErr}</p>
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </FormGroup>
                <FormGroup>
                  {/* <img alt="Email Icon" src={require("./images/mobile.svg")} /> */}
                  <Field
                    name="Phone"
                    type="tel"
                    placeholder="Phone Number"
                    className={`form-control ${touched.Phone && errors.Phone && "border border-danger"}`}
                  />
                  {touched.Phone && errors.Phone ? (
                    <p className="error">{errors.Phone}</p>
                  ) : (
                    <p>&nbsp;</p>
                  )}
                </FormGroup>
                <FormGroup className="mb-4">
                  {/* <img alt="Password" src={require("./images/key.svg")} /> */}
                  <Field
                    name="password"
                    type={showPass ? "text" : "password"}
                    id="password"
                    max={12}
                    placeholder="Password"
                    className={`form-control ${touched.password &&
                      errors.password &&
                      "border border-danger"}`}
                  />
                  {showPass ? <FaEyeSlash
                    className="eye-icon"
                    color="#989CA2"
                    onClick={showPassword}
                  /> :
                    <FaEye
                      className="eye-icon"
                      color="#989CA2"
                      onClick={showPassword}
                    />}

                  {touched.password && errors.password && (
                    <p className="error">{errors.password}</p>
                  )}
                  {signupError ? <p style={{ color: "red" }} className="password-hint">{signupError}</p> : <p className="password-hint">
                    Enter a passsword with alphabets A-z, numbers 0-9 and a symbol
                  </p>}

                </FormGroup>
                <Button
                  type="submit"
                  variant="flat"
                  className="btn-sign-up"
                  block
                >
                  CREATE ACCOUNT
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* <div className="other-actions">
        <NavLink to="/shop" className="skip-line-1">
          {language.auth.skipAndContinue}
        </NavLink>
        <p className="skip-line-2">{language.auth.signupVia}</p>
        <div className="login-action">
          <Button onClick={signUPnWithFacebook} className="fb-button mr-5">
            <FaFacebookF className="mr-2" /> {language.auth.facebook}
          </Button>
          <Button onClick={signUPnWithGoogle} className="google-button">
            <FaRegEnvelope className="mr-2" /> {language.auth.google}
          </Button>
        </div>
        <p className="terms-tagline">{language.auth.tandc}</p>
        <p className="terms">
          <NavLink to="/terms-and-conditions">{language.footer.t_and_c}</NavLink>
          <span>&</span>
          <NavLink to="/privacy-policy">{language.footer.privacy_policy}</NavLink>
        </p>
      </div> */}
    </div>
  );
}
export default withRouter(SignUpPage);
