/* global FB */
import React, { useEffect, useState } from "react";
import "./css/index.scoped.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserData,
  userLogout,
} from "../../../redux/actions/loginActions";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../../../config/firebase.config";

function UserLogout() {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.logInReducer);

  useEffect(() => {
    // if (firebase.apps.length === 0) {
    //   firebase.initializeApp(firebaseConfig);
    // }
    // // Load the SDK asynchronously
    // (function(d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) return;
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "//connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");
    // window.fbAsyncInit = function() {
    //   FB.init({
    //     appId: "1234953260198976",
    //     cookie: true,
    //     xfbml: true,
    //     version: "v8.0",
    //   });
    // };
  }, [firebase]);

  const ConfirmLogout = async () => {
    const accessToken = state.loginData?.token?.access_token;
    const data = {
      token: accessToken,
    };
    const header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      },
    };

    await dispatch(userLogout(data, header, onLogoutSuccess, onLogoutFailure));
    history.push("/");
    setModal(!modal);
  };

  function onLogoutSuccess() {
    if (firebase.auth()) {
      firebase
        .auth()
        .signOut()
        .then(function () {
          history.push("/");
        })
        .catch(function (error) {
          // An error happened.
        });
    }
    if (window.FB) {
      window.FB.logout();
      history.push("/");
    }
    history.push("/");
  }

  function onLogoutFailure() { }

  return (
    <div className="cm-main-modal-wrapper">
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="cm-small-modal-4"
        centered={true}
        modalClassName="popopop"
      >
        <ModalHeader toggle={toggle} className="log-out-title-bar  border-0">
          <span>Too Early</span>
        </ModalHeader>
        <ModalBody className="yt-log-body-wrap">
          <div className="text-center log-out-body-text ">
            Are you sure you want to logout?
          </div>
        </ModalBody>
        <ModalFooter className="log-out-bottom-bar p-1 d-flex justify-content-between">
          <Button
            color="secondary pp-log-out-btn-modal p-3 pp-log-out-btn-light-grey"
            onClick={toggle}
            block
          >
            Cancel
          </Button>
          <span className="yt-form-spacer" />
          <Button
            color="secondary pp-log-out-btn-modal p-3 pp-log-out-btn-dark-grey"
            onClick={ConfirmLogout}
            block
          >
            Logout
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default UserLogout;
