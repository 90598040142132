import React, { useState, useEffect } from "react";
import "./css/index.scoped.css";
import {
  Footer,
  FeatureBar,
  ProductCard,
  ProductReview,
  Header,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchUUID, setGuestStatus } from "../../redux/actions/loginActions";
import { get, getProducts, post } from "../../Barriers/apiHelper";
import { v4 as uuidv4 } from "uuid";
import config from "../../config/index";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";

function Home(props) {
  const dispatch = useDispatch();
  const [products, setHomeProduts] = useState([]);
  const state = useSelector((state) => state.logInReducer);
  const cacheState = useSelector((state) => state.cache);

  useEffect(async () => {
    if (!localStorage.getItem("UUID")) {
      let uuId = uuidv4();
      dispatch(fetchUUID(uuId));
      localStorage.setItem("UUID", uuId);
    }
    if (Object.keys(state.loginData).length > 0 && state.uuId.length > 0) {
      callProducts(state.loginData.token, state.uuId);
    }
    if (state.uuId.length > 0) {
      if (state.guest_user) await createGuestUser(state.uuId);
    }
  }, []);

  function callProducts(token, uuid) {
    let headers = {
      headers: {
        Authorization: token.token_type + " " + token.access_token,
      },
    };
    getProducts(
      `/products/get_all_products?page=1&per_page=9&uuid=${uuid}`,
      headers
    )
      .then((res) => {
        setHomeProduts(res.data.data);
      })
      .catch((Err) => {
        console.log("err", Err);
      });
  }
  const createGuestUser = (uuid) => {
    let data = {
      uuid: uuid,
    };
    post(`/users/create_guest_user`, data)
      .then((res) => {
        callProductsUUID(res.data.data.uuid);
        dispatch(setGuestStatus(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callProductsUUID = (uuid) => {
    let headers;
    if (localStorage.getItem("token")) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      };
    }
    get(`/products/get_all_products?page=1&per_page=9&uuid=${uuid}`, {
      headers,
    })
      .then((res) => {
        setHomeProduts(res.data.data);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  return (
    <div>
      <Header onProps={props} />
      <ScrollToTop smooth color="#000" />
      <BannerHomeOne />
      <ProductCard
        products={cacheState.homepage?.recommended_products}
        name="Trendings"
        onViewMore={() => props.history.push("/shop")}
      />
      <BannerHomeTwo />
      <ProductCard
        products={cacheState.homepage?.on_sale_products}
        name="Best Buys"
        onViewMore={() => props.history.push("/shop?&order_field=latest")}
      />
      <ProductReview />
      <FeatureBar />
      <Footer />
    </div>
  );
}

export const BannerHomeOne = () => {
  const [banner, setBanner] = useState(null);

  const getHomeBanners = async () => {
    const query = await axios.get(`${config().apiUrl}products/banners_list`);
    const result = await query.data;
    const imgUrl = result.data?.banners?.filter((b) => b.position === 0)[0]
      ?.images[0]?.image;
    if (imgUrl) {
      setBanner(imgUrl);
    }
  };

  useEffect(() => {
    getHomeBanners();
  }, []);

  return (
    <section className="home-banner">
      <img className="img-fluid" src={banner} />
      <Container>
        <div className="content">
          <Link to="/shop">
            <Button
              variant="flat"
              className="btn-shop"
              style={{ marginTop: "315px" }}
            >
              Shop Now
            </Button>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export const BannerHomeTwo = () => {
  const [banner, setBanner] = useState(null);
  const [ourBanners, setOurBanners] = useState([]);

  const getHomeBanners = async () => {
    const query = await axios.get(`${config().apiUrl}products/banners_list`);
    const result = await query.data;
    const myBanner = result?.data?.banners?.filter((b) => b.position === 7)[0]
      ?.images[0];
    if (myBanner) {
      setOurBanners(myBanner);
    }
  };
  useEffect(() => {
    getHomeBanners();
  }, []);

  return (
    <div className="banner-container mt-4">
      <img className="img-fluid" src={ourBanners?.image} />
      <Container>
        <div className="content">
          <Button
            variant="flat"
            className="btn-shop"
            style={{ marginLeft: "36px" }}
            onClick={() => (window.location.href = ourBanners?.url)}
          >
            Shop Now
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Home;
