import React, { useState, useEffect, useLayoutEffect } from "react";
import "./css/index.scoped.css";
import "./css/inputrange.css";
import { Row, Col, Button, Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import InputRange from "react-input-range";
import { get } from "../../../Barriers/apiHelper";
import { useSelector } from "react-redux";
import getConfig from "../../../config";
import { useHistory } from "react-router";

const ProductsFilterBar = (props) => {
  // console.log(props);
  const qparams = new URLSearchParams(window.location.search);
  const [brands, setBrands] = useState([]);
  const [searchBrand, setSearchBrand] = useState("");
  const [tags, setTags] = useState([]);
  const [searchTag, setSearchTag] = useState("");
  const [categories, setCategories] = useState(undefined);
  const order = props.orderBy;
  const [reflect, setReflect] = useState("");
  const YTCategory = props.onCategory;
  const YTColor = props.onColor;
  const YTSize = props.onSize;
  const cartState = useSelector((state) => state.cartReducer.categotyData);
  const categoryData = useSelector((state) => state.cartReducer.categotyData);
  const config = getConfig();
  const history = useHistory();
  console.log(categoryData,"MYCATEGORYDATABROOOOO");
  const initialPriceFrom =
    qparams.get("from") === null || isNaN(qparams.get("from"))
      ? 0
      : parseInt(qparams.get("from"));
  const initialPriceTo =
    qparams.get("to") === null || isNaN(qparams.get("to"))
      ? 50000
      : parseInt(qparams.get("to"));

  const [TrpeRangeDefailt, setTrpeRangeDefailt] = useState({
    min: initialPriceFrom,
    max: initialPriceTo,
  });

  const runFilter = () => {
    var urlSearch = new URLSearchParams(window.location.search);
    var priceFrom =
      typeof TrpeRangeDefailt.min === "number" ? TrpeRangeDefailt.min : 0;
    var priceTo =
      typeof TrpeRangeDefailt.max === "number" ? TrpeRangeDefailt.max : 50000;
    // urlSearch.set("from", priceFrom);
    // urlSearch.set("to", priceTo);

    if (order === "1") {
      //low to high
      urlSearch.set("order_field", "price");
      urlSearch.set("order_by", "asc");
    } else if (order === "2") {
      //high to low
      urlSearch.set("order_field", "price");
      urlSearch.set("order_by", "desc");
    } else if (order === "3") {
      urlSearch.set("order_field", "popular");
      urlSearch.delete("order_by");
    } else if (order === "4") {
      urlSearch.set("order_field", "latest");
      urlSearch.delete("order_by");
    } else {
      urlSearch.delete("order_field");
      urlSearch.delete("order_by");
    }

    if (Array.isArray(categories) && categories.length > 0) {
      var checkedCategories = categories
        .filter((category) => category.checked === true)
        .map((category) => category.id);
      checkedCategories.forEach((id, idx) => {
        if (idx === 0) {
          urlSearch.set("category_id[]", id);
        } else {
          urlSearch.append("category_id[]", id);
        }
      });
      if (checkedCategories.length < 1) {
        urlSearch.delete("category_id[]");
      }
    }

    if (Array.isArray(brands) && brands.length > 0) {
      var checkedBrands = brands
        .filter((brand) => brand.checked === true)
        .map((brand) => brand.id);
      checkedBrands.forEach((id, idx) => {
        if (idx === 0) {
          urlSearch.set("brand_id[]", id);
        } else {
          urlSearch.append("brand_id[]", id);
        }
      });
      if (checkedBrands.length < 1) {
        urlSearch.delete("brand_id[]");
      }
    }

    var checkedTags = "";
    if (Array.isArray(tags) && tags.length > 0) {
      checkedTags = tags
        .filter((tag) => tag.checked === true)
        .map((tag) => tag.name)
        .join(",");
      if (checkedTags) {
        urlSearch.set("tag", checkedTags);
      } else {
        urlSearch.delete("tag");
      }
    }

    if (qparams.get("page") === null) {
      urlSearch.set("page", 1);
    }
    if (qparams.get("per_page") === null) {
      urlSearch.set("per_page", 9);
    }

    //alert(priceFrom);
    history.push(`/shop?${decodeURIComponent(urlSearch.toString())}`);
  };

  useEffect(async () => {
    runFilter();
    getCategories();
    getBrands();
    getTags();
  }, []);

  const getCategories = () => {
    let categories = categoryData;
    if (Array.isArray(categories) && categories.length > 0) {
      var urlCategories = qparams.getAll("category_id[]") || [];
      var checkedCategories = categories.map((category) =>
        urlCategories.includes(category.id.toString())
          ? ((category.checked = true), category)
          : ((category.checked = false), category)
      );
      setCategories(checkedCategories);
    }
  };

  const getBrands = () => {
    get(`/brands`)
      .then((res) => {
        let brands = res.data.data.brands;
        if (Array.isArray(brands) && brands.length > 0) {
          var urlBrands = qparams.getAll("brand_id[]") || [];
          var checkedBrands = brands.map((brand) =>
            urlBrands.includes(brand.id.toString())
              ? ((brand.checked = true), brand)
              : ((brand.checked = false), brand)
          );
          setBrands(checkedBrands);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTags = () => {
    get(`/tags`)
      .then((res) => {
        var tags = res.data.data;

        if (Array.isArray(tags) && tags.length > 0) {
          var urlTags = qparams.get("tag")?.split(",") || [];
          var checkedTags = tags.map((tag) =>
            urlTags.includes(tag.name)
              ? ((tag.checked = true), tag)
              : ((tag.checked = false), tag)
          );
          setTags(checkedTags);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const filterCheck = (id) => {
  //   let data = [];
  //   categories.forEach((x) => {
  //     if (id == x.id) {
  //       x.checked = !x.checked;
  //     }
  //     data.push(x);
  //   });
  //   console.log(data);
  //   setCategories(data);
  // };

  async function removeFilter(item, type, arrayData = []) {

    let data = [];
    arrayData.forEach((x) => {
      if (item.id == x.id) {
        x.checked = false;
      }
      data.push(x);
    });

    if (type === "category") {
      setCategories(data);
    }
    if (type === "brands") {
      setBrands(data);
    }
    if (type === "tags") {
      setTags(data);
    }
    props.onSelect(item, type, data);
    runFilter();
  };

  useEffect(() => {
    props.setRemoveFilter(() => removeFilter);
    // props.setOrderBy(() => setOrder);
  }, []);
  
  useEffect(() => {
    props.onSelect({}, "category", categories);
    runFilter();
  }, [categories]);
  
  useEffect(() => {
    props.onSelect({}, "brands", brands);
    runFilter();
  }, [brands]);
  
  useEffect(() => {
    props.onSelect({}, "tags", tags);
    runFilter();
  }, [tags]);
  
  useLayoutEffect(() => {
    runFilter();
  }, [order]);

  return (
    <div className="yt-product-filter-wrap" style={{paddingTop:"52px"}}>
      <h2 className="yt-filter-title mt-0">Filter</h2>
      <div className="yt-main-filter-box bg-white radius-10">
        <div className="yt-filter-inner-wrap p-4">
          {categories?.length > 0 ? (
            <h4 className="yt-box-title mt-0">Category</h4>
          ) : (
            <></>
          )}
          <ul className="p-0 m-0 yt-ul-list-none">
            {categories?.map((cat, index) => (
              <li
                key={index}
                className="pb-2 pb-lg-4 d-flex align-items-center justify-content-between"
              >
                <div className="yt-cat-name">
                  {cat.name} {cat.checked}
                </div>
                <div className="yt-filter-checkbox-wrap">
                  <input
                    type="checkbox"
                    onChange={() => {
                      categories[index] = ((cat.checked = !cat.checked), cat);
                      setCategories([...categories]);
                      runFilter();
                    }}
                    dataId={cat.id}
                    dataValue={cat.name}
                    checked={cat.checked}
                  />
                  <label className="yt-filter-label" htmlFor={cat.name} />
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <span className="yt-filter-divider" />
        <div className="yt-filter-inner-wrap p-4">
          <h4 className="yt-box-title mt-0">Brands</h4>
          <div className="yt-filter-search-filed">
            <Input
              type="text"
              name="yt-filter-brands"
              value={searchBrand}
              onChange={(e) => setSearchBrand(e.target.value)}
              id="yt-filter-brands-flt"
              placeholder="Search Brands"
            />
            <img
              src={require("./images/magnifying-glass@3x.png")}
              alt="search"
              className="yt-searchicon-img w3-ripple"
              width="23"
              height="23"
            />
          </div>
          <ul className="p-0 m-0 yt-ul-list-none">
            {brands?.filter((b) => b.name.toLowerCase().includes(searchBrand.toLowerCase())).length >
            0 ? (
              brands
                ?.filter((b) => b.name.toLowerCase().includes(searchBrand.toLowerCase()))
                .map((cat, index) => (
                  <li
                    key={index}
                    className="pb-4 d-flex align-items-center justify-content-between"
                  >
                    <div className="yt-cat-name">
                      {cat.name}
                      
                    </div>
                    <div className="yt-filter-checkbox-wrap">
                      <input
                        type="checkbox"
                        onChange={() => {
                          brands[index] = ((cat.checked = !cat.checked), cat);
                          setBrands([...brands]);
                          runFilter();
                        }}
                        dataId={cat.id}
                        dataValue={cat.name}
                        checked={cat.checked}
                      />
                      <label className="yt-filter-label" for={cat.name} />
                    </div>
                  </li>
                ))
            ) : (
              <li style={{ color: "red", fontSize: 14 }}>No Match</li>
            )}
          </ul>
        </div>
        <span className="yt-filter-divider" />
        <div className="yt-filter-inner-wrap p-4">
          <h4 className="yt-box-title mt-0">Tags</h4>
          <div className="yt-filter-search-filed">
            <Input
              type="text"
              value={searchTag}
              onChange={(e) => setSearchTag(e.target.value)}
              name="yt-filter-tags"
              id="yt-filter-tags-flt"
              placeholder="Search Tags"
            />
            <img
              src={require("./images/magnifying-glass@3x.png")}
              alt="search"
              className="yt-searchicon-img w3-ripple"
              width="23"
              height="23"
            />
          </div>
          <ul className="p-0 m-0 yt-ul-list-none">
            {tags
              ?.filter((t) => t.name.toLowerCase().includes(searchTag.toLowerCase()))
              .map((cat, index) => (
                <li
                  key={index}
                  className="pb-4 d-flex align-items-center justify-content-between"
                >
                  <div className="yt-cat-name">
                    {cat.name}
                  
                  </div>
                  <div className="yt-filter-checkbox-wrap">
                    <input
                      type="checkbox"
                      onChange={() => {
                        tags[index] = ((cat.checked = !cat.checked), cat);
                        setTags([...tags]);
                        runFilter();
                      }}
                      dataId={cat.id}
                      dataValue={cat.name}
                      checked={cat.checked}
                    />
                    <label className="yt-filter-label" for={cat.name} />
                  </div>
                </li>
              ))}
            {tags?.filter((t) => t.name.toLowerCase().includes(searchTag.toLowerCase())).length <= 0 &&
              searchTag !== "" && (
                <li style={{ color: "red", fontSize: 14 }}>No Match</li>
              )}
          </ul>
        </div> */}
        {/* <span className="yt-filter-divider" /> */}
        {/* <div className="yt-filter-inner-wrap p-4">
          <h4 className="yt-box-title mt-0">Color</h4>
          <div className="yt-filter-search-wrap pb-2 mb-3">
            <AiOutlineSearch className="yt-search-icn" />
            <input
              type="text"
              placeholder="Search Colors"
              className="yt-color-search-bar d-block py-3"
            />
          </div>
          <ul className="p-0 m-0 yt-ul-list-none">
            {YTColor.map((color, index) => (
              <li
                key={index}
                className="pb-4 d-flex align-items-center justify-content-between"
              >
                <div className={"yt-color-name pl-4 " + color.title}>
                  {color.title}
                </div>
                <div className="yt-filter-checkbox-wrap">
                  <input
                    type="checkbox"
                    onChange={(e) => props.onSelect(color.id, "color")}
                    dataId={color.id}
                    dataValue={color.title}
                    checked={color.checked}
                  />
                  <label className="yt-filter-label" for={color.title} />
                </div>
              </li>
            ))}
          </ul>
        </div>*/}
        {/*         
        <div className="yt-filter-inner-wrap p-4">
          <h4 className="yt-box-title mt-0">Size</h4>
          <ul className="p-0 m-0 yt-ul-list-none">
            {YTSize.map((size, index) => (
              <li
                key={index}
                className="pb-4 d-flex align-items-center justify-content-between"
              >
                <div className="yt-size-name">{size.title}</div>
                <div className="yt-filter-checkbox-wrap">
                  <input
                    type="checkbox"
                    onChange={(e) => props.onSelect(size.id, "size")}
                    dataId={size.id}
                    dataValue={size.title}
                    checked={size.checked}
                  />
                  <label className="yt-filter-label" for={size.title} />
                </div>
              </li>
            ))}
          </ul>
        </div>
       
        */}
        {/* <div className="yt-filter-inner-wrap p-4 yt-price-filter-wrap">
          <h4 className="yt-box-title mt-0">Price Range</h4>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="yt-min-price">
              {config.currency} {TrpeRangeDefailt.min}
            </span>
            <span className="yt-max-price">
              {config.currency} {TrpeRangeDefailt.max}
            </span>
          </div>
          <InputRange
            maxValue={100000}
            minValue={0}
            step={1000}
            value={TrpeRangeDefailt}
            onChange={(value) => setTrpeRangeDefailt(value)}
            onChangeComplete={(value) => runFilter()}
          />
        </div> */}
      </div>
    </div>
  );
}
export default ProductsFilterBar;
