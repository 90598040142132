import React from "react";
import "./css/index.scoped.css";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import language from "../../language/language";

function Footer() {
  return (
    <footer>
      <div className="footercontainer yt-main-footer">
        <Container>
                <div className="logobox">
                  <NavLink to="/">
                    <img
                      src={require("./images/FooterLogo.png")}
                      className="logoimage"
                      alt="pooja-duggal-fine-jewels"
                    />
                  </NavLink>
                </div>
              
              <span className="footer-divider"/>
              <div className="yt-footler-menu-links d-flex flex-row">
              
                <NavLink
                  to="/contactus"
                  className="yt-ftr-link avitem w3-ripple"
                >
                  {language.footer.contact_us}
                </NavLink>
                <NavLink to="/size-guide" className="yt-ftr-link w3-ripple">
                Find Your Size
                </NavLink>
                {/* SP - 10/04/21 commented below code for new changes */}
                {/* <NavLink to="/help-center" className="yt-ftr-link w3-ripple">
                Help Center
                </NavLink> */}
                <NavLink
                  to="/help-center/Frequently%20Asked%20Questions"
                  className="yt-ftr-link w3-ripple"
                >
                  FAQ's
                </NavLink>
                <NavLink
                  to="/help-center/Terms%20&%20Conditions"
                  className="yt-ftr-link w3-ripple"
                >
                  Terms & Conditions
                </NavLink>
                <NavLink
                  to="/help-center/Privacy%20Policy"
                  className="yt-ftr-link w3-ripple"
                >
                  Privacy Policy
                </NavLink>
               
              </div>
            
        
        </Container>
      </div>
      <div className="footer-area yt-main-footer bg-white">
      <Container>
        <div className="d-flex">
          <div>
            <p className="  m-0 yt-copyright-text " style={{ color: "black" }}>
              {language.footer.copyrights}
            </p>
          </div>
          <div className="social_all">
            <a href="https://www.facebook.com/" target="_blank">
              <img
                src={require("./images/facebook.png")}
                className="social_logos w3-ripple"
                alt="social"
              />
            </a>
            <a href="https://www.instagram.com/" target="_blank">
              <img
                src={require("./images/instagram.png")}
                className="social_logos w3-ripple"
                alt="social"
              />
            </a>{" "}
          </div>
        </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
