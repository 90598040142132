import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "./css/index.scoped.css";
import { BsChevronCompactRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import commands from "../../commands";
import _ from "lodash";

function DisplayBannerNew() {
  const history = useHistory();
  const myBanner = commands.cache.getPositionedWebBanners({ position: 2 });
  const sortedBanner = _.sortBy(myBanner[0]?.images, ["position"]);
  return (
    <section className="home-banner1">
      <Container>
        <h2
          className="banner-title"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          {/* ENGAGEMENT RINGS */}
          {myBanner[0]?.title}
        </h2>
        <p
          className="banner-subtitle"
          data-aos="fade-up"
          data-aos-duration="2500"
        >
          {/* We create beautiful and exquisitely hand crafted custom engagement rings to meet your match. */}
          {myBanner[0]?.subtitle}
        </p>
        <Row>
          <Col xs={12} sm={3} lg={3}>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="display-product-container"
              onClick={() => (window.location.href = sortedBanner[0]?.url)}
              style={{
                //backgroundImage: `url(${require("./images/Pooja-22.jpg")}`,
                backgroundImage: `url(${sortedBanner[0]?.image})`,
                height: "550px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <span
                  className="myBannercontent"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {/* 2021 Collection Essentials Shapes */}
                  {sortedBanner[0]?.title}
                </span>
                <Button
                  variant="flat"
                  className="my-btn"
                  onClick={() => (window.location.href = sortedBanner[0]?.url)}
                  data-aos="fade-up"
                >
                  DISCOVER &nbsp; <BsChevronCompactRight />
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              onClick={() => (window.location.href = sortedBanner[1]?.url)}
              style={{ cursor: "pointer" }}
              className="mobilePadding"
            >
              {/* <video
                  className="video-container"
                  autoPlay
                  loop
                  controls
                  muted
                  data-reactid=".0.1.0.0"
                >
                  <source
                    type="video/mp4"
                    src={require("./Video/fineJewelvideo.mp4")}
                  />
                </video> */}
              <img
                className="img-fluid myBannerimg"
                // src={require("./images/Pooja-8.jpg")}
                src={sortedBanner[1]?.image}
              />
              <div>
                <span
                  className="myBannercontent myBanner"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {/* 2021 Collection Essentials Shapes */}
                  {sortedBanner[1]?.title}
                </span>
                <Button
                  variant="flat"
                  className="my-btn"
                  onClick={() => (window.location.href = sortedBanner[0]?.url)}
                  data-aos="fade-up"
                >
                  DISCOVER &nbsp; <BsChevronCompactRight />
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={3} lg={3}>
            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              className="display-product-container mobilePadding"
              onClick={() => (window.location.href = sortedBanner[2]?.url)}
              style={{
                // backgroundImage: `url(${require("./images/Pooja-24.jpg")}`,
                backgroundImage: `url(${sortedBanner[2]?.image}`,
                height: "550px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <span
                  className="myBannercontent"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {/* Inlay Collection */}
                  {sortedBanner[2]?.title}
                </span>
                <Button
                  variant="flat"
                  className="my-btn"
                  data-aos="fade-up"
                  onClick={() => (window.location.href = sortedBanner[2]?.url)}
                >
                  DISCOVER &nbsp; <BsChevronCompactRight />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DisplayBannerNew;
