import React, { useState } from "react";
import "./css/index.scoped.css";
import {  Button } from "reactstrap";
// import { FaHeart } from "react-icons/fa";
//setAddress={() => onAddressSuccess()}
import { AllModal } from "../../../components";


function NoAddress(props) {
    const [ShowADModal, settt] = useState(false);
    const openAddAddressModal = () => settt(!ShowADModal);
    return (
      <div className="profile-pg-inner-wrap profile-pg-inner-no-add bg-white radius-10 profile-pg-mb-30">
        <div className="profile-pg-sa-no-address-main-wrap text-center">
          {ShowADModal ? <AllModal modalName="Addaddress" setAddress={() => props.getAddress()}/> : ""}
          <img src={require("./images/address-icn.png")} className="img-fluid mb-5" />
          <div className="pp-sa-no-add-wrap mt-2 mb-5">
            <h2 className="pp-na-ttl mt-0 mb-3">No addresses saved !</h2>
            <p className="pp-na-text mb-0">
              No addresses have been saved to the address list yet !
            </p>
          </div>
          <Button
            color=" pp-no-addr-btn py-3"
            onClick={openAddAddressModal}
          >
            Add an Address
          </Button>
        </div>
      </div>
    );
  }

export default NoAddress
