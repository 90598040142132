import React from "react";
import "./css/index.scoped.css";
import {
  Footer,
  Header,
  ThreePromo,
  CartPageComponent,
} from "../../components";
import "./css/index.scoped.css";
import { BannerHomeTwo } from "../home";

function CartPage(props) {
  return (
    <div>
      <Header onProps={props} />
      <CartPageComponent />
      <ThreePromo />
      <BannerHomeTwo />
      <Footer />
    </div>
  );
}
export default CartPage;
