import * as yup from "yup";
import validations from "./validations";

export const addressForm = yup.object().shape({
  saveBillingAddress: yup.boolean(),
  saveShippingAddress: yup.boolean(),
  bname: validations.name.required("This field is required"),
  bhouseNumber: validations.houseNumber.required("This field is required"),
  baddressLine1: validations.address.required("This field is required"),
  baddressLine2: validations.address,
  bcity: validations.city.required("This field is required"),
  bstate: validations.state.required("This field is required"),
  bcountry: validations.country.required("This field is required"),
  bpincode: yup.string().required("This field is rquired"),
  bphone: yup
    .number()
    .required("This field is Required")
    .typeError("Only numbers are allowed.")
    .required("This field is required.")
    .positive("Negative numbers are not allowed.")
    .integer("Number can't contain a decimal.")
    .min(1000000000, "Minimum 10 digits are required.")
    .max(999999999999, "Maximum 12 digits are allowed."),

  isShippingAddressSame: yup.boolean(),

  sname: validations.name.when("isShippingAddressSame", {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("This field is required"),
  }),
  shouseNumber: validations.houseNumber.when("isShippingAddressSame", {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("This field is required"),
  }),
  saddressLine1: validations.address.when("isShippingAddressSame", {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("This field is required"),
  }),
  saddressLine2: validations.address
    .notRequired()
    .when("isShippingAddressSame", {
      is: true,
      then: yup.string().notRequired(),
      otherwise: yup.string().required("This field is required"),
    }),
  scity: validations.city.when("isShippingAddressSame", {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("This field is required"),
  }),
  sstate: validations.state.when("isShippingAddressSame", {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("This field is required"),
  }),
  scountry: validations.country.when("isShippingAddressSame", {
    is: true,
    then: yup.string().notRequired(),
    otherwise: yup.string().required("This field is required"),
  }),
  spincode: validations.pincode.when("isShippingAddressSame", {
    is: true,
    then: yup.number().notRequired(),
    otherwise: yup.number().required("This field is required"),
  }),
  sphone: validations.phone.when("isShippingAddressSame", {
    is: true,
    then: yup.number().notRequired(),
    otherwise: yup.number().required("This field is required"),
  }),
});

export const enquiryForm = yup.object().shape({
  name: validations.name.required("Name is required"),
  bhouseNumber: validations.houseNumber.required("This field is required"),
  baddressLine1: validations.address.required("This field is required"),
  baddressLine2: validations.address,
  bcity: validations.city.required("City is required"),
  bstate: validations.state.required("State is required"),
  bcountry: validations.country.required("This field is required"),
  bpincode: yup
    .string()
    .required("This field is rquired"),
  bphone: yup
    .number()
    .required("Phone is required")
    .min(1000000000, "Minimum 10 digits are allowed")
    .max(999999999999, "Maximum 12 digits are allowed"),
  emessage: validations.emessage.required("This field is required"),
});

export const contactForm = yup.object().shape({
  name: validations.name.required("Name is required"),
  email: validations.email.required("Email is required"),
  phone: validations.phone
    .required("Phone is required")
    .min(1000000000, "Minimum 10 digits are allowed")
    .max(999999999999, "Maximum 12 digits are allowed"),
  purpose: validations.subject.required("Purpose of contact is required"),
  message: validations.description.required("Message is required"),
});

export const editProfile = yup.object().shape({
  name: validations.name.required("Name is required"),
  email: validations.email.required("Email is required"),
  phone_number: yup
    .number()
    .required("This field is Required")
    .typeError("Only numbers are allowed.")
    .required("This field is required.")
    .positive("Negative numbers are not allowed.")
    .integer("Number can't contain a decimal.")
    .min(1000000000, "Minimum 10 digits are required.")
    .max(999999999999, "Maximum 12 digits are allowed."),
});
