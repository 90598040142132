export default {
  auth: {
    headerText: "Get started and discover the best offers around you",
    otp_sent_email: "OTP sent to your Email",
    verifyOTP: "Verify OTP",
    createPassword: "Create New Password",
    passwordRule: "Enter a passsword with alphabets A-z, numbers 0-9 and a symbol",
    passwordChanged: "Your Password has been changed Successfully.",
    resetPassword: "Reset Password",
    enterEmailOrPhone:
      "Enter your registered Email and we’ll sent you OTP to reset your password",
    emailRequired: "Email is a required field",
    emailInvalid: "Email must be a valid email",
    enterOTP: "Enter OTP",
    enterValidOpt: "Enter valid OTP",
    invalidOtp: "Your OTP is not verified. Please try again",
    otpSend: "Send OTP",
    skipAndContinue: "Skip & Continue as Guest",
    facebook: "Facebook",
    google: "Google",
    tandc: "By Signing Up you agree with our",
    signUp: "Sign up",
    signupVia: "or Sign up via",
    forgotPassword: "Forgot Password ?",
  },
  home: {},
  profile: {},
  cart: {},
  product: {},
  common: {
    download: "Download the App for Free",
  },
  footer: {
    description: `Fully Customized Handcrafted Jewelry`,
    about_us: "About Us",
    delivery_and_returns: "Delivery & Returns",
    contact_us: "Contact Us",
    help_center: "Help Centers",
    faq: "FAQ's",
    t_and_c: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    copyrights: "Copyright © 2021 Pooja Duggal-Fine Jewels. All rights reserved.",
    useful_links: "Useful Links",
    site_map: "Site Map",
    quick_links: "Quick Links",
    promotions: "Promotions",
  },
  payment: {
    processing: "Processing your payment, Do not press back Button.",
    failed: "An error occured while processing your payment.",
  },
};
