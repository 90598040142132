import React from "react";
import "./css/index.scoped.css";
import { Container, Row, Col } from "reactstrap";

function FeatureBar() {
  return (
    <></>
    // <section className="yt-featured-bar-main">
    // <Container>
    //   <div className="customcontainer hp-featured-wrapper">
    //     <Row className="align-items-center yt-cm-fb-row">
    //       <Col xs={12} sm={3} lg={3} className="yt-cm-fb-col">
    //         <Row className="align-items-center yt-fb-inner-wrap">
    //           <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
    //           <div className="yt-ft-img d-flex align-items-center justify-content-center">
    //             <img
    //               src={require("./images/truck.svg")}
    //               className="logoimage"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //              <img
    //               src={require("./images/truck-white.png")}
    //               className="logoimage d-none logo-white"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //            </div>
    //           </Col>
    //           <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
    //             <div className="hp-fb-inner-wrap py-3">
    //               <div className="feature_head first">Free Delivery</div>
    //               <div className="feature_text first">
    //                 Free Shipping on all order
    //               </div>
    //             </div>
    //           </Col>
    //         </Row>
    //       </Col>
    //       <Col xs={12} sm={3} lg={3} className="yt-cm-fb-col">
    //         <Row className="align-items-center yt-fb-inner-wrap">
    //           <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
    //           <div className="yt-ft-img d-flex align-items-center justify-content-center">
    //             <img
    //               src={require("./images/CreditCard.svg")}
    //               className="logoimage"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //             <img
    //               src={require("./images/pay-white.png")}
    //               className="logoimage d-none logo-white"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //             </div>
    //           </Col>
    //           <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
    //             <div className="hp-fb-inner-wrap py-3">
    //               <div className="feature_head">Secure Payment</div>
    //               <div className="feature_text">
    //                 On every order over $100.00
    //               </div>
    //             </div>
    //           </Col>
    //         </Row>
    //       </Col>
    //       <Col xs={12} sm={3} lg={3} className="yt-cm-fb-col">
    //         <Row className="align-items-center yt-fb-inner-wrap">
    //           <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
    //           <div className="yt-ft-img d-flex align-items-center justify-content-center">
    //             <img
    //               src={require("./images/Money.svg")}
    //               className="logoimage"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //              <img
    //               src={require("./images/money-white.png")}
    //               className="logoimage d-none logo-white"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //             </div>
    //           </Col>
    //           <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
    //             <div className="hp-fb-inner-wrap py-3">
    //               <div className="feature_head">60 Days Return</div>
    //               <div className="feature_text">Guarantee under 7 days</div>
    //             </div>
    //           </Col>
    //         </Row>
    //       </Col>
    //       <Col xs={12} sm={3} lg={3} className="yt-cm-fb-col">
    //         <Row className="align-items-center yt-fb-inner-wrap">
    //           <Col xs={2} sm={12} lg={2} className="yt-cm-fb-inner-col">
    //            <div className="yt-ft-img d-flex align-items-center justify-content-center">
    //            <img
    //               src={require("./images/Lifeguard.svg")}
    //               className="logoimage "
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //             <img
    //               src={require("./images/help-white.png")}
    //               className="logoimage d-none logo-white"
    //               alt="pooja-duggal-fine-jewels"
    //             />
    //              </div> 
    //           </Col>
    //           <Col xs={10} sm={12} lg={10} className="yt-cm-fb-inner-col">
    //             <div className="hp-fb-inner-wrap border-md-0 py-3">
    //               <div className="feature_head">Support 24/7</div>
    //               <div className="feature_text">
    //                 Support online 24 hours a day
    //               </div>
    //             </div>
    //           </Col>
    //         </Row>
    //       </Col>
    //     </Row>
    //   </div>
    // </Container>
    // </section>
  );
}

export default FeatureBar;
