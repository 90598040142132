import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
//import { FaRegHeart } from "react-icons/fa";
import { FavouriteProductSet } from "../../components";
import { getProducts, get } from "../../Barriers/apiHelper";
import { useSelector } from "react-redux";
import commands from "../../commands";
import Ripple from "react-ripples";
import _ from "lodash";
import service, { serviceTypes } from "../../services";

import "./css/index.scoped.css";
import { useHistory, withRouter } from "react-router";
import ImageController from "../imagecontroller";
import { BsChevronCompactRight } from "react-icons/bs";
import config from "../../config";
function SingleProductComponent(props) {
  console.log(props,"MYPROPSHERE")
  const history = useHistory();
  const [productsList, setProductList] = useState(undefined);
  const [userdata, setuserData] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const cacheState = useSelector((state) => state.cache);

  console.log('dataa - recommended_products', cacheState.homepage?.recommended_products);
  const state = useSelector((state) => state.logInReducer);
  const filterData = (type) => {
    let uuid = state.uuId;
    let categoryParam;
    let tagId;
    let brandId;
    let from = ``;
    let to = ``;
    let OrderBy;
    if (props.category.length > 0) {
      let d = "";
      categoryParam = "&category_id[]=1";
      props.category.forEach((x) => {
        d += x.checked === true ? `&category_id[]=${x.id}` : "";
      });
      categoryParam = d;
    }

    if (props.tags.length > 0) {
      let d = "&tag=";
      tagId = "tag=5,";
      props.tags.forEach((x) => {
        d += x.checked === true ? x.name + "," : "";
      });
      tagId = d + "&";
    }
    if (props.brands.length > 0) {
      let d = "";
      brandId = "brand_id[]=3&";
      props.brands.forEach((x) => {
        d += x.checked === true ? `&brand_id[]=${x.id}` : "";
      });
      brandId = d;
    }
    
    if (props.sortBy == "1") {
      //low to high
      OrderBy = "&order_field=price&order_by=asc";
    } else if (props.sortBy == "2") {
      //high to low
      OrderBy = "&order_field=price&order_by=desc";
    } else if (props.sortBy == "3") {
      OrderBy = "&order_field=popular";
    } else if (props.sortBy == "4") {
      OrderBy = "&order_field=latest";
    }
    if (state.guest_user) {
      getProductList(
        `/products/filter${
          window.location.search ? window.location.search : "?"
        }&uuid=${uuid}`
      );
    } else {
      let headers = {
        headers: {
          Authorization: `${state.loginData.token.token_type} ${
            state.loginData.token.access_token
          }`,
        },
      };
      getProducts(
        `/products/filter${
          window.location.search ? window.location.search : "?"
        }`,
        headers
      )
        .then((res) => {
          setProductList(res.data?.data);

          if (!_.isEqual(props.paginationData, res.data?.meta?.pagination)) {
            props.setPaginationData(res.data?.meta?.pagination);
          }
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            setProductList([]);
          }
          window.notify([
            {
              message: err.response?.data?.error || err.message,
              type: "success",
            },
          ]);
        })
        .finally(() => setShowSpinner(false));
    }
  };

  function getProductList(data) {
    get(data)
      .then((res) => {
        setProductList(res.data?.data);

        if (!_.isEqual(props.paginationData, res.data?.meta?.pagination)) {
          props.setPaginationData(res.data?.meta?.pagination);
        }
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setProductList([]);
        }
        window.notify([
          {
            message: err.response?.data?.error || err.message,
            type: "success",
          },
        ]);
      })
      .finally(() => setShowSpinner(false));
  }

  useEffect(() => {
    if(props.isRecommended){
      setProductList(cacheState.homepage?.recommended_products);
    } else if (
      props.brands.length > 0 ||
      props.tags.length > 0 ||
      props.category.length > 0 ||
      props.sortBy.length > 0
    ) {
      filterData(props.type);
    } else {
      //checkUserData();
    }
  }, [props]);

  function addToWishlist(product) {
    // console.log("Adding to wishlist");
    const serviceType = serviceTypes.addToWishlist(state.user?.id);
    serviceType.body.product_id = product.id;

    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product added to wishlist successfully",
            type: "success",
          },
        ]);
        if (props.isRecommended)
          setProductList(cacheState.homepage?.recommended_products);
        else
          filterData(props.type);
        // filterData(props.type);
      }
    });
  }

  function removeFromWishlist(product) {
    // console.log("Removing from wishlist");
    const serviceType = serviceTypes.removeFromWishlist(state.user?.id);
    serviceType.params.product_id = product.id;
    service(serviceType).then(({ response, error }) => {
      if (error) {
        window.notify([{ message: error.message }]);
      } else if (response?.data?.success) {
        window.notify([
          {
            message: "Product removed from wishlist successfully",
            type: "danger",
          },
        ]);
        if(props.isRecommended) 
          setProductList(cacheState.homepage?.recommended_products);
        else 
          filterData(props.type);
      }
    });
  }

  const checkUserData = () => {
    if (userdata.loginData != undefined) {
      if (Object.keys(userdata.loginData).length > 0) {
        let accessToken = userdata.loginData.token;
        if (props.location.state == undefined) callMe(accessToken);
        else callCategoryToken(accessToken, props.location.state);
      } else if (userdata.guest_user) {
        let uuid = userdata.uuId;
        if (props.location.state == undefined) callUuidProducts(uuid);
        else callCategory(props.location.state, uuid);
      }
    }
  };
  const callCategory = (catgId, uuid) => {
    get(`/categories/${catgId}/get_products?uuid=${uuid}`)
      .then((res) => {
        setProductList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const callCategoryToken = (token, catgId) => {
    let headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
    getProducts(`/categories/${catgId}/get_products`, headers)
      .then((res) => {
        setProductList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setuserData(state);
  }, [state]);
  useEffect(() => {
    // console.log(userdata, Object.keys(userdata).length);
    if (Object.keys(userdata).length > 0) {
      //checkUserData();
    }
  }, [userdata]);
  useEffect(() => {}, [productsList]);

  const callMe = async (token) => {
    // console.log(token, "accessToken");
    let headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };

    await getProducts(`/products/get_all_products?page=1&per_page=30`, headers)
      .then((res) => {
        // console.log(res.data.data);
        setProductList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const callUuidProducts = (uuid) => {
    get(`/products?uuid=${uuid}`)
      .then((res) => {
        setProductList(res.data.data.all_products);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };
  const productDetails = (a) => {
    history.push({ pathname: `/shop/${a.id}`, state: { a } });
  };

  return (
    <>
      <div className="yt-allproduct-main-wrap my-3">
        <Row className="yt-cm-row-margin">
          {productsList?.length > 0 ? (
            productsList.map((product, index) => (
              <Col
                key={index}
                xs={12}
                sm={4}
                lg={4}
                className=" cm-col-prdt-col mt-4"
              >
                <div
                  className="product yt-sgl-product-cpnt text-center"
                  style={{ cursor: "default" }}
                  data-aos="zoom-in-up"
                  data-aos-duration="1500"
                >
                  <div className="d-flex justify-content-between align-items-center yt-sp-top-fav-act">
                    <div className="text-right p-2 rounded-circle bg-white yt-fav-circle-wrap">
                      <FavouriteProductSet
                        onDataId={index}
                        onPageType="shop"
                        isFav={product.is_wishlisted}
                        onClick={() =>
                          product.is_wishlisted
                            ? removeFromWishlist(product)
                            : addToWishlist(product)
                        }
                      />
                    </div>
                  </div>

                  <ImageController
                    src={
                      product.images?.length > 0
                        ? product.images[0].image
                        : null
                    }
                    imageClick={() => productDetails(product)}
                    className="hoverMe"
                  />

                  <div
                    className="yt-sgl-product-title text-center w3-ripple"
                    onClick={() => productDetails(product)}
                  >
                    {product.name}
                  </div>
                  <Ripple className={"w-100"}>
                    <Button
                      color="secondary yt-sgl-product-add-btn buttoncart py-3"
                      onClick={() => {
                        productDetails(product);
                      }}
                    >
                      Enquire<BsChevronCompactRight />
                    </Button>
                  </Ripple>
                </div>
              </Col>
            ))
          ) : !showSpinner ? (
            <span className="noProducts">Sorry ! No Products Found</span>
          ) : (
            <>
              <div style={{ margin: "0 auto", textAlign: "center" }}>
                <img width="200" src={require("./images/animat-diamond.gif")} />
                <h2 className="trans-fl-ttl my-3" style={{ color: "#000" }}>
                  Loading Product . . .
                </h2>
              </div>
            </>
          )}
        </Row>
      </div>
    </>
  );
}

export default withRouter(SingleProductComponent);
