import getQuickSearch from "./get-quick-search";
import postenquireProduct from './enquireProduct';
import createReview from "./createReview";
import updateReview from "./updateReview";

export default {
    getQuickSearch,
    postenquireProduct,
    createReview,
    updateReview
}