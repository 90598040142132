import React from "react";
import { CardImg } from "react-bootstrap";
import "./css/index.scoped.css";

const ImageController = ({ src, imageClick }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  return (
    <div onClick={imageClick} className="hover-me" >
      <CardImg
        variant="top"
        src={src}
        alt="pooja-duggal-fine-jewels"
        className="smooth-image image-visible"
        style={{cursor:"pointer"}}
        // onLoad={() => setLoading(false)}
        // onError={() => {
        //   setLoading(false);
        //   setError(true);
        // }}

      />
      {/* {loading && !error && (
        <CardImg variant="top" src={require("./images/loader.gif")} />
      )}
      {error && !loading && (
        <CardImg variant="top" src={require("./images/noimg.png")} />
      )} */}
    </div>
  );
};

export default ImageController;
