import React, { useState } from "react";
import "./css/index.scoped.css";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import commands from "../../../commands";

function ProductRating(props) {
  //const [deleteorder, SetDelete] = useState(false);
  // console.log(props,"PRODUCT MODAL")
  let [rating, setRating] = useState(0);
  let [comment, setComment] = useState("");

  const isTabletOrMobile = useMediaQuery({
    query: "(max-width: 767.88px)",
  });
  var StarColor = "#D4C96D";
  if (isTabletOrMobile) {
    StarColor = "#3BC490";
  }
  const secondExample = {
    size: 40,
    count: 5,
    color: "#DBDBDB",
    activeColor: StarColor,
    value: 0,
    a11y: true,
    isHalf: true,
    emptyIcon: <BsStarFill className="m-1" />,
    halfIcon: <BsStarHalf className="m-1" />,
    filledIcon: <BsStarFill className="m-1" />,
    onChange: setRating,
  };

  const {
    reviewData: { orderId, orderItemId, reviewId },
    toggle,
    isOpen,
    onSuccess,
  } = props;

  const canReview = orderId || orderItemId;
  

  function onSubmit() {
    if (reviewId) {
      const data = { reviewId, rating, comment };
     commands.products.updateReview({ reviewId, rating, comment, onSuccess });
    } else {
      const data = { orderId, orderItemId, rating, comment };
      console.log("Data for submit ", data);
      commands.products.createReview({
        orderId,
        orderItemId,
        rating,
        comment,
        onSuccess,
      });
      
    }
    toggle();
  }

 
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="cm-small-modal-4"
        centered={true}
      >
        <ModalHeader toggle={toggle} className="pr-title-bar border-0">
          <span>Rate and Review</span>
        </ModalHeader>
        {canReview ? (
          <ModalBody className="cm-modal-body">
            <h3 className="pr-body-title">Rate our Services</h3>
            <ReactStars {...secondExample} classNames="yt-rating-wrapper" />
            <Form>
              <FormGroup>
                <Input
                  type="hidden"
                  name="ProductStar"
                  id="ProductStar"
                  value={rating}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="product-review-description"
                  id="product-review-description"
                  placeholder="Write detailed review for us .."
                  className="p-3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
        ) : (
          <ModalBody className="cm-modal-body">
            <h3 className="pr-body-title">
              You can't review a product until you purchase it.
            </h3>
          </ModalBody>
        )}
        <ModalFooter className="pr-bottom-bar p-0">
          {canReview ? (
            <Button
              color="secondary pr-rate-submit-btn bg-white py-3"
              onClick={onSubmit}
              block
            >
              Submit
            </Button>
          ) : (
            <Button
              color="secondary pr-rate-submit-btn bg-white py-3"
              onClick={toggle}
              block
            >
              OK
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default ProductRating;
