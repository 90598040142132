import React, { useRef } from "react";
import "./css/index.scoped.css";
import { Row, Col } from "reactstrap";
import Carousel from "react-elastic-carousel";
import {
  FaChevronUp,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import ReactImageMagnify from 'react-image-magnify';

function ProductverticalSlider(props) {
  let carousel = useRef();
  return (
    <>
      {props.images?.length > 0 && (
        <div className="sp-inner-content-wrap sp-image-vert-slider vertical-slider">
          <FaChevronUp
            className="yt-slider-up img-fluid"
            onClick={() => carousel.slidePrev()}
          />
          <FaChevronDown
            className="yt-slider-down img-fluid"
            width="20"
            height="20"
            onClick={() => carousel.slideNext()}
          />
          <Carousel
            focusOnSelect={false}
            verticalMode
            itemsToShow={3}
            pagination={false}
            ref={(ref) => (carousel = ref)}
            className="py-4"
          >
            {props.images.map((item, idx) => (
              <div
                key={idx}
                className="vert-slider-item my-1 d-flex align-items-center justify-content-center"
                onClick={() => props.setCurrentImage(item.image)}
              >
                <img
                  src={item.image || "null" || require("./images/P860_2.png")}
                  alt={"img " + idx}
                  className="img-fluid"
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
}

function ProducthorizontalSlider(props) {
  let carousel = useRef();
  return (
    <>
      {props.images?.length > 0 && (
        <div className="sp-inner-content-wrap sp-image-vert-slider horizontal-slider">
          {/* <FaChevronLeft
            className="yt-slider-up img-fluid"
            onClick={() => carousel.slidePrev()}
          />
          <FaChevronRight
            className="yt-slider-down img-fluid"
            width="20"
            height="20"
            onClick={() => carousel.slideNext()}
          /> */}
          <Carousel
            focusOnSelect={false}
            itemsToShow={3}
            pagination={false}
            ref={(ref) => (carousel = ref)}
            className="py-4"
          >
            {props.images.map((item, idx) => (
              <div
                key={idx}
                className="vert-slider-item m-2 d-flex align-items-center justify-content-center"
                onClick={() => props.setCurrentImage(item.image)}
              >
                <img src={item.image} alt="search" className="img-fluid" />
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
}

function ResponsiveProductSlider(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const isTabletOrMobile = useMediaQuery({
    query: "(max-width: 1279.92px)",
  });
  return (
    <>
      {isDesktopOrLaptop && <ProductverticalSlider {...props} />}
      {isTabletOrMobile && <ProducthorizontalSlider {...props} />}
    </>
  );
}

function ProductImageWithSlider(props) {
  return (
    <div className="sp-inner-wrap radius-10">
      {/* {props.images.length > 1 ? (
        <Row className="yt-product-img-inner-row">
          <Col xs={12} sm={8} lg={8} className="yt-inner-col">
            <div className="sp-inner-content-wrap sp-spp-inner-image-wrap m-4">
              <img
                src={
                  props.currentImage || "null" || require("./images/P860_1.png")
                }
                alt="Try Refresh"
                className="img-fluid sp-spimg-item"
              />
            </div>
          </Col>
          <Col xs={12} sm={4} lg={4} className="yt-inner-col">
            <ResponsiveProductSlider
              images={props.images}
              setCurrentImage={props.setCurrentImage}
            />
          </Col>
        </Row>
      ) : (
        <Row className="yt-product-img-inner-row">
          <Col xs={12} sm={10} lg={10} className="yt-inner-col">
            <div className="sp-inner-content-wrap sp-spp-inner-image-wrap m-4">
              <img
                src={props?.images[0]?.image}
                alt="Try Refresh"
                className="img-fluid sp-spimg-item"
                style={{ maxHeight: "470px", objectFit: "contain" }}
              />
            </div>
          </Col>
          <Col xs={12} sm={2} lg={2} className="yt-inner-col" />
        </Row>
      )} */}

      {/* <Row className="yt-product-img-inner-row">
        <Col xs={12} sm={10} lg={10} className="yt-inner-col">
          <div className="sp-inner-content-wrap sp-spp-inner-image-wrap m-4">
            <img
              src={props?.images[0]?.image}
              alt="Try Refresh"
              className="img-fluid sp-spimg-item"
              style={{ maxHeight: "500px", objectFit: "contain" }}
            />
          </div>
        </Col>
        <Col xs={12} sm={2} lg={2} className="yt-inner-col" />
      </Row> */}
      <div className="sp-inner-content-wrap sp-spp-inner-image-wrap m-4">
      <ReactImageMagnify {...{
              smallImage: {
                alt: 'Wristwatch by Ted Baker London',
                isFluidWidth: true,
                src: `${props?.images[0]?.image}`,
              },
              largeImage: {
                src: `${props?.images[0]?.image}`,
                width: 1200,
                height: 1800
              }, isHintEnabled: true,
              shouldHideHintAfterFirstActivation: true,

            }}
              isActivatedOnTouch
              className="img-fluid sp-spimg-item"
              style={{ zIndex: "10" }} />
            {/* <img
              src={props?.images[0]?.image}
              alt="Try Refresh"
              className="img-fluid sp-spimg-item"
            /> */}
          </div>
    </div>
  );
}

export default ProductImageWithSlider;
